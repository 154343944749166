import React from 'react';
import {
  ResponsiveContainer,
  RadarChart as RecharsRadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Tooltip,
  Legend
} from 'recharts';
import useWindowWidth from '../../../utils/useWindowWidth';

const RadarChart = ({ height, width, minWidth, maxDomain, dataKey, data }) => {
  const windowWidth = useWindowWidth();
  return (
    <ResponsiveContainer
      height={height || (windowWidth > 576 ? 330 : 200) }
      width={width}
      minWidth={minWidth || '100%'}
    >
      <RecharsRadarChart
        data={data}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey={dataKey} />
        <Radar name="Total Tally" dataKey="catTotal" stroke="#007bff" fill="#8884d8" fillOpacity={0.6} />
        <Tooltip />
      </RecharsRadarChart>
    </ResponsiveContainer>
  );
};

export default RadarChart;
