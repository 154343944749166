import React from 'react';
import Helmet from "react-helmet";
import Menubar from './components/Menubar';
import Main from './components/Main';
import Footer from './components/Footer';
import './App.css';
import './scss/theme.scss';

const App = ({ client }) => {
  return (
    <div>
      <Helmet>
        <title>ProgressMAKR</title>
        <meta
          name="keywords"
          content="progressmaker, progress maker, productivity tracker, progress tracker, bullet journal, habit tracker, habit journal, wellbeing, life balance, mood tracker"
        />
        <meta
          name="description"
          content="Online app to keep track of your progress and wellbeing for yourself and with others. Try Free Today and create positive habits to make a difference in your life."
        />
      </Helmet>
      <Menubar client={client}/>
      <Main />
      <Footer />
    </div>
  );
};


export default App;
