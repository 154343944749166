import React, { useEffect } from 'react';
import { VERIFY_USER, SET_AUTHENTICATED } from './apollo';
import { useQuery, useMutation } from 'react-apollo-hooks';

const AuthProvider  = (props) => {
  const { data, loading, error } = useQuery(VERIFY_USER, { errorPolicy: 'all', fetchPolicy: 'no-cache', suspend: false });
  const setAuthenticated = useMutation(SET_AUTHENTICATED, { suspend: false });
  const { verified, csrfToken } = data && data.verifyUser || {};

  if (loading) {
    return <p>Loading...</p>
  }
  localStorage.setItem('csrfToken', csrfToken);

  if (verified) {
    setAuthenticated({ variables: { authenticated: true } });
  } else if (!loading) {
    setAuthenticated({ variables: { authenticated: false } });
    // localStorage.removeItem('token');
  }
  return (
    <div
      className="h-100"
    >
      {props.children}
    </div>
  );
};

export default AuthProvider;
