import React from 'react';
import FormBox from '../../components/form';
import {CREATE_ITEM, UPDATE_ITEM, MY_ITEM_WITH_CATEGORY_LIST} from "./apollo";
import { ITEM } from '../ProgressPost/apollo';
import itemFieldsArr from './itemFieldsArr';
import { useQuery } from "react-apollo-hooks";
import LoadingPage from '../LoadingPage';

const ItemForm = ({ itemId='', ...props }) => {
  // get myItem by Query
  const {error, data={}, loading } = useQuery(MY_ITEM_WITH_CATEGORY_LIST, { variables: { id: itemId }, suspend: false, fetchPolicy: "cache-and-network" });
  if (loading) return <LoadingPage />;
    // fieldsData from Query
  const fieldsData = data.myItem || {};
  // merge the data into inputFieldsArr.
  const inputFieldsArr = itemFieldsArr
    .map(fieldObj => {
      // If it is a category(categoryId) field,
      // then insert the category id value add optionList data in the field.
      if (fieldObj.name === 'categoryId') {
        fieldObj.value = (fieldsData.category && fieldsData.category.id) || fieldObj.initialValue;
        fieldObj.optionList = (data.myCategories && data.myCategories.map(({ id, title, color }) =>({ id, label: title, color }))) || [];
        fieldObj.color = (fieldsData.category && fieldsData.category.color) || fieldObj.initialColor;
      } else {
        fieldObj.value = fieldsData[fieldObj.name] || fieldObj.initialValue;
      }
      return fieldObj;
    });

  const cancelLink = itemId ? `/progressposts/${itemId}` : '/myarea';
  const refetchQueries = itemId ? [{ query: ITEM, variables: { id: itemId }, suspend: false }] : [];
  return (
    <FormBox
      hasCategoryModal
      itemId={itemId}
      mutation={itemId ? UPDATE_ITEM : CREATE_ITEM}
      refetchQueries={refetchQueries}
      buttonText="Save"
      alternativeLinks={[{ title: 'Cancel', to: cancelLink }]}
      inputFieldsArr={inputFieldsArr}
      callbackOnSuccess={(variables, data) => {
        const itemPageId = itemId ? itemId : (data && data.createItem && data.createItem.id);
        setTimeout(() => {
          props.history.push(`/progressposts/${itemPageId}`);
        }, 1000);
      }}
    />
  );
};

export default ItemForm;
