const deleteFieldsArr = [
  {
    type: 'text', label: '', name: 'delete',
    initialValue: '',
    helpMessage: 'Please type DELETE to confirm.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      pattern: { value: /^DELETE$/, errorMessage: 'Please enter "DELETE".' }
    }
  }
];

export default deleteFieldsArr;
