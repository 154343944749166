import React from "react";
import { Route, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import LoadingPage from "../../pages/LoadingPage";
import { GET_AUTHENTICATION_STATUS } from './apollo';
import {useQuery} from "react-apollo-hooks";
const LoadableMyArea = Loadable({
  loader: () => import('../../pages/MyArea'),
  loading: LoadingPage
});

const PublicOnlyRoute = (props) => {
  const { data, loading } = useQuery(GET_AUTHENTICATION_STATUS, { suspend: false });
  if (loading) return <LoadingPage />;

  if (data.authenticationStatus && (data.authenticationStatus.authenticated)) {
    return <Redirect exact to="/myarea" component={ LoadableMyArea } />;
  } else {
    return <Route { ...props } />;
  }
};

export default PublicOnlyRoute;
