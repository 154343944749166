import gql from 'graphql-tag';

export const DEACTIVATE_ACCOUNT = gql`
    mutation deactivateAccount(
        $password: String!,
    ){
        deactivateAccount(
            password: $password
        ) {
            status
        }
    }
`;
