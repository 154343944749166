export const hasErrors = (options, value='', type) => {
  if (!options) return false;
  const trimmedValue = (type === 'text' || type === 'textarea' || type === 'email' || type === 'password' || type === 'editor') ? value && value.trim() : value;
  const { required, minValue, maxValue, minLength, maxLength, pattern } = options || {};
  if(required && required.value && (trimmedValue === "" || trimmedValue === null || trimmedValue === undefined || trimmedValue.length < 1)) return true;
  if (pattern && pattern.value && !pattern.value.test(trimmedValue)) return true;
  if (type === 'number' || type === 'range') {
    if (minValue && (trimmedValue < minValue.value)) return true;
    if (maxValue && (trimmedValue > maxValue.value)) return true;
  } else {
    if (minLength && (trimmedValue.length < minLength.value)) return true;
    if (maxLength && (trimmedValue.length > maxLength.value)) return true;
  }
  return false;
};

export const selectErrorMessage = (options, value='', type) => {
  if (!options) return '';
  const trimmedValue = (type === 'text' || type === 'textarea' || type === 'email' || type === 'password' || type === 'editor') ? value.trim() : value;
  const { required, minValue, maxValue, minLength, maxLength, pattern } = options || {};
  if(required && required.value && (!trimmedValue || trimmedValue.length < 1)) return required.errorMessage;
  if (pattern && pattern.value && !pattern.value.test(trimmedValue)) return pattern.errorMessage;
  if (typeof value === 'number') {
    if (minValue && (trimmedValue < minValue.value)) return minValue.errorMessage;
    if (maxValue && (trimmedValue > maxValue.value)) return maxValue.errorMessage;
  } else {
    if (minLength && (trimmedValue.length < minLength.value)) return minLength.errorMessage;
    if (maxLength && (trimmedValue.length > maxLength.value)) return maxLength.errorMessage;
  }
  return '';
};
