import React, { useState } from 'react';
import Modal from '../../../components/Modal';
import Button from 'reactstrap/lib/Button';


const ExampleModal = () => {
  const [ isOpen, openModal ] = useState(false);
  const [ modalTitle, setModalTitle ] = useState('');
  const [ modalContent, setModalContent ] = useState('');
  const testContent1 = (<div>
    <h1>Test Content 1</h1>
    <p>Test paragraph 1</p>
    <p>Test paragraph 2</p>
    <p>Test paragraph 3</p>
  </div>);
  const testContent2 = (<div>
    <h1>Test Content 2</h1>
    <p>Test paragraph 1</p>
    <p>Test paragraph 2</p>
    <p>Test paragraph 3</p>
  </div>);
  const handleOpenModal = (open, title, content) => {
    if (open) {
      setModalTitle(title);
      setModalContent(content);
      openModal(true);
    } else {
      setModalTitle('');
      setModalContent('');
      openModal(false);
    }
  };
  return (
    <div>
      <Button
        className="m-3"
        onClick={() => handleOpenModal(true, 'Title 1', testContent1)}>
        Modal Button (content 1)
      </Button>
      <Button
        className="m-3"
        onClick={() => handleOpenModal(true, 'Title 2', testContent2)}>
        Modal Button (content 2)
      </Button>
      <Modal
        title={modalTitle || 'Test Title'}
        size="xl"
        className="rounded-0"
        isOpen={isOpen}
        closeModal={() => handleOpenModal(false)}
        centered
        content={() => modalContent}
      >
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.</p>
        <p>Curabitur tortor. Pellentesque nibh. Aenean quam. In scelerisque sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin ut ligula vel nunc egestas porttitor. Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa. Fusce ac turpis quis ligula lacinia aliquet. Mauris ipsum. Nulla metus metus, ullamcorper vel, tincidunt sed, euismod in, nibh. Quisque volutpat condimentum velit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
      </Modal>
    </div>
  )
};

export default ExampleModal;
