import { useState, useEffect } from 'react';

const useWindowWidth = () => {
  const getWindowWidth = () => typeof window !== 'undefined' ? window.innerWidth : 992;
  const [ width, setWidth ] = useState(getWindowWidth());
  useEffect(() => {
    if(typeof window !== 'undefined') {
      const handleResize = () => setWidth(getWindowWidth());
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  return width;
};

export default useWindowWidth;
