import gql from "graphql-tag";

export const ITEM = gql`
  query getProgressPost($id: ID!) {
    item (id: $id){
      id
      title
      published
      allowOthersComment
      likedByCount
      totalProgressTally
      progressRecords {
        id
        notes
        latestProgressDate
        progressTally
      }
      categoryId
      categoryColor
      categoryTitle
      tags
      userId
      username
      avatarUrl
      description
      notes
      updatedAt
      createdAt
      comments {
        id
        body
        createdAt
        author {
          id
          username
          avatarUrl
        }
      }
    }
  }
`;

export const COMMENTS_UPDATE = gql`
    subscription comment($id: ID!) {
        comment(postId: $id) {
            mutation
        }
    }
`;

export const PROGRESS_UPDATE = gql`
    subscription progressRecord($id: ID!) {
        progressRecord(postId: $id) {
            mutation
        }
    }
`;

export const DELETE_ITEM = gql`
    mutation deleteItem($id: ID!){
        deleteItem(id: $id) {
            id
            title
        }
    }
`;

export const DELETE_COMMENT = gql`
    mutation deleteComment($id: ID!){
        deleteComment(id: $id) {
            id
        }
    }
`;

