import React from 'react';
import { useMutation } from 'react-apollo-hooks';
import FormBox from '../../../components/form';
import { LOG_IN, REQUEST_ACCOUNT_CONFIRMATION_EMAIL } from './apollo';
import logInFieldsArr from './logInFieldsArr';

const LogInForm = () => {
  const requestEmail = useMutation(REQUEST_ACCOUNT_CONFIRMATION_EMAIL);
  return (
    <FormBox
      mutation={LOG_IN}
      buttonText="Log In"
      alternativeLinks={[{ title: 'Sign Up', to: '/signup' }, { title: 'Forgot password?', to: '/requestpasswordreset'}]}
      inputFieldsArr={logInFieldsArr}
      authenticate
      requestAccConfirmEmail={requestEmail}
    />
  );
};

export default LogInForm;

