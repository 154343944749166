const deactivateAccountFieldsArr = [
  { type: 'password', label: 'Password', name: 'password', initialValue: '',
    helpMessage: 'Please enter your password to confirm the deactivation of your account.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 8, errorMessage: 'Password should not be less than 8 characters long.' },
      maxLength: { value: 20, errorMessage: 'Password should not be more than 20 characters long.' },
    }
  }
];

export default deactivateAccountFieldsArr;
