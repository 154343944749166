import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import TagManager from "react-gtm-module";
import './index.css';
import App from './App/App';
import client from './client';
import ScrollToTop from "./App/components/ScrollToTop";
import {ToastProvider} from "react-toast-notifications";
import ToastContainer from "./App/components/ToastMessenger/ToastContainer";
import AuthProvider from "./App/components/AuthProvider";
import ToastMessenger from "./App/components/ToastMessenger";

const tagManagerArgs = {
  gtmId: 'GTM-WRC5HH6'
};

TagManager.initialize(tagManagerArgs);

const fontsList = ['Oxygen:400,700', 'Open Sans', 'Roboto', 'Lato', 'Baskervville', 'Courgette' ];
WebFont.load({
  google: {
    families: fontsList
  }
});

const render = module.hot ? ReactDOM.render : ReactDOM.hydrate;

render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <BrowserRouter>
        <ScrollToTop />
        <ToastProvider
          placement="bottom-right"
          components={{ ToastContainer }}
        >
          <div className="App">
            <AuthProvider>
              <ToastMessenger />
              <App client={client} />
            </AuthProvider>
          </div>
        </ToastProvider>
      </BrowserRouter>
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);


