import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useMutation, useQuery} from 'react-apollo-hooks';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import {FOLLOWERS, GET_USER} from './apollo';
import LoadingPage from '../LoadingPage';
import TabContents from '../../components/TabContents';
import profileTabItems from './profileTabItems';
import {ME} from "../../components/Menubar/Dropdown/apollo";
import {FOLLOW} from "../ProgressPosts/apollo";
import FollowButton from "../../components/PublicItemCards/FollowButton";
import './Profile.scss';
import AvatarImage from "../../components/AvatarImage";

const Profile = ({ authenticated, match }) => {
  const { username } = (match
  && match.params) || {};

  const toggleFollow = useMutation(
    FOLLOW,
    {
      refetchQueries: [{ query: ME }, { query: GET_USER, variables: { username } }, { query: FOLLOWERS, variables: { username: username } }],
      suspense: false
    }
  );

  const { data: meData, loading: meLoading } = useQuery(ME, { skip: !authenticated, suspend: false });
  const { username: myUsername, following: myFollowing } = (meData && meData.me) || {};
  const followingUsernames = (myFollowing && myFollowing.map(user => user && user.username)) || [];

  const { data, loading, refetch } = useQuery(GET_USER, { variables: { username }, suspend: false });
  const { user } = data || {};
  const { id, avatarUrl, createdAt, updatedAt, followingCount, following, followersCount, followers } = user || {};
  if (loading || meLoading) return <LoadingPage small hideLoadingText />;

  if (!id) {
    return (
      <Container className="Profile">
        <Row className="pt-5">
          <div className="m-auto">
            <h1
              className="m-auto text-center"
            >
              User not found.
            </h1>
          </div>
        </Row>
      </Container>
    );
  }
  const followingThisUser = username && followingUsernames.includes(username);
  return (
    <Container className="Profile">
      <div className="p-3 text-center">
        <h1 className="m-auto">{username}</h1>
        <AvatarImage
          className={`rounded-circle ${(username !== myUsername) ? "mt-4 mb-2": "m-4"}`}
          avatarUrl={avatarUrl}
          style={{ width: '10rem', height: '10rem', objectFit: "cover"}}
        />
        {
          (username !== myUsername) && (
            <div>
              <FollowButton
                id={id}
                authorId={id}
                followingThisUser={followingThisUser}
                toggleFollow={toggleFollow}
                authenticated={authenticated}
              />
            </div>
          )
        }
        <p className="mb-0">
          Last Profile Update: { updatedAt ? moment(updatedAt).format('ddd MMM Do YYYY') : ''}
        </p>
        <p>
          Joined on: { createdAt ? moment(createdAt).format('ddd MMM Do YYYY') : ''}
        </p>
      </div>
      <TabContents
        authenticated={authenticated}
        extraInfo={username}
        tabItems={profileTabItems}
        contentData={{ followingCount, following, followersCount, followers }}
        refetch={refetch}
      />
    </Container>
  );
};

export default Profile;
