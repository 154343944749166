import React from 'react';
import { Alert as ReactStrapAlert } from 'reactstrap/lib';

const Alert = ({ color, className, children }) => {
  return (
    <ReactStrapAlert
      color={color}
      className={className}
    >
      {
        children
      }
    </ReactStrapAlert>
  );
}

export default Alert;
