import React, { Fragment } from "react";
import ProgressRecordForm from "../../../components/ProgressRecordModal/ProgressRecordForm";
import CollapsibleInline from "../../../components/CollapsibleInline";

const ProgressRecordDisplay = ({ record, refetchQueries, isEditMode, setIsEditMode }) => {
  const { id, notes } = record || {};
  return (
    <Fragment>
      {
        isEditMode ? (
          <div className="text-left">
            <ProgressRecordForm
              progressRecordId={id}
              record={record}
              refetchQueries={refetchQueries}
              openModal={() => setIsEditMode(false)}
            />
          </div>
        ): (
          <div>
            <CollapsibleInline
              title="Progress Record"
              className="pt-1"
              titleClassName=""
              initialStatus={true}
            >
              <div className="ql-editor p-1" dangerouslySetInnerHTML={{__html: notes }} />
            </CollapsibleInline>
          </div>
        )
      }
    </Fragment>
  );
};

export default ProgressRecordDisplay;
