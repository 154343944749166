import React from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';

const CustomModal = ({ title, size, centered=false, isOpen, closeModal, className, content, children, DoButton }) => {
  return (
    <Modal
      size={size}
      centered={centered}
      isOpen={isOpen}
      toggle={closeModal}
      contentClassName={className}
      scrollable
    >
      <ModalHeader toggle={closeModal}>
        { title }
      </ModalHeader>
      <ModalBody>
        {
          content && content()
        }
        {
          children
        }
      </ModalBody>
      <ModalFooter>
        {
          DoButton
          && (
            <DoButton />
          )
        }
        <Button color="secondary" onClick={closeModal}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CustomModal;
