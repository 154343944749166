import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-apollo-hooks';
import { useQuery, useSubscription } from 'react-apollo';
import LoadingPage from '../LoadingPage';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import './ProgressPost.scss';
import moment from 'moment';
import invert from 'invert-color';
import { FiEdit, FiPlusSquare, FiTrash } from 'react-icons/fi';
import CommentInputBox from '../../components/CommentInputBox/index';
import { ME } from "../../components/Menubar/Dropdown/apollo";
import { ITEM, COMMENTS_UPDATE, PROGRESS_UPDATE, DELETE_ITEM, DELETE_COMMENT } from "./apollo";
import UserIcon from '../../assets/face-24px.svg';
import CollapsibleInline from '../../components/CollapsibleInline';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import ProgressRecordModal from '../../components/ProgressRecordModal';
import { FiMessageCircle } from 'react-icons/fi';
import {FOLLOW, LIKE} from "../ProgressPosts/apollo";
import LikedByModal from "../../components/LikedByModal";
import FollowButton from '../../components/PublicItemCards/FollowButton';
import LikesButtons from "./LikesButtons";
import {DELETE_PROGRESS_RECORD, MY_ITEMS, MY_PROGRESS_RECORDS} from "../MyArea/apollo";
import ProgressRecordDisplay from "../MyArea/ProgressRecordDisplay";
import CalendarHeatmap from 'react-calendar-heatmap';
import './react-calendar-heatmap.scss';
import ReactTooltip from 'react-tooltip';
import useWindowWidth from "../../utils/useWindowWidth";
import ProgressRecordsModal from "../../components/ProgressRecordsModal";
import Helmet from "react-helmet";
import AvatarImage from "../../components/AvatarImage";

function shiftDate(date, numDays) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
};

const ProgressPost = ({ authenticated, match, history }) => {
  const [ isDeleteItemModalOpen, setDeleteItemModalOpen ] = useState(false);
  const [ isDeleteCommentModalOpen, setDeleteCommentModalOpen ] = useState(false);
  const [ selectedComment, selectComment ] = useState({ id: '', title: '' });
  const [ progressRecordModalOpen, setProgressRecordModalOpen ] = useState(false);
  const [ likedByModalOpen, setLikedByModalOpen ] = useState(false);
  const [ modalStatus, openModal ] = useState({ isOpen: false, records: [], itemId: null, targetDateStr: '' });
  const [ isPREditMode, setIsPREditMode ] = useState(false);
  const [ isDeletePRModalOpen, setDeletePRModalOpen ] = useState(false);
  const [ selectedProgressRecord, setSelectedProgressRecord ] = useState('');
  const width = useWindowWidth();

  const itemId = (match
    && match.params
    && match.params.itemId) || '';

  const { error: meError, data: meData, loading: meDataLoading, refetch: refetchMe } = useQuery(ME, { skip: !authenticated, suspend: false });
  const { error, data, loading, refetch } = useQuery(ITEM, { variables: { id: itemId }, suspend: false, fetchPolicy: 'network-only' });

  const { id: myId, following } = (meData && meData.me) || {};
  const itemFound = !!(data && data.item);
  const targetItem = (data && data.item) || {};
  const {
    title, totalProgressTally, progressRecords=[], categoryId, categoryColor, categoryTitle, tags, allowOthersComment, userId: postAuthorId, username: authorUsername, avatarUrl: authorAvatarUrl, likedByCount, description, notes, updatedAt, createdAt, comments=[],
  } = targetItem || {};
  const followingIds = (following && following.map(user => user && user.id)) || [];
  const followingThisUser = postAuthorId && followingIds.includes(postAuthorId);

  const { data: commentData, error: commentError, loading: commentLoading } = useSubscription(COMMENTS_UPDATE, { skip: !itemId, variables: { id: itemId }});
  const { data: progressRData, error: progressRError, loading: progressRLoading } = useSubscription(PROGRESS_UPDATE, { skip: !itemId, variables: { id: itemId }});

  useEffect(() => {
    if (commentData) {
      refetch && refetch();
    }
  }, [commentData]);

  useEffect(() => {
    if (progressRData) {
      refetch && refetch();
    }
  }, [progressRData]);

  const progressAndComments = [ ...progressRecords, ...comments ]
    .sort((a, b) => {
      const aValue = a.latestProgressDate || a.createdAt;
      const bValue = b.latestProgressDate || b.createdAt;
      if (!aValue && !bValue) return 0;
      if (!aValue && bValue) return -1;
      if (aValue && !bValue) return 1;
      const aDate = new Date(aValue);
      const bDate = new Date(bValue);
      return (aDate < bDate) ? 1 : (aDate > bDate) ? -1 : 0;
    });

  const toggleLike = useMutation(
    LIKE,
    {
      refetchQueries: [{ query: ITEM, skip: !itemId, variables: { id: itemId }, suspend: false }],
      suspense: false
    }
  );
  const toggleFollow = useMutation(
    FOLLOW,
    {
      refetchQueries: [{ query: ME }],
      suspense: false
    }
  );

  const deleteItem = useMutation(
    DELETE_ITEM,
    {
      variables: { id: itemId },
      suspense: false
    }
  );

  const deleteComment = useMutation(
    DELETE_COMMENT,
    {
      variables: { id: selectedComment && selectedComment.id },
      suspense: false
    }
  );

  const handleDeleteItemConfirmation = () => {
    deleteItem();
    setDeleteItemModalOpen(false);
    history.push('/myarea');
  };

  const openDeleteCommentModal = (modalState, comment={ id: '', title: ''}) => {
    selectComment(comment);
    setDeleteCommentModalOpen(modalState);
  };

  const handleDeleteCommentConfirmation = async () => {
    await deleteComment();
    // await refetchMe();
    // await refetch();
    openDeleteCommentModal(false);
  };

  const deleteProgressRecord = useMutation(DELETE_PROGRESS_RECORD, {
    refetchQueries: [{ query: MY_ITEMS }, { query: MY_PROGRESS_RECORDS }]
  });

  const handleDeletePRConfirmation = (variables) => {
    deleteProgressRecord({ variables: { id: variables.id }});
    setDeletePRModalOpen(false);
    setSelectedProgressRecord('');
  };

  if (loading || meDataLoading) return <LoadingPage />;

  if (!itemFound) return (
    <Container className="ProgressPost">
      <Row className="pt-5">
        <div className="m-auto">
          <h1
            className="m-auto text-center"
          >
            Item not found or private
          </h1>
        </div>
      </Row>
    </Container>
  );

  const today = new Date();
  const heatMapProgressRecordObj = [ ...progressRecords ].reduce((acc, recordObj, i) => {
    const recordDate = recordObj && recordObj.latestProgressDate ? new Date(recordObj.latestProgressDate) : null;
    const recordCount = recordObj && recordObj.progressTally;
    if (recordDate && recordCount) {
      const currentDateStr = recordDate.toISOString().slice(0, 10);
      if (!acc[currentDateStr]) {
        acc[currentDateStr] = recordCount;
      } else {
        acc[currentDateStr] = acc[currentDateStr] + recordCount;
      }
    }
    return acc;
  }, {});

  const heatMapDateKeys = Object.keys(heatMapProgressRecordObj);
  const heatMapProgressRecords = heatMapDateKeys.map(dateKey => {
    return {
      date: dateKey,
      count: heatMapProgressRecordObj[dateKey]
    };
  });
  const numOfDaysToGoBack = () => {
    if (width < 576) {
      return -60;
    } else if (width < 768) {
      return -180;
    } else {
      return -365;
    }
  };

  return (
    <Container className="ProgressPost">
      <Helmet>
        <title>{title}</title>
        <meta
          name="keywords"
          content={tags || title}
        />
        <meta
          name="description"
          content={description || tags || 'Public post'}
        />
      </Helmet>
      <DeleteConfirmationModal
        buttonColor="danger"
        buttonText="Confirm"
        message="Are you sure you want to delete this progress record?"
        selectedItem={{ title: 'Delete Progress Record', id: selectedProgressRecord }}
        isModalOpen={isDeletePRModalOpen}
        openModal={(modalState) => {
          setDeletePRModalOpen(modalState);
          setSelectedProgressRecord('');
        }}
        callbackOnConfirm={handleDeletePRConfirmation}
      />
      <DeleteConfirmationModal
        message="Are you sure you want to delete this item?"
        selectedItem={targetItem}
        isModalOpen={isDeleteItemModalOpen}
        openModal={(modalState) => setDeleteItemModalOpen(modalState)}
        callbackOnConfirm={handleDeleteItemConfirmation}
      />
      <DeleteConfirmationModal
        message="Are you sure you want to delete this comment?"
        selectedItem={selectedComment}
        isModalOpen={isDeleteCommentModalOpen}
        openModal={openDeleteCommentModal}
        callbackOnConfirm={handleDeleteCommentConfirmation}
      />
      <ProgressRecordModal
        selectedItem={targetItem}
        refetchQueries={[{ query: ITEM, skip: !itemId, variables: { id: itemId }, suspend: false }]}
        isModalOpen={progressRecordModalOpen}
        openModal={setProgressRecordModalOpen}
      />
      <ProgressRecordsModal
        data={data}
        openModal={openModal}
        modalStatus={modalStatus}
        selectedProgressRecord={selectedProgressRecord}
        setSelectedProgressRecord={setSelectedProgressRecord}
        setDeleteModalOpen={setDeletePRModalOpen}
      />
      <LikedByModal
        authenticated={authenticated}
        myId={myId}
        postId={itemId}
        followingIds={followingIds}
        toggleFollow={toggleFollow}
        isModalOpen={likedByModalOpen}
        closeModal={() => setLikedByModalOpen(false)}
      />
      <Row className="pt-5">
        <div className="m-auto">
          <h1
            className="text-center mb-4"
          >
            {title}
          </h1>
          <p className="text-center mb-1">Updated on: {moment(updatedAt).format('dddd D MMM YYYY, hh:mm a')}</p>
          <p className="text-center">Created on: {moment(createdAt).format('dddd D MMM YYYY, hh:mm a')}</p>
          <p className="display-2 mt-3 mb-0 d-flex justify-content-center align-items-center">
            <span>Tally</span>
            <span className={ `mx-2 badge badge-pill ${totalProgressTally >= 0 ? 'badge-primary' : 'badge-danger'}` }>
              { totalProgressTally }
            </span>
          </p>
          <div className="pt-2 d-flex justify-content-center align-items-center">
            <LikesButtons
              id={itemId}
              likedByCount={(likedByCount) || 0}
              toggleLike={toggleLike}
              authenticated={authenticated}
              setLikedByModalOpen={()=>setLikedByModalOpen(true)}
            />
            {
              targetItem && (myId === postAuthorId) && (
                <div className="d-flex justify-content-center align-items-center p-2">
                  <span className="pr-1">Edit</span>
                  <Link
                    style={{ cursor: 'pointer' }}
                    to={`/item/edit/${itemId}`}
                    className="d-flex"
                  >
                    <FiEdit
                      size={20}
                      className="text-primary"
                      style={{ cursor: 'pointer' }}
                    />
                  </Link>
                  &nbsp;&nbsp;
                  <span className="pr-1">Delete</span>
                  <FiTrash
                    size={20}
                    color="#942933"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setDeleteItemModalOpen(true)}
                  />
                </div>
              )
            }
          </div>
        </div>
      </Row>
      {
        targetItem && (
          <Row className="mb-3">
            <Col className="text-center">
              <p className="h3 mb-3">
                <AvatarImage
                  className="mr-2"
                  avatarUrl={(authorAvatarUrl) ? authorAvatarUrl : UserIcon}
                  style={{ width: '3rem', height: '3rem', borderRadius: "100%", objectFit: "cover" }}
                />
                <Link style={{ cursor: 'pointer' }} to={`/profile/${authorUsername}`}>
                  <span className="pr-2">
                    { authorUsername && authorUsername }
                  </span>
                </Link>
                {
                  postAuthorId !== myId && (
                    <FollowButton
                      id={postAuthorId}
                      authorId={postAuthorId}
                      followingThisUser={followingThisUser}
                      toggleFollow={toggleFollow}
                      authenticated={authenticated}
                    />
                  )
                }
              </p>
              {
                heatMapProgressRecords && heatMapProgressRecords.length > 0 && (
                  <div className="col-xl-10 offset-xl-2 m-auto">
                    <CalendarHeatmap
                      startDate={shiftDate(today, numOfDaysToGoBack())}
                      endDate={today}
                      values={heatMapProgressRecords}
                      classForValue={value => {
                        const { count } = value || {};
                        if (!value || !count) {
                          return 'color-empty';
                        }
                        if (count <= 3) {
                          return 'color-github-1';
                        } else if (count <= 6) {
                          return 'color-github-2';
                        } else if (count <= 9) {
                          return 'color-github-3';
                        } else {
                          return 'color-github-4';
                        }
                      }}
                      tooltipDataAttrs={value => {
                        if (value && value.date) {
                          return {
                            'data-tip': `${value.date} Total Progress Tally: ${
                              value.count
                              }`,
                          };
                        }
                      }}
                      showWeekdayLabels={true}
                      onClick={value => {
                        if (value && value.date) {
                          const records = (progressRecords
                            .filter(record => {
                              return record.latestProgressDate.includes(value.date);
                            })) || [];
                          records && records.length > 0 && openModal({ isOpen: true, records, itemId, targetDateStr: value.date });
                        }
                      }}
                    />
                    <ReactTooltip />
                  </div>
                )
              }

              <div className="bg-white shadow rounded p-2">
                <div className="d-flex justify-content-center align-items-center pb-1 flex-wrap">
                  <p className="h4 px-4">
                    My Category: &nbsp;
                    <span
                      className="badge badge-pill badge-info py-2 px-3 m-2"
                      style={{
                        color: categoryColor ? invert(categoryColor, true) : '',
                        backgroundColor: (categoryColor) || ''
                      }}
                    >
                      { categoryTitle }
                    </span>
                  </p>
                  <p className="h4">
                    tags: &nbsp;
                    { tags }
                  </p>
                </div>
                <hr className="m-0 w-75 m-auto" />
                <div className="description p-2 text-center">
                  <p className="h3 pb-2 text-center">Description</p>
                  { description }
                </div>
                <hr className="m-0 w-75 m-auto" />
                <div className="notes p-2 text-left">
                  <CollapsibleInline
                    initialStatus={true}
                    title="Summary Notes"
                    className="justify-content-center"
                    titleClassName=""
                  >
                    <div className="ql-editor p-3" dangerouslySetInnerHTML={{__html: notes }} />
                  </CollapsibleInline>
                </div>
              </div>
            </Col>
          </Row>
        )
      }
      <Row className="pb-4 mb-4">
        <div className="col-sm-12">
          <div className="text-center bg-white shadow rounded py-2 px-2">
            <h3 className="p-2">Progress Records/Comments</h3>
            {
              myId === postAuthorId && (
                <div>
                  <FiPlusSquare
                    size={20}
                    className="text-success"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setProgressRecordModalOpen(true)}
                  />
                  &nbsp;
                  Add Progress
                </div>
              )
            }
            {
              (allowOthersComment || (myId === postAuthorId)) && (
                <div>
                  <p className="h4 p-2">
                    Comments
                    &nbsp;
                    <FiMessageCircle
                      className="text-info"
                      size={20}
                    />
                  </p>
                  <CommentInputBox
                    authenticated={authenticated}
                    itemId={itemId}
                    refetchQueries={[{ query: ITEM, skip: !itemId, variables: { id: itemId }, suspend: false }]}
                  />
                </div>
              )
            }
            <div className="commentsAndProgressRecords my-2 border">
              {
                progressAndComments.map((itemObj, i) => {
                  const isProgressRecord = Object.keys(itemObj).includes('latestProgressDate');
                  if (isProgressRecord) {
                    const { id, notes, latestProgressDate, progressTally } = itemObj || {};
                    return (
                      <div
                        key={latestProgressDate + i}
                        className="bg-white shadow mt-1 mb-3 p-3 rounded"
                      >
                        <div className="d-flex align-items-center flex-wrap">
                          <AvatarImage
                            className="mr-2"
                            avatarUrl={(authorAvatarUrl) ? authorAvatarUrl : UserIcon}
                            style={{ width: '3rem', height: '3rem', borderRadius: "100%", objectFit: "cover" }}
                          />
                          <span className="font-weight-bold">{ authorUsername }</span>
                          &nbsp;
                          <span className={ `mb-0 ${progressTally >= 0 ? 'text-success' : 'text-danger'}` }>
                        { latestProgressDate && moment(latestProgressDate).format('ddd D MMM YYYY, hh:mm a') }
                      </span>
                          &nbsp;
                          <span className={ `badge badge-pill badge-info ${progressTally >= 0 ? 'badge-success' : 'badge-danger'}` }>
                      { progressTally > 0 ? `+${progressTally}` : progressTally }
                      </span>
                          {
                            myId === postAuthorId && (
                              <div className="py-1 px-3 mb-2">
                                <FiEdit
                                  size={20}
                                  className="text-primary"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setSelectedProgressRecord(id);
                                    setIsPREditMode(true);
                                  }}
                                />
                                &nbsp;
                                <FiTrash
                                  size={20}
                                  className="text-danger"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setSelectedProgressRecord(id);
                                    setDeletePRModalOpen(true);
                                  }}
                                />
                              </div>
                            )
                          }
                        </div>
                        <ProgressRecordDisplay
                          record={itemObj}
                          refetchQueries={[{ query: MY_ITEMS }, { query: MY_PROGRESS_RECORDS }]}
                          isEditMode={isPREditMode && id === selectedProgressRecord}
                          setIsEditMode={setIsPREditMode}
                        />
                      </div>
                    );
                  } else {
                    const { id, author, body, createdAt } = itemObj || {};
                    return (
                      <div
                        key={id}
                        className={`mb-2 d-flex ${postAuthorId === author.id ? "" : "justify-content-end"}`}
                      >
                        <div className="comment bg-white shadow mb-0 p-3 d-flex align-items-center">
                          <p className="text-left pr-2">
                            <AvatarImage
                              className="mr-2"
                              avatarUrl={(author && author.avatarUrl) ? author.avatarUrl : UserIcon}
                              style={{ width: '3rem', height: '3rem', borderRadius: "100%", objectFit: "cover" }}
                            />
                            <span className="font-weight-bold">{ author && author.username }</span>
                            &nbsp;
                            <span className="mb-0">
                        { moment(createdAt).format('ddd D MMM YYYY, hh:mm a') }
                      </span>
                          </p>
                          <p className="text-left">
                            &nbsp;
                            ~ { body }
                            {
                              myId === author.id && (
                                <FiTrash
                                  size={20}
                                  className="ml-2"
                                  color="#942933"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => openDeleteCommentModal(true, { id, title: 'Delete Comment'})}
                                />
                              )
                            }
                          </p>
                        </div>
                      </div>
                    );
                  }
                })
              }
            </div>
          </div>
        </div>
      </Row>
    </Container>
  )
};

export default ProgressPost;
