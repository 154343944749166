import React, {Fragment} from 'react';
import { RingSpinner } from "react-spinners-kit";
import Container from 'reactstrap/lib/Container';
import Button from 'reactstrap/lib/Button';

const LoadingPage = ({ texts, error, retry, small, hideLoadingText=false }) => {
  if (error) {
    return (
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <div className="text-center">
          <p>There was an error loading the page.</p>
          <p>Please check your internet connection.</p>
          <p><Button outline onClick={ retry }>Retry</Button></p>
        </div>
      </Container>

    );
  } else {
    return (
      <Container className={`d-flex justify-content-center align-items-center ${small ? "" : "min-vh-100"}`}>
        <div>
          <div className="d-flex justify-content-center">
            <RingSpinner
              size={30}
              color="gray"
              loading={true}
            />
          </div>
          {
            !hideLoadingText
            && (
              <div className="ml-2 py-3 text-center">
                {
                  texts
                    ? (
                      <Fragment>
                        {
                          texts.split('\n').map((text, i) => (
                            <p
                              key={text + i}
                            >
                              {text}
                            </p>
                          ))
                        }
                      </Fragment>
                    ) : (
                      <p> Loading...</p>
                    )
                }
              </div>
            )
          }
        </div>
      </Container>
    );
  }
};

export default LoadingPage;

