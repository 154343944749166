import React from "react";
import {Route, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import LoadingPage from "../../pages/LoadingPage";
import { useQuery } from 'react-apollo-hooks';
import { GET_AUTHENTICATION_STATUS } from './apollo';
const LoadableLogIn = Loadable({
  loader: () => import('../../pages/Home'),
  loading: LoadingPage
});

const PrivateRoute = (props) => {
  const { data, loading } = useQuery(GET_AUTHENTICATION_STATUS, { suspend: false });
  if (loading) return <LoadingPage />;
  if (!data.authenticationStatus || (data.authenticationStatus.authenticated === null)) {
    return null;
  } else if (data.authenticationStatus && (data.authenticationStatus.authenticated === false)) {
    return <Redirect exact to="/login" component={ LoadableLogIn } />;
  } else {
    return <Route { ...props } />;
  }
};

export default PrivateRoute;
