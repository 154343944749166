import gql from 'graphql-tag';

export const RESET_PASSWORD = gql`
    mutation resetPassword(
      $resetPasswordToken: String!,
      $newPassword: String!,
      $confirmNewPassword: String!
    ){
        resetPassword(
            resetPasswordToken: $resetPasswordToken,
            newPassword: $newPassword,
            confirmNewPassword: $confirmNewPassword
        ) {
            status
        }
    }
`;
