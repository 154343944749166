import gql from 'graphql-tag';

export const CONFIRM_ACCOUNT = gql`
  mutation confirmAccount($confirmationToken: String!){
      confirmAccount(confirmationToken: $confirmationToken) {
      status
    }
  }
`;

