import React from 'react';
import {PopoverBody, PopoverHeader, UncontrolledPopover} from "reactstrap";
import {Link} from "react-router-dom";
import { FiHeart } from 'react-icons/fi';

const LikesButtons = ({ uniquePrefix, id, likedByCount, toggleLike, authenticated, setLikedByModalOpen }) => {
  return (
    <div className="d-flex align-items-center">
      Likes
      <FiHeart
        id={`like${uniquePrefix}${id}`}
        size={20}
        className={`m-2 ${(likedByCount && likedByCount) > 0 ? 'text-danger': 'text-dark' }`}
        role="button"
        style={{ cursor: 'pointer'}}
        onClick={() => {
          if (authenticated) toggleLike({ variables: { id }});
        }}
      />
      {
        !authenticated && (
          <UncontrolledPopover trigger="legacy" placement="bottom" target={`like${uniquePrefix}${id}`}>
            <PopoverHeader>
              <span>Do you want to like this post?</span>
            </PopoverHeader>
            <PopoverBody>
              <Link style={{ cursor: 'pointer' }} className="d-flex justify-content-center" to="/login">Please sign in</Link>
            </PopoverBody>
          </UncontrolledPopover>
        )
      }
      {
        (likedByCount && likedByCount) > 0
          ? (
            <button
              style={{ background: "none", textDecoration: "underline", border: "none" }}
              className="px-1"
              onClick={() => setLikedByModalOpen && setLikedByModalOpen()}
            >
              {likedByCount}
            </button>
          )
          : ''
      }
    </div>
  );
};

export default LikesButtons;
