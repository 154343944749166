import React from 'react';
import FormBox from '../form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Button from 'reactstrap/lib/Button';

const ConfirmationModal = ({
  buttonColor, buttonText, selectedItem, isModalOpen, openModal, message, callbackOnConfirm, inputFieldsArr, hideModalFooter
}) => {


  return (
    <Modal isOpen={isModalOpen} toggle={() => openModal(false)}>
      <ModalHeader
        toggle={() => openModal(false)}>
        {selectedItem && selectedItem.title}
      </ModalHeader>
      <ModalBody>
        { message }
        {
          inputFieldsArr && inputFieldsArr.length > 0 && (
            <FormBox
              itemId={selectedItem && selectedItem.id}
              buttonText={ buttonText ? buttonText : "Confirm" }
              buttonColor={buttonColor}
              cancelFunc={() => openModal(false)}
              inputFieldsArr={inputFieldsArr}
              callbackOnSuccess={callbackOnConfirm}
            />
          )
        }
      </ModalBody>
      {
        !hideModalFooter && (
          <ModalFooter>
            <Button
              color="danger"
              onClick={callbackOnConfirm}
              className="rounded-0"
              outline
            >
              Confirm
            </Button>{' '}
            <Button
              color="secondary"
              onClick={() => openModal(false)}
              className="rounded-0"
              outline
            >
              Cancel
            </Button>
          </ModalFooter>
        )
      }
    </Modal>
  )
};

export default ConfirmationModal;
