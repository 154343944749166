const resetPasswordFieldsArr = [
  { type: 'password', label: 'New Password', name: 'newPassword', initialValue: '',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 8, errorMessage: 'Password should not be less than 8 characters long.' },
      maxLength: { value: 20, errorMessage: 'Password should not be more than 20 characters long.' },
    }
  },
  { type: 'password', label: 'Confirm New Password', name: 'confirmNewPassword', initialValue: '',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 8, errorMessage: 'Password should not be less than 8 characters long.' },
      maxLength: { value: 20, errorMessage: 'Password should not be more than 20 characters long.' },
    }
  }
];

export default resetPasswordFieldsArr;
