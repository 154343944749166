import React from "react";
import { Route } from "react-router-dom";
import { useQuery } from 'react-apollo-hooks';
import { GET_AUTHENTICATION_STATUS } from './apollo';
import LoadingPage from "../../pages/LoadingPage";

const CommonRoute = (props) => {
  const { data, loading } = useQuery(GET_AUTHENTICATION_STATUS, { suspend: false });
  if (loading) return <LoadingPage />;
  const authenticated = (data.authenticationStatus && data.authenticationStatus.authenticated);
  const { component: Component } = props || {};
  const newComponent = (props)=> <Component authenticated={authenticated} { ...props }/>;
  const newProps = { ...props, component: newComponent };
  return <Route {...newProps } />;
};

export default CommonRoute;
