import gql from 'graphql-tag';

export const REQUEST_NEW_PASSWORD_LINK = gql`
  mutation requestNewPasswordLink($email: String!){
    requestNewPasswordLink (email: $email) {
      status
    }
  }
`;

