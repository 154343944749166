import React from 'react';
import {DefaultToastContainer} from 'react-toast-notifications';

const ToastContainer = props => (
  <DefaultToastContainer
    style={{ zIndex: 3000 }}
    {...props}
  />
);

export default ToastContainer;
