import React from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap/lib';
import ItemForm from './ItemForm';
import './Item.scss';

const Item = (props) => {
  const itemId = props.match.params.itemId || '';
  return (
    <Container className="Item">
      <Row className="p-3 pb-3">
        <Col className="text-center">
          <h1 className="pb-3">{itemId ? 'Edit Your Item Summary' : 'What Do You Want To Track Progress On?'}</h1>
          {
            !itemId ? (
              <p className="mb-0">Once you create and save this item, then you will be able to start tracking progress on it.  You can always come back to edit this.</p>
            ) : (
              <p className="mb-0">Make sure to save in order to apply your changes.</p>
            )
          }
        </Col>
      </Row>
      <div className="p-3 bg-white rounded shadow">
        <ItemForm
          itemId={itemId}
          {...props}
        />
      </div>
    </Container>
  )
};

export default Item;
