import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {useQuery} from 'react-apollo-hooks';
import { ME } from './apollo';
import LoadingPage from "../../../pages/LoadingPage";
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import AvatarImage from '../../../components/AvatarImage';

const Dropdown = ({ pathname, items, logOut, closeNav }) => {
  const { data, loading } = useQuery(ME, { suspend: false });
  const { username, avatarUrl } = (data && data.me) || {};
  if (loading) return <LoadingPage small hideLoadingText />;
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" className={`nav-link ${pathname === '/settings' ? 'active' : '' }`} outline caret>
        <AvatarImage
          avatarUrl={avatarUrl}
          style={{ width: '3rem', height: '3rem', borderRadius: "100%", objectFit: "cover" }}
        />
        &nbsp;
        { username } &nbsp;
      </DropdownToggle>
      <DropdownMenu>
        {
          items && items.map(({ name, link }) => {
            return (
              <DropdownItem
                key={name}
                className="pl-3"
              >
                <Link
                  onClick={() => {
                    closeNav && closeNav();
                  }}
                  style={{ cursor: 'pointer' }}
                  className={`text-decoration-none nav-link ${link === '/settings' ? 'active' : '' }`}
                  to={link}
                >
                  { name }
                </Link>
              </DropdownItem>
            );
          })
        }
        {
          logOut && (
            <Fragment>
              <DropdownItem divider />
              <DropdownItem
                className="pl-4 nav-link"
                onClick={() => {
                  closeNav && closeNav();
                  logOut();
                }}
              >
                Log Out
              </DropdownItem>
            </Fragment>
          )
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default Dropdown;
