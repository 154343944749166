import gql from 'graphql-tag';

export const MY_PROFILE = gql`
    query {
        me {
            id
            username
            avatarUrl
            email
            createdAt
            updatedAt
        }
    }
`;

export const CHANGE_AVATAR_URL = gql`
    mutation changeAvatarUrl($avatarUrl: String){
        changeAvatarUrl (avatarUrl: $avatarUrl) {
            id
            username
            avatarUrl
        }
    }
`;
