import gql from 'graphql-tag';

export const GET_USER = gql`
    query user($username: String!) {
        user(username: $username) {
            id
            username
            avatarUrl
            createdAt
            updatedAt
        }
    }
`;

export const USER_ITEMS = gql`
    query userItems($username: String!) {
        items(username: $username) {
            id
            userId
            username
            avatarUrl
            title
            published
            likedByCount
            categoryId
            categoryTitle
            categoryColor
            description
            tags
            latestProgressDate
            latestProgressTally
            totalProgressTally
        }
    }
`;

export const ITEMS_USER_LIKES = gql`
    query itemsUserLikes($username: String!) {
        itemsUserLikes(username: $username) {
            id
            userId
            username
            avatarUrl
            title
            categoryId
            categoryTitle
            categoryColor
            description
            tags
            likedByCount
            latestProgressDate
            latestProgressTally
            totalProgressTally
        }
    }
`;

export const FOLLOWING = gql`
  query following($username: String!) {
      following(username: $username) {
          id
          username
          avatarUrl
      }
  }
`;

export const FOLLOWERS = gql`
    query followers($username: String!) {
        followers(username: $username) {
            id
            username
            avatarUrl
        }
    }
`;
