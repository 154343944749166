import gql from 'graphql-tag';

export const CREATE_COMMENT = gql`
    mutation createComment(
      $itemId: ID!,
      $body: String!,
      $published: Boolean!,
    ) {
      createComment(
        data: {
          itemId: $itemId,
          body: $body,
          published: $published,
        }
      ) {
        id
        published
        body
        author {
          username
        }
        createdAt
        updatedAt
      }
    }
`;
