import gql from 'graphql-tag';

export const VERIFY_USER = gql`
    query {
        verifyUser {
            verified
            csrfToken
        }
    }
`;

export const LOG_OUT = gql`
    mutation {
        logout
    }
`;

export const GET_AUTHENTICATION_STATUS = gql`
    {
        authenticationStatus @client {
            authenticated
        }
    }
`;

export const SET_AUTHENTICATED = gql`
  mutation SetAuthenticated($authenticated: Boolean!) {
      setAuthenticated(authenticated: $authenticated) @client
  }
`;

export const setAuthenticated = (parent, { authenticated }, { cache }, info) => {
  const newAuthStatus = { authenticated, __typename: "authenticationStatus" };
  const data = { authenticationStatus: newAuthStatus};
  cache.writeData({data});
  return newAuthStatus;
};
