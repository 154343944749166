import gql from 'graphql-tag';

export const CREATE_ITEM = gql`
    mutation createItem(
        $title: String!,
        $published: Boolean!,
        $allowOthersComment: Boolean!,
        $tags: String,
        $categoryId: ID,
        $description: String,
        $notes: String
    ){
        createItem(data: {
            title: $title,
            published: $published,
            allowOthersComment: $allowOthersComment,
            tags: $tags,
            categoryId: $categoryId,
            description: $description,
            notes: $notes
        }) {
            id
            title
        }
    }
`;

export const UPDATE_ITEM = gql`
    mutation updateItem(
        $id: ID!,
        $title: String!,
        $published: Boolean!,
        $allowOthersComment: Boolean!,
        $tags: String,
        $categoryId: ID,
        $description: String,
        $notes: String
    ){
        updateItem(
            id: $id,
            data: {
                title: $title,
                published: $published,
                allowOthersComment: $allowOthersComment,
                tags: $tags,
                categoryId: $categoryId
                description: $description,
                notes: $notes,
            }
        ) {
            id
            title
        }
    }
`;

export const MY_ITEM_WITH_CATEGORY_LIST = gql`
    query myItemWithCategoryList($id: ID!){
        myItem(id: $id) {
            id
            title
            published
            allowOthersComment
            tags
            category {
                id
                title
                color
            }
            description
            notes
        }
        myCategories {
            id
            title
            published
            color
        }
    }
`;
