const progressRecordFieldsArr = [
  { type: 'range', label: 'Progress Tally', name: 'progressTally', initialValue: 0,
    rangeLabels: {
      0: '0',
      1: 'Small Step (1)',
      10: 'Great Progress (10)'
    },
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minValue: { value: 1, errorMessage: 'Minimum tally is 1.' },
      maxValue: { value: 10, errorMessage: 'Maximum tally is 10.' }
    },
    helpMessage: "Record your progress tally as you feel."
  },
  { type: 'datetime', label: 'Latest Progress Date', name: 'latestProgressDate', initialValue: new Date(),
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
    }
  },
  { type: 'editor', label: 'Notes', name: 'notes', initialValue: '' }
];

export default progressRecordFieldsArr;
