import React, { useState } from 'react';
import ResetPasswordForm from './RequestNewPasswordLinkForm';
import Row from 'reactstrap/lib/Row';
import './RequestNewPasswordLink.scss';

const RequestNewPasswordLink = () => {
  const initialInstruction = 'Please send your registered email address below.  If we find a matching account, we will send you a password reset link to the email address.';
  const [showForm, setShowForm ] = useState(true);
  const [ instruction, setInstruction ] = useState(initialInstruction);
  const handleCallbackOnSuccess = () => {
    setInstruction('If your email address is registered, we have sent you a password reset link. Please check your email to see if you have received it.');
    setTimeout(()=>{
      setShowForm(false);
    }, 1);
  };
  return (
    <div className="RequestPasswordLink container-fluid">
      <Row className="h-100">
        <div className="col-lg-4 m-auto">
          <div className="requestPasswordLinkSection shadow px-4 pb-3 m-1">
            <div className="mt-5 p-4">
              <h1 className="text-center">Request Password Reset</h1>
            </div>
            <div>
              <div className="alert alert-info">
                <p className="mb-0">{instruction}</p>
              </div>
              {
                showForm
                && (
                  <ResetPasswordForm
                    callbackOnSuccess={handleCallbackOnSuccess}
                  />
                )
              }
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default RequestNewPasswordLink;
