import React from 'react';
import {
  Container,
  Row
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './HowToUse.scss';
import Helmet from "react-helmet";

const HowToUse = () => {
  return (
    <Container className="HowToUse h-100">
      <Helmet>
        <title>How To Use ProgressMAKR</title>
        <meta
          name="keywords"
          content="progressmaker, progress maker, productivity tracker, progress tracker, bullet journal, habit tracker, habit journal, wellbeing, life balance, mood tracker"
        />
        <meta
          name="description"
          content="This page shows you how to use ProgressMAKR app, which is an online app to keep track of your progress and wellbeing for yourself and with others. Try Free Today and create positive habits to make a difference in your life."
        />
      </Helmet>
      <Row className="pb-2">
        <div className="col-sm-12 px-0">
          <div className="titleSection" />
        </div>
      </Row>
      <Row>
        <div className="col-sm-10 offset-sm-2 p-4 m-auto">
          <div className="text-center">
            <h1 className="font-weight-bold pb-4">How To Use ProgressMAKR</h1>
            <p className="">The following video explains you how to use ProgressMAKR.</p>
          </div>
          <div className="iframe-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/C4zXH7Hn4uQ"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div className="p-4 text-center">
            <h2 className="font-weight-bold py-4">More Questions?</h2>
            <p>For inquiries and support, please send us an email at contact@progressmakr.com</p>
          </div>
          <div className="pb-4 text-center">
            <h2 className="font-weight-bold py-4">Do you want to sign up?</h2>
            <p>Please go to the <Link style={{ cursor: 'pointer' }} to="/signup">signup</Link> page.</p>
          </div>
        </div>
      </Row>
    </Container>
  )
};

export default HowToUse;
