import React from 'react';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const SortingArrows = ({targetCol, sortCol, onClick, ...rest }) => {
  const targetMatch = targetCol === sortCol.col;
  return (
    <div
      className="d-flex flex-column px-2 pt-1"
      onClick={onClick}
      role="button"
    >
      <FiChevronUp className={targetMatch && sortCol.state === 'ascending' ? 'text-primary' : 'grey' } />
      <FiChevronDown className={targetMatch && sortCol.state === 'descending' ? 'text-primary' : 'grey' } />
    </div>
  );
};

export default SortingArrows;
