import React from "react";
import ItemsTabSection from './ItemsTabSection';
import LikesTabSection from './LikesTabSection';
import FollowingTabSection from './FollowingTabSection';
import FollowersTabSection from './FollowersTabSection';

const profileTabItems = [
  { id: "items", title: "Items", renderItem: (profileName, data, authenticated, refetch)=> (<ItemsTabSection profileName={profileName} authenticated={authenticated} refetch={refetch} />) },
  { id: "likedItems", title: "Likes", renderItem: (profileName, data, authenticated, refetch)=> (<LikesTabSection profileName={profileName} authenticated={authenticated} refetch={refetch} />) },
  { id: "following", title: "Following", renderItem: (profileName, data, authenticated)=> (<FollowingTabSection profileName={profileName} following={data} authenticated={authenticated} />) },
  { id: "followers", title: "Followers", renderItem: (profileName, data, authenticated, refetch)=> (<FollowersTabSection profileName={profileName} followers={data} authenticated={authenticated} />) }
];

export default profileTabItems;
