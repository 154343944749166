import React, {useState} from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap/lib';

const TabContents = ({ authenticated, tabItems, extraInfo, contentData, refetch }) => {
  const [ activeTab, setActiveTab ] = useState('items');
  return (
    <div>
      <Nav tabs>
        {
          tabItems && tabItems.map(({ id, title }) => {
            return (
              <NavItem
                key={id}
                style={{ cursor: 'pointer' }}
              >
                <NavLink
                  className={activeTab === `${id}` ? "active text-primary": ""}
                  onClick={() => setActiveTab(id)}
                >
                  { title }
                </NavLink>
              </NavItem>
            );
          })
        }
      </Nav>
      <TabContent activeTab={activeTab}>
        {
          tabItems && tabItems.map(({ id, renderItem }) => {
            return (
              <TabPane key={id} tabId={id} className="bg-beige shadow p-2">
                {
                  renderItem(extraInfo, (contentData[id] || null), authenticated, refetch)
                }
              </TabPane>
            );
          })
        }
      </TabContent>
    </div>
  );
};

export default TabContents;
