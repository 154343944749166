import gql from 'graphql-tag';

export const ME = gql`
    query {
        me {
            id
            username
            avatarUrl
            email
            createdAt
            updatedAt
            following {
                id
                username
                avatarUrl
            }
        }
    }
`;
