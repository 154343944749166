import {setContext} from "apollo-link-context";
import {createUploadLink} from "apollo-upload-client/lib";
import {WebSocketLink} from "apollo-link-ws";
import {split} from "apollo-link";
import {getMainDefinition} from "apollo-utilities";
import {onError} from "apollo-link-error";
import {SET_MESSAGE} from "./App/components/ToastMessenger/apollo";
import {SET_AUTHENTICATED} from "./App/components/AuthProvider/apollo";
import {InMemoryCache} from "apollo-cache-inmemory";
import {ApolloClient} from "apollo-client";
import resolvers from "./App/resolvers";

const authLink = setContext(async(_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');

  const csrfToken = localStorage.getItem('csrfToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // authorization: token ? `Bearer ${token}` : '',
      'X-CSRF-TOKEN': csrfToken ? csrfToken : ''
    }
  }
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GQL_URI || "http://localhost:4040/graphql",
  credentials: 'include'
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WEBSOCKET_URI || "ws://localhost:4040/ws",
  options: {
    reconnect: true
  },
  credentials: 'include',
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const errorLink = onError((props) => {
  const { operation, response, graphQLErrors, networkError } = props;
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
        if (message === 'invalid token' || message === 'Authorization required' || message === 'jwt malformed') {
          const newMessage = message === 'Authorization required' ? { message: "You are logged out.", appearance: "success"} : { message: "You may be timed out.  Your action is not executed.  Please sign in again.", appearance: "warning"};
          client.mutate({ mutation: SET_MESSAGE, variables: newMessage, suspense: false })
            .then(() => {
              client.mutate({ mutation: SET_AUTHENTICATED, variables: { authenticated: false }, suspend: false });
              // localStorage.removeItem('token');
              setTimeout(()=> {
                client.resetStore();
              }, 1000);
            });
        }
        if (message === 'csrf tokens do not match') {
          window.location.reload();
        }
        return props;
      }
    );
  } else if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // if networkError.statusCode === 401
    client.mutate({ mutation: SET_MESSAGE, variables: { message: "Something went wrong with your network.  Please check your network and try again.", appearance: "warning"}, suspense: false })
    return props;
  }
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  ssrForceFetchDelay: 100,
  cache: cache.restore(window.__APOLLO_STATE__),
  link: authLink.concat(errorLink).concat(link),
  resolvers,
  credentials: 'include'
});

// // initial state is now set in server side (in server.js) so commented out below. // //
// // above client got the state into cache with 'cache: cache.restore(window.__APOLLO_STATE__)' // //
// set up default local state
// const authenticationStatus = {
//   authenticated: null,
//   __typename: "authenticationStatus"
// };
// const toastMessage = {
//   message: '',
//   appearance: '',
//   __typename: "toastMessage"
// };
//
// const data = {
//   authenticationStatus,
//   toastMessage
// };
// cache.writeData({data});
//
// client.onResetStore(() => {
//   cache.writeData({data});
// });

export default client;
