import React from 'react';
import { Link } from 'react-router-dom';
import { FiEdit, FiPlusSquare } from 'react-icons/fi';
import SortingArrows from './SortingArrows';
import Table from 'reactstrap/lib/Table';
import ListBody from './ListBody';
import './MyProgressTable.scss';

const MyProgressTable = ({ items, showChart, toggleSort, sortCol, openDeleteModal, openCategoryModal, openProgressRecordModal, filterTerm }) => {
  return (
    <Table className="MyProgressTable p-3 text-center shadow bg-light table table-striped table-bordered">
      <thead>
      <tr>
        <th width="20%">
          <div className="d-flex justify-content-center align-items-center">
            Item &nbsp;
            <Link style={{ cursor: 'pointer' }} to="/item/new">
              <FiPlusSquare
                size={20}
                className="text-success"
              />
            </Link>
            <SortingArrows
              targetCol="item"
              onClick={() => toggleSort('item')}
              sortCol={sortCol}
            />
          </div>
        </th>
        <th width="10%">
          <div className="d-flex justify-content-center align-items-center">
            Total Tally
            <SortingArrows
              targetCol="tally"
              onClick={() => toggleSort('tally')}
              sortCol={sortCol}
            />
          </div>
        </th>
        <th width="22%">
          <div className="d-flex justify-content-center align-items-center">
            <div className="px-2">Latest Progress</div>
            <SortingArrows
              targetCol="latestProgress"
              onClick={() => toggleSort('latestProgress')}
              sortCol={sortCol}
            />
          </div>
        </th>
        <th width="20%">
          <div className="d-flex justify-content-center align-items-center">
            Category &nbsp;
            <FiEdit
              size={20}
              className="text-primary"
              style={{ cursor: 'pointer' }}
              onClick={() => openCategoryModal(true)}
            />
            <SortingArrows
              targetCol="category"
              onClick={() => toggleSort('category')}
              sortCol={sortCol}
            />
          </div>
        </th>
        <th width="12%">
          <div className="d-flex justify-content-center align-items-center h-100">
            Tags
          </div>
        </th>
        <th width="8%">
          <div className="d-flex justify-content-center align-items-center">
            <span
              className="publishedHeaderText"
              title="Published"
              style={{ cursor: 'pointer' }}
            >
              Published
            </span>
            <SortingArrows
              targetCol="published"
              onClick={() => toggleSort('published')}
              sortCol={sortCol}
            />
          </div>
        </th>
        <th
          className="d-flex justify-content-center align-items-center"
          width="8%"
        >
          Delete
        </th>
      </tr>
      </thead>
      <ListBody
        filterTerm={filterTerm}
        showChart={showChart}
        openModal={openDeleteModal}
        openProgressRecordModal={openProgressRecordModal}
        items={items}
      />
    </Table>
  );
};

export default MyProgressTable;
