import React, {Fragment} from 'react';
import { Button, PopoverBody, PopoverHeader, UncontrolledPopover} from "reactstrap";
import {Link} from "react-router-dom";

const FollowButton = ({ parentId, id, authorId, followingThisUser, toggleFollow, authenticated }) => {
  return (
    <Fragment>
      <Button
        id={`follow${parentId}${id}`}
        size="sm"
        color="primary"
        className={`followBtn my-2 ${followingThisUser ? "active": ""}`}
        outline={!followingThisUser}
        onClick={() => {
          if (authenticated) toggleFollow({ variables: { id: authorId } });
        } }
      >
        {followingThisUser ? 'Following' : 'Follow'}
      </Button>
      {
        !authenticated && (
          <UncontrolledPopover trigger="legacy" placement="bottom" target={`follow${parentId}${id}`}>
            <PopoverHeader>
              <span>Do you want to follow this member?</span>
            </PopoverHeader>
            <PopoverBody>
              <Link style={{ cursor: 'pointer' }} className="d-flex justify-content-center" to="/login">Please sign in</Link>
            </PopoverBody>
          </UncontrolledPopover>
        )
      }
    </Fragment>
  );
};

export default FollowButton;
