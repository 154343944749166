import { lowerCaseValidationFunc, emailValidationFunc } from '../../components/form/validationFuncs';
import {emailPattern, lowerCasePattern} from "../../components/form/validationPatterns";

const signUpFieldsArr = [
  { type: 'email', label: 'Email', name: 'email', initialValue: '', placeholder: '',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      pattern: { value: emailPattern, errorMessage: 'Please enter a valid email address.' }
    }
  },
  { type: 'text', label: 'Username', name: 'username', initialValue: '',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 6, errorMessage: 'Username should not be less than 6 characters long.' },
      maxLength: { value: 20, errorMessage: 'Username should not be more than 20 characters long.' },
      pattern: { value: lowerCasePattern, errorMessage: 'Please use lower case characters without spaces for username.'} // lower case only
    }
  },
  { type: 'password', label: 'Password', name: 'newPassword', initialValue: '',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 8, errorMessage: 'Password should not be less than 8 characters long.' },
      maxLength: { value: 20, errorMessage: 'Password should not be more than 20 characters long.' },
    }
  },
  { type: 'password', label: 'Confirm Password', name: 'confirmNewPassword', initialValue: '',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 8, errorMessage: 'Password should not be less than 8 characters long.' },
      maxLength: { value: 20, errorMessage: 'Password should not be more than 20 characters long.' },
    }
  },
  { type: 'checkbox', label: 'I agree to terms and conditions.', name: 'termsAndConditions', link: 'https://app.termly.io/document/terms-and-conditions/7895ca5d-3bb1-4325-9e93-da1e2ef61797', initialValue: false,
    validationOptions: {
      required: {value: true, errorMessage: 'Your agreement is required.'},
      pattern: { value: /^true$/, errorMessage: 'Please agree to sign up.'}
    }
  },
];

export default signUpFieldsArr;
