import gql from 'graphql-tag';

export const LOG_IN = gql`
  mutation logIn($username: String!, $password: String!){
      logIn (data: {
          username: $username,
          password: $password
      }) {
          user {
              id
              username
          }
          accountConfirmed
      }
  }
`;

export const REQUEST_ACCOUNT_CONFIRMATION_EMAIL = gql`
  mutation requestAccountConfirmationEmail($username: String!) {
      requestAccountConfirmationEmail(username: $username) {
          status
      }
  }
`;

