import gql from 'graphql-tag';

export const CHANGE_EMAIL = gql`
  mutation changeEmail($newEmail: String!, $confirmNewEmail: String!, $password: String!){
      changeEmail (
          newEmail: $newEmail,
          confirmNewEmail: $confirmNewEmail,
          password: $password
      ) {
          id
          username
      }
  }
`;

