const wellbeingRecordFieldsArr = [
  { type: 'range', label: 'Overall Wellbeing', name: 'overallWellbeing', initialValue: 0,
    rangeLabels: {
      0: 'Not well (0)',
      10: 'Great (10)'
    },
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minValue: { value: 0, errorMessage: 'Minimum wellbeing is 0.' },
      maxValue: { value: 10, errorMessage: 'Maximum wellbeing is 10.' }
    },
    helpMessage: "How well do you feel overall?"
  },
  { type: 'range', label: 'Physical Wellbeing', name: 'physicalWellbeing', initialValue: 0,
    rangeLabels: {
      0: 'Not well (0)',
      10: 'Great (10)'
    },
    validationOptions: {
      minValue: { value: 0, errorMessage: 'Minimum wellbeing is 0.' },
      maxValue: { value: 10, errorMessage: 'Maximum wellbeing is 10.' }
    },
    helpMessage: "How well do you feel physically?"
  },
  { type: 'range', label: 'Emotional Wellbeing', name: 'emotionalWellbeing', initialValue: 0,
    rangeLabels: {
      0: 'Not well (0)',
      10: 'Great (10)'
    },
    validationOptions: {
      minValue: { value: 0, errorMessage: 'Minimum wellbeing is 0.' },
      maxValue: { value: 10, errorMessage: 'Maximum wellbeing is 10.' }
    },
    helpMessage: "How well do you feel emotionally and mentally?"
  },
  { type: 'range', label: 'Energy Level', name: 'energyLevel', initialValue: 0,
    rangeLabels: {
      0: 'Drained (0)',
      10: 'Fully-energised (10)'
    },
    validationOptions: {
      minValue: { value: 0, errorMessage: 'Minimum energy level is 0.' },
      maxValue: { value: 10, errorMessage: 'Maximum energy level is 10.' }
    },
    helpMessage: "How much energy do you feel you have left now?"
  },
  { type: 'tags', label: 'Tags', name: 'tags', initialValue: ''
  },
  { type: 'editor', label: 'Notes', name: 'notes', initialValue: '' }
];

export default wellbeingRecordFieldsArr;
