import gql from 'graphql-tag';

export const MY_ITEMS = gql`
    query myItems($query: String, $first: Int, $skip: Int) {
        myItems (query: $query, first: $first, skip: $skip) {
            id
            title
            published
            allowOthersComment
            likedByCount
            categoryId
            categoryTitle
            categoryColor
            description
            tags
            latestProgressDate
            latestProgressTally
            totalProgressTally
        }
    }
`;

export const DELETE_ITEM = gql`
    mutation deleteItem($id: ID!){
        deleteItem(id: $id) {
            id
            title
        }
    }
`;

export const DELETE_WELLBEING_RECORD = gql`
    mutation deleteItem($id: ID!){
        deleteWellbeingRecord(id: $id) {
            id
        }
    }
`;


export const UPDATE_ITEM_PUBLISHED_STATUS = gql`
    mutation updateItemPuplishedStatus(
    $id: ID!,
    $published: Boolean!
    ){
        updateItemPuplishedStatus(
            id: $id,
            data: {
                published: $published,
            }
        ) {
            id
            title
            published
        }
    }
`;

export const UPDATE_ALLOW_OTHERS_COMMENT_STATUS = gql`
    mutation updateAllowOthersCommentStatus(
        $id: ID!,
        $allowOthersComment: Boolean!
    ){
        updateAllowOthersCommentStatus(
            id: $id,
            data: {
                allowOthersComment: $allowOthersComment,
            }
        ) {
            id
            title
            allowOthersComment
        }
    }
`;

export const MY_PROGRESS_RECORDS = gql`
  {
    myProgressRecords(orderBy: latestProgressDate_ASC) {
      id
      latestProgressDate
      progressTally
      notes
      item {
        id
        title
        category {
          id
          title
          color
        }
      }
    }
  }
`;

export const MY_WELLBEING_RECORDS = gql`
    query myWellbeingRecords($gte: DateTime, $lte: DateTime) {
        myWellbeingRecords(orderBy: createdAt_DESC, gte: $gte, lte: $lte) {
            id
            overallWellbeing
            physicalWellbeing
            emotionalWellbeing
            energyLevel
            progressRecord {
                id
            }
            tags
            notes
            updatedAt
            createdAt
        }
    }
`;

export const DELETE_PROGRESS_RECORD = gql`
    mutation deleteProgressRecord($id: ID!){
        deleteProgressRecord(id: $id) {
            id
        }
    }
`;

export const LIKE = gql`
    mutation like($id: ID!, $status: Boolean) {
        like(id: $id, status: $status) {
            id
        }
    }
`;
