import React, { useState, useEffect } from 'react';
import { FiList, FiGrid, FiSearch } from 'react-icons/fi';
import './ListMenuBar.scss';
import Tooltip from "../../../components/Tooltip";
import useThrottleTerm from './useThrottleTerm';
import ListForm from './ListForm';
import usePrevious from '../../../utils/usePrevious';
import {  Button } from 'reactstrap';

const ListMenuBar = ({ listName, listLength, showTotal, listLengthSuffix=null, filteredListLength, maxPage, currentPage, selectPage, perPage, handlePerPageChange, width, switchingWidth, isCardsView, showCardsView, searchTerm, setSearchTerm }) => {
  const [ searchValue, setSearchValue ] = useState('');
  const throttledTerm = useThrottleTerm(searchValue);
  const previousTHrottledTerm = usePrevious(throttledTerm);
  useEffect(() => {
    setSearchTerm(throttledTerm);
    if (currentPage !==1 && (previousTHrottledTerm !== throttledTerm)) {
      selectPage && selectPage(1);
    }
  }, [setSearchTerm, previousTHrottledTerm, throttledTerm, selectPage, currentPage]);

  return (
    <div className="ListMenuBar container px-3">
      <div className="d-flex justify-content-lg-between justify-content-center align-items-center flex-wrap">
        {
          (!!listLength && showTotal) ? (<p className="mb-0">Total: { listLength } { listLengthSuffix }</p>) : null
        }
        <ListForm
          listName={listName}
          noKeywordsInput
          listLength={listLength}
          maxPage={maxPage}
          currentPage={currentPage}
          selectPage={selectPage}
          perPage={perPage}
          handlePerPageChange={handlePerPageChange}
          filteredListLength={filteredListLength}
          // searchTerms={searchTerms}
          // onHandleSubmit={onHandleSubmit}
          // handleKeywordChange={handleKeywordChange}
          // placeholder=""
          // helpMessage="Please type any keyword and hit enter to filter."
        />
        {
          !!listLength && (
            <div className="">
              <FiSearch
                className="text-primary"
                size={25}
              />
              &nbsp;
              <input
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value.toString());
                }}
              />
            </div>
          )
        }
        {
          width > switchingWidth
          && (
            <Button
              id="switchViewButton"
              className="viewToggle"
              color="link"
              outline
              size="sm"
              onClick={() => showCardsView(!isCardsView)}
            >
              {
                isCardsView
                  ? (
                    <FiList
                      className="text-secondary"
                      size={25}
                      title="Switch to List View"
                    />
                  )
                  : (
                    <FiGrid
                      className="text-secondary"
                      size={25}
                      title="Switch to Cards View"
                    />
                  )
              }
              <Tooltip
                target="switchViewButton"
                placement="top"
                comment={isCardsView ? "Switch to List View" : "Switch to Cards View"}
              />
            </Button>
          )
        }
      </div>
    </div>
  );
};

export default ListMenuBar;
