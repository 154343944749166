import React from 'react';
import { Switch } from 'react-router-dom';
import CommonRoute from '../AuthProvider/CommonRoute';
import PrivateRoute from '../AuthProvider/PrivateRoute';
import PublicOnlyRoute from '../AuthProvider/PublicOnlyRoute';
import Loadable from 'react-loadable';
import LoadingPage from '../../pages/LoadingPage';
import './Main.scss';

// const LoadableRequestNewPasswordLink = Loadable({
//   loader: () => import('../../pages/RequestNewPasswordLink'),
//   loading: LoadingPage
// });

import RequestNewPasswordLink from '../../pages/RequestNewPasswordLink';

// const LoadableResetPassword = Loadable({
//   loader: () => import('../../pages/ResetPassword'),
//   loading: LoadingPage
// });

import ResetPassword from '../../pages/ResetPassword';

// const LoadableLogIn = Loadable({
//   loader: () => import('../../pages/Home'),
//   loading: LoadingPage
// });

import LogIn from '../../pages/Home';

// const LoadableSignUp = Loadable({
//   loader: () => import('../../pages/SignUp'),
//   loading: LoadingPage
// });

import SignUp from '../../pages/SignUp';


// const LoadableConfirmAccount = Loadable({
//   loader: () => import('../../pages/ConfirmAccount'),
//   loading: LoadingPage
// });

import ConfirmAccount from '../../pages/ConfirmAccount';

// const LoadableProgressPosts = Loadable({
//   loader: () => import('../../pages/ProgressPosts'),
//   loading: LoadingPage
// });

import ProgressPosts from '../../pages/ProgressPosts';

// const LoadableProgressPost = Loadable({
//   loader: () => import('../../pages/ProgressPost'),
//   loading: LoadingPage
// });

import ProgressPost from '../../pages/ProgressPost';

// const LoadableMyArea = Loadable({
//   loader: () => import('../../pages/MyArea'),
//   loading: LoadingPage
// });

import MyArea from '../../pages/MyArea';

// const LoadableItem = Loadable({
//   loader: () => import('../../pages/Item'),
//   loading: LoadingPage
// });

import Item from '../../pages/Item';

// const LoadableSettings = Loadable({
//   loader: () => import('../../pages/Settings'),
//   loading: LoadingPage
// });

import Settings from '../../pages/Settings';

// const LoadableProfile = Loadable({
//   loader: () => import('../../pages/Profile'),
//   loading: LoadingPage
// });

import Profile from '../../pages/Profile';

// const LoadableStyleGuide = Loadable({
//   loader: () => import('../../pages/StyleGuide'),
//   loading: LoadingPage
// });

import StyleGuide from '../../pages/StyleGuide';

// const LoadableAcceptableUsePolicy = Loadable({
//   loader: () => import('../../pages/AcceptableUsePolicy'),
//   loading: LoadingPage
// });

import AcceptableUsePolicy from '../../pages/AcceptableUsePolicy';

import About from '../../pages/About';
import HowToUse from '../../pages/HowToUse';

const Main = () => (
  <main className="Main">
    <Switch>
      <PrivateRoute exact path="/item/new" component={ Item } />
      <PrivateRoute exact path="/item/edit/:itemId" component={ Item } />
      <PrivateRoute exact path="/myarea" component={ MyArea } />
      <PrivateRoute exact path="/settings" component={ Settings } />
      <PrivateRoute exact path="/styleguide" component={ StyleGuide } />
      <PublicOnlyRoute exact path="/requestpasswordreset" component={ RequestNewPasswordLink } />
      <PublicOnlyRoute exact path="/resetpassword/:resetPasswordToken" component={ ResetPassword } />
      <PublicOnlyRoute exact path="/signup" component={ SignUp } />
      <PublicOnlyRoute exact path="/confirmaccount/:confirmationToken" component={ ConfirmAccount } />
      <PublicOnlyRoute path="/login" component={ LogIn } />
      <CommonRoute exact path="/profile/:username" component={ Profile } />
      <CommonRoute exact path="/progressposts/:itemId" component={ ProgressPost } />
      <CommonRoute exact path="/acceptableusepolicy" component={ AcceptableUsePolicy } />
      <CommonRoute exact path="/about" component={ About } />
      <CommonRoute exact path="/howtouse" component={ HowToUse } />
      <CommonRoute exact path="/" component={ ProgressPosts } />
    </Switch>
  </main>
);


export default Main;
