import React from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip
} from 'recharts';
import ChartTooltip from '../ChartTooltip';

const HorizontalBarChart = ({ height, x, y, data }) => {
  return (
    <div style={{ height: height || '100%', margin: "auto" }}>
      <ResponsiveContainer>
        <BarChart
          layout="vertical"
          data={data}
          margin={{top: 5, right: 10, left: 20, bottom: 5}}
          maxBarSize={40}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis
            type="category" dataKey={y}
            tick={{ strokeWidth: 2, visibility: "hidden"}}
            width={-10}
          />
          <XAxis
            type="number"
            allowDecimals={false}
          />
          <Tooltip content={<ChartTooltip />}/>
          <Bar
            layout="vertical"
            dataKey={x}
            fill="#8884d8"
          >
            {
              data.map((item, index) => {
                return <Cell key={item.id} fill={item.fill} />;
              })
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HorizontalBarChart;


