import React, {useState, Fragment, useEffect} from 'react';
import _ from 'lodash';
import Button from 'reactstrap/lib/Button';
import {
  FiPlusSquare
} from 'react-icons/fi';
import CollapsibleInline from "../../../components/CollapsibleInline";
import {
  ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Dot
} from 'recharts';
import { FiEdit, FiTrash } from 'react-icons/fi';
import moment from 'moment';
import Moment from "react-moment";
import wellbeingColors from './wellbeingColors';
import DateRangeForm from "./DateRangeForm";
import WellbeingRecordForm from "../../../components/WellbeingPayloadModal/WellbeingRecordForm";
import {useMutation, useQuery} from "react-apollo-hooks";
import {DELETE_WELLBEING_RECORD, MY_WELLBEING_RECORDS} from "../apollo";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import WellbeingPayloadModal from "../../../components/WellbeingPayloadModal";

const WellbeingSection = () => {
  const [ selectedGraph, setSelectedGraph ] = useState('');
  const [ updatedRecord, setUpdatedRecord ] = useState(null);
  const [ isWREditMode, setIsWREditMode ] = useState(false);

  const [ selectedWellbeingPayload, setSelectedWellbeingPayload ] = useState(null);
  const [ isDeleteWBRecordModalOpen, setDeleteWBRecordModalOpen ] = useState(false);
  const [ selectedWBRecord, selectWBRecord ] = useState({ id: '', title: '' });

  const openDeleteWBRecordModal = (modalState, record={ id: '', title: ''}) => {
    selectWBRecord(record);
    setDeleteWBRecordModalOpen(modalState);
  };

  const deleteWBRecord = useMutation(
    DELETE_WELLBEING_RECORD,
    {
      variables: { id: selectedWBRecord && selectedWBRecord.id },
      // refetchQueries: [{ query: ITEMS }],
      suspense: false
    }
  );

  const handleDeleteWBRecordConfirmation = async () => {
    await deleteWBRecord();
    await wellbeingRefetch();
    openDeleteWBRecordModal(false);
    setSelectedWellbeingPayload(null);
  };

  let startDate = new Date();
  startDate.setDate(startDate.getDate() - 30);
  const initialRange = [startDate, new Date()];
  const [ wellbeingDateRange, setWellbeingDateRange ] = useState(initialRange);
  const [ wellbeingRecords, setWellbeingRecords ] = useState([]);
  const { error: wellbeingError, data: wellbeingData, loading: wellbeingLoading, refetch: wellbeingRefetch } = useQuery(MY_WELLBEING_RECORDS, { variables: { gte: wellbeingDateRange[0], lte: wellbeingDateRange[1] }, suspend: false, fetchPolicy: "cache-and-network" });
  const myWellbeingData = (wellbeingData && wellbeingData.myWellbeingRecords) || [];

  useEffect(() => {
    setWellbeingRecords(myWellbeingData);
  }, [JSON.stringify(myWellbeingData)]);

  useEffect(() => {
    setWellbeingRecords((prevRecords) => {
      if (!prevRecords || prevRecords.length === 0) {
        return (updatedRecord && Object.keys(updatedRecord).length > 0) ? [ updatedRecord ] : [];
      } else {
        const recordsWithoutDuplicate = prevRecords.filter((record) => record && (record.id !== (updatedRecord && updatedRecord.id)));
        return (updatedRecord && Object.keys(updatedRecord).length > 0) ? [ updatedRecord, ...recordsWithoutDuplicate ] : recordsWithoutDuplicate;
      }
    });
  }, [updatedRecord, setWellbeingRecords]);

  const myWellbeingDataForGraph = wellbeingRecords && wellbeingRecords
    .filter(item => item !== null)
    .map((item) => {
      const { id, overallWellbeing, physicalWellbeing, emotionalWellbeing, energyLevel, progressRecord, tags, notes, createdAt } = item || {};
      return { name: createdAt, overallWellbeing, physicalWellbeing, emotionalWellbeing, energyLevel, progressRecord, tags, notes, createdAt, id };
    })
    .sort((a, b) => {
      const aValue = a.createdAt;
      const bValue = b.createdAt;
      if (!aValue && !bValue) return 0;
      if (!aValue && bValue) return 1;
      if (aValue && !bValue) return -1;
      const aDate = new Date(aValue);
      const bDate = new Date(bValue);
      return (aDate < bDate) ? -1 : (aDate > bDate) ? 1 : 0;
    });

  const myWellbeingDataForList = myWellbeingDataForGraph && [ ...myWellbeingDataForGraph ]
    .sort((a, b) => {
      const aValue = a.createdAt;
      const bValue = b.createdAt;
      if (!aValue && !bValue) return 0;
      if (!aValue && bValue) return 1;
      if (aValue && !bValue) return -1;
      const aDate = new Date(aValue);
      const bDate = new Date(bValue);
      return (aDate < bDate) ? 1 : (aDate > bDate) ? -1 : 0;
    });

  return (
    <div>
      <DeleteConfirmationModal
        message="Are you sure you want to delete this Wellbeing Record?"
        selectedItem={selectedWBRecord}
        isModalOpen={isDeleteWBRecordModalOpen}
        openModal={openDeleteWBRecordModal}
        callbackOnConfirm={handleDeleteWBRecordConfirmation}
      />
      <WellbeingPayloadModal
        openDeleteWBRecordModal={openDeleteWBRecordModal}
        setWellbeingRecords={setWellbeingRecords}
        selectedPayload={selectedWellbeingPayload}
        wellbeingDateRange={wellbeingDateRange}
        isModalOpen={!!selectedWellbeingPayload}
        closeModal={() => setSelectedWellbeingPayload(null)}
      />

      <h2 className="text-center pt-2 pb-3">My Wellbeing</h2>
      <p className="display-4 text-center py-2">
        <span>How are you now?</span>
        &nbsp;
        <FiPlusSquare
          size={20}
          className="text-success"
          style={{ cursor: 'pointer' }}
          onClick={() => setSelectedWellbeingPayload({})}
        />
      </p>
      <div className="">
        <div className="text-center mt-4">
          Date Range:
          <Button
            color="link"
            role="button"
            onClick={() => {
              const bounced = _.debounce(()=>{
                setWellbeingDateRange(initialRange);
              }, 1000);
              bounced();
            }}
          >
            Reset to month
          </Button>
          <DateRangeForm
            initialRange={initialRange}
            wellbeingDateRange={wellbeingDateRange}
            setWellbeingDateRange={setWellbeingDateRange}
          />
        </div>
        <CollapsibleInline
          initialStatus={true}
          title="Wellbeing Chart"
          wrapperClassName="border shadow px-1 mx-md-3 mb-3"
          className="py-3 justify-content-center"
          titleClassName=""
          description="See how your wellbeing changes over time"
        >
          {
            myWellbeingDataForGraph && myWellbeingDataForGraph.length > 0 && (
              <Fragment>
                <div className="mt-1 mb-2" style={{ width: "100%", height: '15rem' }}>
                  <ResponsiveContainer>
                    <AreaChart
                      data={myWellbeingDataForGraph}
                      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                      <defs>
                        <linearGradient id="colorOverallWell" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorPhysicalWell" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#28a745" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#28a745" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorEmotionalWell" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#ffc107" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#ffc107" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorEnergyLevel" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#f44336" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#f44336" stopOpacity={0}/>
                        </linearGradient>
                      </defs>
                      <XAxis
                        dataKey="name"
                        height={60}
                        tick={({ x, y, stroke, payload })=> {
                          if (payload && payload.value) {
                            return (
                              <g transform={`translate(${x},${y})`}>
                                <text x={90} y={0} dy={16} textAnchor="end" fill="#666">{moment(payload.value).format('dd D MMM, hh:mm a')}</text>
                              </g>
                            );
                          } else {
                            return null;
                          }
                        }}
                      />
                      <YAxis
                        width={30}
                        domain={[0, 10]}
                        interval={0}
                        ticks={[0, 5, 10]}
                      />
                      <CartesianGrid
                        strokeDasharray="3 3"
                      />
                      <Tooltip
                        content={
                          ({ active, payload, label }) => {
                            if (active) {
                              return (
                                <div className="bg-white p-2 border">
                                  <p className="display-3"><Moment format="dddd D MMM YYYY, hh:mm a">{label}</Moment></p>
                                  {
                                    payload && payload.map(item => {
                                      return (
                                        <p key={item.name} className="label mb-0" style={{ color: item.color }}>{`${item.name} : ${item.value}`}</p>
                                      )
                                    })
                                  }
                                </div>
                              );
                            }
                            return null;
                          }
                        }
                      />
                      <Legend
                        formatter={(value, entry, index) => {
                          const { color } = entry;
                          return (<span className={ value === selectedGraph ? "px-1" : "" } style={{ border: value === selectedGraph ? `1px solid ${color}` : "", color, cursor: 'pointer' }}>{value}</span>);
                        }}
                        onClick={({ value })=> {
                          setSelectedGraph((prevValue) => {
                            if (prevValue === value) return "";
                            return value;
                          });
                        }}
                      />
                      <Area
                        type="monotone" dataKey="overallWellbeing"
                        stroke={(selectedGraph==="overallWellbeing" || selectedGraph === "") ? wellbeingColors["overallWellbeing"] : "" }
                        fillOpacity={(selectedGraph==="overallWellbeing" || selectedGraph === "") ? 1 : 0}
                        fill="url(#colorOverallWell)"
                        dot={true}
                        activeDot={<Dot onClick={({ payload }) => {
                          setSelectedWellbeingPayload(payload);
                        }} />}
                      />
                      <Area
                        type="monotone"
                        dataKey="physicalWellbeing"
                        stroke={(selectedGraph==="physicalWellbeing" || selectedGraph === "") ? wellbeingColors["physicalWellbeing"] : "" }
                        fillOpacity={(selectedGraph==="physicalWellbeing" || selectedGraph === "") ? 1 : 0}
                        fill="url(#colorPhysicalWell)"
                        dot={true}
                        activeDot={<Dot onClick={({ payload }) => {
                          setSelectedWellbeingPayload(payload);
                        }} />}
                      />
                      <Area
                        type="monotone"
                        dataKey="emotionalWellbeing"
                        stroke={(selectedGraph==="emotionalWellbeing" || selectedGraph === "") ? wellbeingColors["emotionalWellbeing"] : "" }
                        fillOpacity={(selectedGraph==="emotionalWellbeing" || selectedGraph === "") ? 1 : 0}
                        fill="url(#colorEmotionalWell)"
                        dot={true}
                        activeDot={<Dot onClick={({ payload }) => {
                          setSelectedWellbeingPayload(payload);
                        }} />}
                      />
                      <Area
                        type="monotone"
                        dataKey="energyLevel"
                        stroke={(selectedGraph==="energyLevel" || selectedGraph === "") ? wellbeingColors["energyLevel"] : "" }
                        fillOpacity={(selectedGraph==="energyLevel" || selectedGraph === "") ? 1 : 0}
                        fill="url(#colorEnergyLevel)"
                        dot={true}
                        activeDot={<Dot onClick={({ payload }) => {
                          setSelectedWellbeingPayload(payload);
                        }} />}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </Fragment>
            )
          }
        </CollapsibleInline>
        <CollapsibleInline
          initialStatus={true}
          title="Wellbeing Journal"
          wrapperClassName="border shadow px-1 mx-md-3 mb-3"
          className="py-3 justify-content-center"
          titleClassName=""
          description="Look back your wellbeing entries"
        >
          {
            myWellbeingDataForList && myWellbeingDataForList.map(props => {
              const { name, overallWellbeing, physicalWellbeing, emotionalWellbeing, energyLevel, progressRecord, tags, notes, createdAt, id } = props || {};
              return (
                <div
                  key={id}
                  className="m-2 p-2 bg-white border border-light shadow"
                >
                  <Moment format="dddd D MMM YYYY, hh:mm a">{name}</Moment>
                  {
                    name && (
                      <div className="d-flex flex-wrap align-items-center">
                        <FiEdit
                          size={20}
                          className="text-primary"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setUpdatedRecord(props);
                            setIsWREditMode((prevState)=> {
                              return !prevState;
                            });
                          }}
                        />
                        &nbsp;
                        <FiTrash
                          size={20}
                          className="text-danger"
                          style={{ cursor: 'pointer' }}
                          onClick={() => openDeleteWBRecordModal(true, { id, title: name })}
                        />
                      </div>
                    )
                  }
                  {
                    (isWREditMode && updatedRecord && updatedRecord.id === id) ?  (
                      <WellbeingRecordForm
                        setUpdatedRecord={setUpdatedRecord}
                        wellbeingRecordId={id}
                        updatedRecord={updatedRecord}
                        openModal={setIsWREditMode}
                        closePayloadModal={() => {
                          setIsWREditMode(false);
                          setUpdatedRecord(null);
                        }}
                        wellbeingDateRange={wellbeingDateRange}
                      />
                    ) : (
                      <div>
                        <p className="mb-0" style={{ color: wellbeingColors['overallWellbeing']}}>Overall Wellbeing: {overallWellbeing}</p>
                        <p className="mb-0" style={{ color: wellbeingColors['physicalWellbeing']}}>Physical Wellbeing: {physicalWellbeing}</p>
                        <p className="mb-0" style={{ color: wellbeingColors['emotionalWellbeing']}}>Emotional Wellbeing: {emotionalWellbeing}</p>
                        <p className="mb-0" style={{ color: wellbeingColors['energyLevel']}}>Energy Level: {energyLevel}</p>
                        <p className="mb-0">Tags: {tags}</p>
                        <div>
                          <p className="mb-0">Notes:</p>
                          <div className="ql-editor p-1" dangerouslySetInnerHTML={{__html: notes }} />
                        </div>
                      </div>
                    )
                  }
                </div>
              );
            })
          }
        </CollapsibleInline>
      </div>
    </div>
  );
};

export default WellbeingSection;
