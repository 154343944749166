import React from 'react';
import FormBox from '../../../components/form';
import deactivateAccountFieldsArr from './deactivateAccountFieldsArr';
import {DEACTIVATE_ACCOUNT} from "./apollo";

const DeactivateAccountForm = ({ history }) => {
  return (
    <div>
      <div className="alert alert-danger">
        <p>Do you really want to deactivate your account?</p>
        <p className="mb-0">Attention: Once you confirm with your password below, you will be immediately logged out.</p>
      </div>
      <FormBox
        mutation={DEACTIVATE_ACCOUNT}
        buttonText="Confirm"
        inputFieldsArr={deactivateAccountFieldsArr}
        compareEmails
        successMessage="Your account has been deactivated."
        callbackOnSuccess={() => {
          setTimeout(() => {
            history.push('/login');
          }, 1000);
        }}
      />
    </div>
  );
};

export default DeactivateAccountForm;
