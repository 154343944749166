import gql from 'graphql-tag';

export const CREATE_PROGRESS_RECORD = gql`
    mutation createProgressRecord(
        $itemId: ID!,
        $notes: String,
        $latestProgressDate: DateTime!,
        $progressTally: Int!
    ){
        createProgressRecord(data:{
            itemId: $itemId,
            notes: $notes,
            latestProgressDate: $latestProgressDate,
            progressTally: $progressTally
        }) {
            id
            notes
            latestProgressDate
            progressTally
            item {
                id
            }
        }
    }
`;

export const UPDATE_PROGRESS_RECORD = gql`
    mutation updateProgressRecord(
        $id: ID!,
        $notes: String,
        $latestProgressDate: DateTime!,
        $progressTally: Int!
    ){
        updateProgressRecord(
            id: $id,
            data:{
                notes: $notes,
                latestProgressDate: $latestProgressDate,
                progressTally: $progressTally
            }
        ) {
            id
            notes
            latestProgressDate
            progressTally
            item {
                id
            }
        }
    }
`;
