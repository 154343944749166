import gql from 'graphql-tag';

export const MY_CATEGORIES = gql`
  query {
      myCategories {
          id
          title
          published
          color
      }
  }
`;

export const CREATE_CATEGORY = gql`
    mutation createCategory(
    $title: String!,
    $published: Boolean!,
    $color: String!,
    $description: String,
    $notes: String
    ){
        createCategory(data: {
            title: $title,
            published: $published,
            color: $color,
            description: $description,
            notes: $notes
        }) {
            id
            title
        }
    }
`;

export const UPDATE_CATEGORY = gql`
    mutation updateCategory(
    $id: ID!,
    $title: String!,
    $color: String!,
    $published: Boolean!,
    $description: String,
    $notes: String
    ){
        updateCategory(
            id: $id,
            data: {
                title: $title,
                color: $color,
                published: $published,
                description: $description,
                notes: $notes,
            }
        ) {
            id
            title
        }
    }
`;

export const DELETE_CATEGORY = gql`
    mutation deleteCategory(
    $id: ID!,
    ){
        deleteCategory(
            id: $id
        ) {
            id
            title
        }
    }
`;

export const MY_CATEGORY = gql`
    query myCategory($id: ID!){
        myCategory(id: $id) {
            id
            title
            color
            published
            description
            notes
            updatedAt
            createdAt
            items {
                title
            }
        }
    }
`;
