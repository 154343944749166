import React, {useState, Fragment} from 'react';
import { useMutation } from "react-apollo-hooks";
import { SET_AUTHENTICATED } from '../AuthProvider/apollo';
import { RingSpinner } from "react-spinners-kit";
import DynamicInputField from "./DynamicInputField";
import AlternativeLink from './AlternativeLink';
import CategoryModal from '../CategoryModal';
import { hasErrors } from './errorHandlingFuncs';
import Button from 'reactstrap/lib/Button';

const FormBox = ({
  itemId, idAsItemId, className, fieldsWrapperClassName, inputFieldClassName, hasCategoryModal, mutation, refetchQueries, buttonColor, buttonText, inputFieldsArr,
  alternativeLinks, cancelFunc, authenticate=false, callbackOnSuccess, comparePasswords, compareEmails, resetPasswordToken, clearFieldsOnSubmit, signUp, customMessage, requestAccConfirmEmail, successMessage
}) => {
  const authenticateTrue = useMutation(SET_AUTHENTICATED, { variables: { authenticated: true }, suspend: false });
  const authenticateFalse = useMutation(SET_AUTHENTICATED, { variables: { authenticated: false }, suspend: false });

  const [ isLoading, setLoading ] = useState(false);
  const [validate, setValidate] = useState(false);
  const [ formMessage, setFormMessage ] = useState({ message: '', isError: false });
  const initialShowRequestEmailValues = { show: false, username: null, success: false };
  const [ showRequestEmail, setShowRequestEmail ] = useState(initialShowRequestEmailValues);
  const [isCategoryModalOpen, setCategoryModalOpen ] = useState(false);
  const [ formDisabled, setFormDisabled ] = useState(false);

  const sendMutation = useMutation(
    mutation,
    {
      refetchQueries,
      suspense: false
    }
  );

  const formatValue = (fieldType, value) => {
    if (fieldType === 'checkbox') {
      switch (value) {
        case 'true':
         return true;
        case 'false':
          return false;
        default:
          return value;
      }
    } else if (fieldType === 'number' || fieldType === 'range') {
      return value ? +value : null;
    } else if (fieldType === 'datetime') {
      return value ? new Date(value) : '';
    } else {
      return value;
    }
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    setShowRequestEmail(initialShowRequestEmailValues);
    const formTarget = e.target;
    setFormMessage({ message: '', isError: false });
    setValidate(true);

    const fieldValues = inputFieldsArr
      .reduce((acc, fieldObj) => {
        if (fieldObj.type === 'group') {
          fieldObj.groupFields.forEach(fieldObj => {
            let value = formTarget[fieldObj.name].value;
            acc[fieldObj.name] = formatValue(fieldObj.type, value);
          });
          return acc;
        } else {
          let value = formTarget && formTarget[fieldObj.name] && formTarget[fieldObj.name].value;
          acc[fieldObj.name] = formatValue(fieldObj.type, value);
        }
        return acc;
      }, {});

    const errors = inputFieldsArr
      .filter(({ type, name, validationOptions }) => hasErrors(validationOptions, fieldValues[name], type))
      .map(fieldObj => fieldObj.name);
    if(errors.length === 0) {
      let variables = {};
      if(itemId) {
        if(idAsItemId) {
          variables = { ...fieldValues, itemId };
        } else {
          variables = { ...fieldValues, id: itemId };
        }
      } else {
        variables = { ...fieldValues };
      }
      const passwordMisMatch = comparePasswords && (variables.newPassword !== variables.confirmNewPassword);
      const emailMisMatch = compareEmails && (variables.newEmail !== variables.confirmNewEmail);
      if (comparePasswords && resetPasswordToken) {
        variables = { resetPasswordToken, ...variables }
      }
      if (passwordMisMatch) {
        setFormMessage({ message: (customMessage && customMessage['comparePasswords']) ? customMessage['comparePasswords'] : 'New Password does not match with Confirm New Password.  Please enter the same new password twice.', isError: true });
      } else if (emailMisMatch) {
        setFormMessage({ message: 'New Email does not match with Confirm New Email.  Please enter the same new email twice.', isError: true });
      } else {
        setLoading(true);
        let data;
        let errors;
        if (mutation) {
          try {
            const { data: resultData, errors: resultErrors } = await sendMutation({ variables, errorPolicy: 'all' });
            data = resultData;
            errors = resultErrors;
          } catch (err) {
            errors = [{ message: "Oops, Something went wrong." }];
          }
          if (errors) {
            setFormMessage({ message: errors[0].message, isError: true });
            if(authenticate) {
              authenticateFalse();
            }
          } else {
            if (authenticate && (data && data.logIn && data.logIn.accountConfirmed)) {
              setFormMessage({ message: 'Success. Logging in...', isError: false });
              setTimeout(() => {
                authenticateTrue();
              }, 1000);
              if(callbackOnSuccess) callbackOnSuccess();
            } else if (authenticate && (data && data.logIn && !data.logIn.accountConfirmed)) {
              const { username } = (data.logIn.user) || {};
              setFormMessage({ message: 'Please confirm your account with the email we sent you.  If you are reactivating your account, you will need to request a confirmation email below.', isError: true });
              setShowRequestEmail({ show: true, username, success: false });
              if(callbackOnSuccess) callbackOnSuccess(null, data);
            } else if (signUp) {
              if(callbackOnSuccess) callbackOnSuccess();
            } else {
              setFormDisabled(true);
              if (successMessage) setFormMessage({ message: successMessage, isError: false });
              if(callbackOnSuccess) callbackOnSuccess(variables, data);
            }
          }
        } else {
          if(callbackOnSuccess) callbackOnSuccess(variables);
        }
        if (clearFieldsOnSubmit) formTarget.reset();
        setLoading(false);
      }
    } else {
      setFormMessage({ message: 'Please check your details.', isError: true })
    }
  };

  const openCategoryModal = (modalState) => {
    setCategoryModalOpen(modalState);
  };

  return (
    <Fragment>
      {
        hasCategoryModal
        && (
          <CategoryModal
            isModalOpen={isCategoryModalOpen}
            openModal={openCategoryModal}
          />
        )
      }
      <form
        className={className}
        onSubmit={onHandleSubmit}
      >
        <fieldset disabled={formDisabled}>
          <div className={fieldsWrapperClassName}>
            {
              inputFieldsArr.map(({
                                    type, label, link, rangeLabels, name, optionList, placeholder, validationOptions, helpMessage, value,  groupOuterClassName, groupInnerClassName, groupFields
                                  }, i) => {
                if (type === 'group') {
                  return (
                    <div key={name+i} className={groupOuterClassName}>
                      {
                        label && (<label>{label}</label>)
                      }
                      <div className={groupInnerClassName}>
                        {
                          groupFields && groupFields.map(({type, label, link, rangeLabels, name, optionList, placeholder, validate, value, helpMessage})  => (
                            <div key={name}>
                              <DynamicInputField
                                type={type}
                                label={label}
                                link={link}
                                name={name}
                                rangeLabels={rangeLabels}
                                className={inputFieldClassName}
                                optionList={optionList}
                                value={value}
                                placeholder={placeholder}
                                helpMessage={helpMessage}
                                validate={validate}
                                validationOptions={validationOptions}
                                openCategoryModal={openCategoryModal}
                              />
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div key={name}>
                      <DynamicInputField
                        type={type}
                        label={label}
                        link={link}
                        rangeLabels={rangeLabels}
                        name={name}
                        className={inputFieldClassName}
                        optionList={optionList}
                        value={value}
                        placeholder={placeholder}
                        helpMessage={helpMessage}
                        validate={validate}
                        validationOptions={validationOptions}
                        openCategoryModal={openCategoryModal}
                      />
                    </div>
                  );
                }
              })
            }
          </div>
        </fieldset>
        {
          formMessage.message
          && (
            <p className={formMessage.isError ? 'text-danger' : 'text-success'}>{formMessage.message}</p>
          )
        }
        {
          showRequestEmail && showRequestEmail.show && (
            <div>
              {
                showRequestEmail && showRequestEmail.success ? (
                  <p className="text-success">
                    Another confirmation email is sent to you just now.  Please check your email inbox.
                  </p>
                ) : (
                  <Fragment>
                    <p className="text-danger">
                      Would you like us to send you another confirmation email if you cannot find the previous one or it has expired?
                    </p>
                  </Fragment>
                )
              }
              <div>
                <Button
                  color="primary"
                  className="my-2 mr-2 rounded-0"
                  outline
                  onClick={() => {
                    const { username } = showRequestEmail || {};
                    requestAccConfirmEmail({ variables: { username }}).then(res => {
                      setShowRequestEmail({ show: true, username, success: true });
                    }).catch(err => {
                      setShowRequestEmail({ show: true, username, success: false });
                    });
                  }}
                  disabled={showRequestEmail && showRequestEmail.success}
                >
                  Yes, send me
                </Button>
              </div>
            </div>
          )
        }
        <div>
          <Button
            type="submit"
            color= { buttonColor || "primary" }
            className="my-2 mr-2 rounded-0"
            outline
            disabled={isLoading || formDisabled }
          >
            {
              isLoading ?
                <RingSpinner
                  size={30}
                  color="gray"
                  loading={true}
                />
                : buttonText
            }
          </Button>
          {
            alternativeLinks && alternativeLinks.map(linkObj => (<AlternativeLink key={linkObj.title&&linkObj.to} {...linkObj} />))
          }
          {
            cancelFunc
            && (
              <Button
                type="button"
                onClick={cancelFunc}
                color="secondary"
                className="my-2 mr-2 rounded-0"
                outline
              >
                Cancel
              </Button>
            )
          }
        </div>
      </form>
    </Fragment>
  )
};

export default FormBox;
