import Markdown from 'markdown-to-jsx';
import React, { useState } from 'react';
import {
  Container,
  Row,
  Button
} from 'reactstrap';
import './AcceptableUsePolicy.scss';

const documentStr = `
Please read this acceptable use policy ("policy", “AUP”) carefully before using progressmakr.com (“website”, "service") operated by Nobuyuki Fujioka trading as Abacus Learning Lab ("us", 'we", "our").

Services provided by us may only be used for lawful purposes. You agree to comply with all applicable laws, rules and regulations in connection with your use of the services. Any material or conduct that in our judgment violates this policy in any manner may result in suspension or termination of the services or removal of user’s account with or without notice.

### Prohibited use
You may not use the services to publish content or engage in activity that is illegal under applicable law, that is harmful to others, or that would subject us to liability, including, without limitation, in connection with any of the following, each of which is prohibited under this AUP:

- Phishing or engaging in identity theft
- Distributing computer viruses, worms, Trojan horses or other malicious code
- Distributing pornography or adult related content or offering any escort services
- Promoting or facilitating violence or terrorist activities
- Infringing the intellectual property or other proprietary rights of others

### Enforcement
Your services may be suspended or terminated with or without notice upon any violation of this policy. Any violations may result in the immediate suspension or termination of your account.

### Reporting violations
To report a violation of this policy, please contact us.

We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.


`;

const AcceptableUsePolicy = () => {
  return (
    <Container className="AcceptableUsePolicy">
      <Row className="p-3">
        <h1 className="m-auto">Acceptable Use Policy</h1>
      </Row>
      <div className="p-3">
        <Markdown
          options={{ forceBlock: true }}
        >
          {documentStr}
        </Markdown>
      </div>
    </Container>
  );
};

export default AcceptableUsePolicy;
