const data = {
  buttonText: "Information here..",
  titleText: "Popover TItle",
  body: [
    { textClassName: 'text-success', text: 'This is text 1.' },
    { textClassName: 'text-warning', text: 'This is text 2' },
    { textClassName: 'text-danger', text: 'This is text 3' }
  ]
};

export default data;
