import React, {useEffect, useState} from 'react';
import UserIcon from "../../assets/face-24px.svg";

const AvatarImage = ({ className, style, avatarUrl }) => {
  const [ imgSrc, setImgSrc ] = useState(UserIcon);
  useEffect(() => {
    if(avatarUrl) {
      setImgSrc(avatarUrl);
    }
  }, [avatarUrl]);
  return (
    <img
      style={style}
      className={className}
      src={imgSrc}
      alt="userIcon"
      onError={() => setImgSrc(UserIcon)}
    />
  );
};

export default AvatarImage;
