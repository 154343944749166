import {emailPattern} from '../../components/form/validationPatterns';

const requestNewPasswordLinkFieldsArr = [
  { type: 'email', label: 'Email', name: 'email', initialValue: '', placeholder: '',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      pattern: { value: emailPattern, errorMessage: 'Please enter a valid email address.' }
    }
  }
];

export default requestNewPasswordLinkFieldsArr;
