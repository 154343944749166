import React, { Fragment, useState } from 'react';
import HorizontalBarChart from '../../../components/charts/HorizontalBarChart';
import PieChart from '../../../components/charts/PieChart';
import Button from 'reactstrap/lib/Button';


const TalliesChart = ({ items }) => {
  const [ showBarChart, setShowBarChart ] = useState(true);
  const someProgressRecordsExist = items && items.some(item => item.latestProgressDate !== null);
  return (
    <Fragment>
      <h2 className="text-center p-2">Tallies By Progress Items</h2>
      <p className="text-center mb-0">Keep up with all your progress</p>
      {
        someProgressRecordsExist && (
          <Fragment>
            <div className="text-center pb-2">
              <Button
                size="sm"
                color="link"
                onClick={() => setShowBarChart((prevState) => !prevState)}
              >
                {
                  showBarChart ? "Switch to Pie Chart" : "Switch to Bar Chart"
                }
              </Button>
            </div>
            {
              showBarChart ? (
                <HorizontalBarChart
                  height={300}
                  x="totalProgressTally"
                  y="title"
                  data={ items }
                />
              ) : (
                <PieChart
                  height={300}
                  dataKey="totalProgressTally"
                  data={ items }
                />
              )
            }
          </Fragment>
        )
      }
    </Fragment>
  );
};

export default TalliesChart;
