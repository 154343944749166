import React from 'react';
import FormBox from '../../../components/form';
import deactivateAccountFieldsArr from './deactivateAccountFieldsArr';
import {DELETE_ACCOUNT} from "./apollo";

const DeleteAccountForm = ({ history }) => {
  return (
    <div>
      <div className="alert alert-danger" role="alert">
        <p>Attention: Once you confirm with your password below, you will be immediately logged out.</p>
        <p>
          <strong>Your account will be deleted IMMEDIATELY and PERMANENTLY, along with all your posts, comments, and images you stored in our image library.</strong>
        </p>
        <p className="mb-0">
          <strong>Do you really want to delete your account?</strong>
        </p>
      </div>
      <FormBox
        mutation={DELETE_ACCOUNT}
        buttonText="Confirm"
        inputFieldsArr={deactivateAccountFieldsArr}
        compareEmails
        successMessage="Your account has been deleted permanently."
        callbackOnSuccess={() => {
          setTimeout(() => {
            history.push('/login');
          }, 1000);
        }}
      />
    </div>
  );
};

export default DeleteAccountForm;
