import React from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';

import { Link } from 'react-router-dom';
import './About.scss';
import wellbeingTracker from '../../assets/wellbeing-tracker.png';
import myProgressCharts from '../../assets/myprogress-charts.png';
import progressItems from '../../assets/progress-items.png';
import ProgressItem from '../../assets/progress-item.png';
import ProgressRecords from '../../assets/progress-records.png';
import Helmet from "react-helmet";


const About = () => {
  return (
    <Container className="About h-100">
      <Helmet>
        <title>About ProgressMAKR</title>
        <meta
          name="keywords"
          content="progressmaker, progress maker, productivity tracker, progress tracker, bullet journal, habit tracker, habit journal, wellbeing, life balance, mood tracker"
        />
        <meta
          name="description"
          content="Online app to keep track of your progress and wellbeing for yourself and with others. Try Free Today and create positive habits to make a difference in your life."
        />
      </Helmet>
      <Row className="pb-2">
        <div className="col-sm-12 px-0">
          <div className="titleSection" />
        </div>
      </Row>
      <Row>
        <div className="col-sm-10 offset-sm-2 p-4 m-auto">
          <div className="text-center mt-1">
            <h1
              className="pb-2 text-center text-dark"
            >
              About ProgressMAKR
            </h1>
            <p className="text-dark display-3 mb-4 text-center d-flex flex-wrap align-items-center justify-content-center">The way to track your progress and wellbeing</p>
            <h2 className="font-weight-bold pt-3 pb-4">Keep Track of Your Progress and Wellbeing</h2>
            <p className="mb-0">
              ProgressMAKR lets you keep a record of your learning activities and goals as well as your wellbeing.</p>
            <p>By doing so, we hope that you will form positive activity habits while maintaining your life balance, self-care and wellbeing.</p>

            <p>To access this functionality, you will need to <Link style={{ cursor: 'pointer' }} to="/signup">create an account</Link> and <Link style={{ cursor: 'pointer' }} to="/login">log in</Link> to the site.</p>
            <img
              src={wellbeingTracker}
              className="w-100 border shadow-lg mb-4"
            />
            <img
              src={myProgressCharts}
              className="w-100 border shadow-lg mb-4"
            />
            <img
              src={progressItems}
              className="w-100 border shadow-lg mb-4"
            />
          </div>
          <div className="pb-4 text-center">
            <h2 className="font-weight-bold py-4">Join Us to build a community of people trying to make progress with whatever maybe</h2>
            <p>A person often encounters obstacles to keep up the momentum. It can be tough if you are all on your own.  You can shout out your struggles to the world.  Perhaps others will give you a little help along the way or you can.  There may be others who are going through the same things as you now.  That is why we hope to build a community of users who share and support the progress of their learning activities and goals with each other.
              <br />
              If you choose not to share the progress of some or any of your activities and goals, that is totally ok too.
            </p>
            <p>A list of progress which individual users decided to share in public will be displayed in <Link style={{ cursor: 'pointer' }} to="/">Progress Posts page</Link> of this site.</p>
            <img
              src={ProgressItem}
              className="w-100 border shadow-lg mb-4"
            />
            <img
              src={ProgressRecords}
              className="w-100 border shadow-lg mb-4"
            />
          </div>
          <div className="pb-4 text-center">
            <h2 className="font-weight-bold py-4">Tailored Recommendations</h2>
            <p>At later stage, we would like to provide users with tailored recommendations to connect with local services, communities, and groups so that you can keep making progress with your activities and goals.
            </p>
          </div>
          <div className="pb-4 text-center">
            <h2 className="font-weight-bold pt-4">It is FREE</h2>
            <p>Using the site and also signing up as a user is free for the current features.</p>
          </div>
          <div className="pb-4 text-center">
            <h2 className="font-weight-bold py-4">How to contact</h2>
            <p>For inquiries and feature requests, please send us an email at contact@progressmakr.com</p>
          </div>
          <div className="pb-4 text-center">
            <h2 className="font-weight-bold py-4">Do you want to sign up?</h2>
            <p>Please go to the <Link style={{ cursor: 'pointer' }} to="/signup">signup</Link> page.</p>
          </div>
        </div>
      </Row>
    </Container>
  )
};

export default About;
