import React, { useState } from 'react';
import { useMutation } from 'react-apollo-hooks';
import { CREATE_COMMENT } from './apollo';
import './CommentInputBox.scss';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import PopoverHeader from 'reactstrap/lib/PopoverHeader';
import UncontrolledPopover from 'reactstrap/lib/UncontrolledPopover';
import Button from 'reactstrap/lib/Button';
import {Link} from "react-router-dom";


const CommentInputBox = ({ authenticated, itemId, refetchQueries }) => {
  const [ comment, setComment ] = useState('');
  const createComment = useMutation(
    CREATE_COMMENT,
    {
      variables: {
        itemId,
        body: comment,
        published: true
      },
      refetchQueries
    }
  );

  const handleCreateComment = async () => {
    if (authenticated && (comment && comment.trim().length > 0)) {
      const response = await createComment();
      setComment('');
    }
  };

  return (
    <div className="CommentInputBox input-group mb-3 d-flex align-items-center">
      <textarea
        className="form-control"
        name="comment"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Leave your comments here."
        aria-label="comment"
      />
      <div>
        <Button
          id={`comment${itemId}`}
          className="m-2"
          color="secondary"
          outline
          type="button"
          onClick={handleCreateComment}
        >
          Send
        </Button>
        {
          !authenticated && (
            <UncontrolledPopover trigger="legacy" placement="bottom" target={`comment${itemId}`}>
              <PopoverHeader>
                <span>Do you want to comment?</span>
              </PopoverHeader>
              <PopoverBody>
                <Link style={{ cursor: 'pointer' }} className="d-flex justify-content-center" to="/login">Please sign in</Link>
              </PopoverBody>
            </UncontrolledPopover>
          )
        }
      </div>
    </div>
  );
};

export default CommentInputBox;
