import { setAuthenticated } from './components/AuthProvider/apollo';
import { setMessage } from './components/ToastMessenger/apollo';

const resolvers = {
  Mutation: {
    setAuthenticated,
    setMessage
  },
  Query: {
    // add imported schema here
  }
};

export default resolvers;
