import React from 'react';
import {useMutation } from "react-apollo-hooks";
import {MY_ITEMS, UPDATE_ITEM_PUBLISHED_STATUS} from "./apollo";

const PublishedCheckBox = ({ id, value }) => {
  const updatePublishedStatus = useMutation(
    UPDATE_ITEM_PUBLISHED_STATUS,
    {
      variables: { id, published: !value },
      refetchQueries: [{ query: MY_ITEMS }],
      suspense: false
    }
  );

  const handleUpdatePublishedStatus = () => {
    if (id) updatePublishedStatus();
  };

  return (
    <input
      type="checkbox"
      style={{ opacity: 1, margin: 0, position: 'inherit', cursor: 'pointer' }}
      id={id}
      checked={value}
      onChange={handleUpdatePublishedStatus}
    />
  );
};

export default PublishedCheckBox;
