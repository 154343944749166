import React, { useState, useEffect } from 'react';
// import ResetPasswordForm from './RequestNewPasswordLinkForm';
import { useMutation } from 'react-apollo-hooks';
import { CONFIRM_ACCOUNT } from './apollo';
import { Link } from 'react-router-dom';
import {Row} from "reactstrap/lib";
import './ConfirmAccount.scss';

const ConfirmAccount = (props) => {
  const confirmationToken = props.match.params.confirmationToken || '';
  const [ responseMessage, setResponseMessage ] = useState({ message: '', isError: false });
  const confirmAccount = useMutation(CONFIRM_ACCOUNT);

  useEffect(() => {
    if (confirmationToken) {
      confirmAccount({ variables: { confirmationToken }, suspense: false })
        .then(response => {
          setResponseMessage({ message: "You have successfully confirmed and activated your account.  Please log in with your username and password.", isError: false })
        })
        .catch(err => {
          const messages = err.graphQLErrors && err.graphQLErrors.length > 0 ? err.graphQLErrors.map(errObj => errObj.message).join(' ') : 'Oops, something went wrong.';
          setResponseMessage({ message: messages, isError: true });
        });

    }
  }, [confirmationToken]);

  return (
    <div className="ConfirmAccount container-fluid">
      <Row className="h-100">
        <div className="col-lg-4 m-auto">
          <div className="confirmAccountSection shadow px-4 pb-3 m-1">
            <div className="mt-5 p-4">
              <h1 className="text-center">Confirm Account</h1>
            </div>
            <div>
              {
                responseMessage.message
                && (
                  <p className={responseMessage.isError ? 'text-danger' : 'text-success'}>{responseMessage.message}</p>
                )
              }
            </div>
            <div>
              <Link style={{ cursor: 'pointer' }} to="/login">Log in</Link>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default ConfirmAccount;
