import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import './CollapsibleInline.scss';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const CollapsibleInline = ({ wrapperClassName, title, description, className, titleClassName, bodyClassName, children, initialStatus=false }) => {
  const [ open, setOpen ] = useState(initialStatus);
  return (
    <div className={`CollapsibleInline ${wrapperClassName}`}>
      <div
        className={`${className} text-center`}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ cursor: 'pointer' }}
          onClick={() => setOpen(!open)}
        >
          <p
            className={`${titleClassName || "h3"}`}
          >
            { title }
          </p>
          &nbsp;
          {
            open
              ? (
                <IoIosArrowUp
                  className={titleClassName}
                  size={20}
                />
              )
              :
              (
                <IoIosArrowDown
                  className={titleClassName}
                  size={20}
                />
              )
          }
        </div>
        {
          description && (
            <p className="mb-1">{description}</p>
          )
        }
      </div>
      <Collapse
        isOpen={open}
      >
        <div className={`${bodyClassName} py-1 text-left`}>
          {
            children
          }
        </div>
      </Collapse>
    </div>
  );
};

export default CollapsibleInline;

