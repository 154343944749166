import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from 'reactstrap/lib/Button';
import ProgressRecordForm from './ProgressRecordForm';

const ProgressRecordModal = ({
  selectedItem, isModalOpen, openModal, refetchQueries
}) => {
  const { id ='', title='' } = selectedItem || {};
  return (
    <Modal
      size="xl"
      isOpen={isModalOpen}
      toggle={() => openModal(false)}
    >
      <ModalHeader
        toggle={() => openModal(false)}
      >
        {title}
      </ModalHeader>
      <ModalBody>
        <ProgressRecordForm
          selectedItemId={id}
          selectedItemTitle={title}
          progressRecordId=""
          refetchQueries={refetchQueries}
          openModal={openModal}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          className="rounded-0"
          outline
          onClick={() => openModal(false)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProgressRecordModal;
