import React, { Fragment } from 'react';
import RadarChart from '../../../components/charts/RadarChart';

const CategoriesChart = ({ height, width, minWidth, items }) => {
  const categorySummaryObj = items && items.reduce((total, item) => {
    if (!item.categoryTitle) {
      if (item.totalProgressTally) {
        if(total['No Category']) {
          total['No Category'] += item.totalProgressTally;
        } else {
          total['No Category'] = item.totalProgressTally;
        }
      }
    } else {
      if (item.totalProgressTally) {
        if (total[item.categoryTitle]) {
          total[item.categoryTitle] += item.totalProgressTally;
        } else {
          total[item.categoryTitle] = item.totalProgressTally;
        }
      } else {
        if (total[item.categoryTitle]) {
          total[item.categoryTitle] += 0;
        } else {
          total[item.categoryTitle] = 0;
        }
      }
    }
    return total;
  }, {});

  const categoryKeys = Object.keys(categorySummaryObj) || [];
  const maxDomain = categoryKeys.reduce((final, key) => {
    if (categorySummaryObj[key] > final) return categorySummaryObj[key];
    return final;
  }, 0);
  const categorySummaryData = categoryKeys.map(key => {
    return {
      category: key,
      catTotal: categorySummaryObj[key],
      maxDomain
    }
  });

  return (
    <Fragment>
      <h2 className="text-center p-2">Total Tallies by Categories</h2>
      <p className="text-center mb-4">Keep balance in your life</p>
      <RadarChart
        height={height}
        width={width}
        minWidth={minWidth}
        maxDomain={maxDomain}
        dataKey="category"
        data={ categorySummaryData }
      />
    </Fragment>
  );
};

export default CategoriesChart;
