import React, { Fragment } from 'react';
import { Row, Col } from "reactstrap";
import {Link} from "react-router-dom";
import {useMutation, useQuery} from "react-apollo-hooks";
import {FOLLOW, ME} from "../ProgressPosts/apollo";
import FollowButton from "../../components/PublicItemCards/FollowButton";
import {FOLLOWERS, FOLLOWING} from "./apollo";
import AvatarImage from "../../components/AvatarImage";

const FollowersTabSection = ({ profileName, authenticated, hideFollowing=false, hideProfileLink }) => {
  const { data: meData, loading: meLoading } = useQuery(ME, { skip: !authenticated, suspend: false });
  const { data: followersData, loading: followersLoading } = useQuery(FOLLOWERS, { variables: { username: profileName }, suspend: false });

  const toggleFollow = useMutation(
    FOLLOW,
    {
      refetchQueries: [{ query: ME }, { query: FOLLOWERS, variables: { username: profileName } }, { query: FOLLOWING, variables: { username: profileName } }],
      suspense: false
    }
  );

  const { followers } = followersData || {};
  const { username: myUsername, following: myFollowing } = (meData && meData.me) || {};
  const followingUsernames = (myFollowing && myFollowing.map(user => user && user.username)) || [];
  return (
    <Fragment>
      <Row>
        <Col>
          {
            (followers && followers.length > 0) ? (
              <p>Below members are following {profileName}.</p>
            ): (
              <p>{profileName} does not have followers yet.</p>
            )
          }
        </Col>
      </Row>
      <Row className="mb-3">
        {
          followers && followers.map(({ id, username, avatarUrl }) => {
            const followingThisUser = username && followingUsernames.includes(username);
            return (
              <div
                key={id}
                className="col-lg-3 col-md-4 col-sm-6 mb-3"
              >
                <div
                  className="card h-100 shadow border-0 rounded"
                >
                  <div
                    className="card-body overflow-hidden rounded-bottom"
                    // style={{ height: '21rem' }}
                  >
                    <p className="d-flex align-items-center flex-wrap">
                      <AvatarImage
                        avatarUrl={avatarUrl}
                        style={{ width: '3rem', height: '3rem', borderRadius: "100%", objectFit: "cover" }}
                      />
                      {
                        hideProfileLink ? (
                          <span className="px-2">{ username }</span>
                        ) : (
                          <Link style={{ cursor: 'pointer' }} to={`/profile/${username}`}>
                            <span className="px-2">{ username }</span>
                          </Link>
                        )
                      }
                      {
                        !hideFollowing && (username !== myUsername) && (
                          <FollowButton
                            parentId="followersTab"
                            id={id}
                            authorId={id}
                            followingThisUser={followingThisUser}
                            toggleFollow={toggleFollow}
                            authenticated={authenticated}
                          />
                        )
                      }
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        }
      </Row>
    </Fragment>
  );
};

export default FollowersTabSection;
