import React, { Fragment } from 'react';
import Input from './Input';
import TagInput from './TagInput';
import DateTimeInput from './DateTimeInput';
import ColorPicker from './ColorPicker';
import doesInputTypeMatch from './doesInputTypeMatch';
import { FiDelete } from 'react-icons/fi';
import './Select.scss';

const DynamicInputField = ({ className, type, label, link, rangeLabels, name, optionList, value, placeholder='', validationOptions, helpMessage, validate, openCategoryModal }) => {
  return (
    <Fragment>
      {
        doesInputTypeMatch(type)('number', 'range', 'email', 'textarea', 'text', 'password', 'checkbox', 'editor')
        && (
          <Input
            className={className}
            type={type}
            label={label}
            link={link}
            rangeLabels={rangeLabels}
            name={name}
            initialValue={value || ''}
            validate={validate}
            validationOptions={validationOptions}
            helpMessage={helpMessage}
            placeholder={placeholder}
          />
        )
      }
      {
        doesInputTypeMatch(type)('select')
        && (
          <Fragment>
            <Input
              className={`Select ${className}`}
              type={type}
              label={label}
              name={name}
              initialValue={value || ''}
              validate={validate}
              validationOptions={validationOptions}
              helpMessage={helpMessage}
              placeholder={placeholder}
              openCategoryModal={openCategoryModal}
              optionList={optionList}
            >
              <option value=''>No category selected</option>
              {
                optionList && optionList.map(({ id, label }) => (
                  <option key={id} value={id}>{label}</option>
                ))
              }
            </Input>
          </Fragment>
        )
      }
      {
        doesInputTypeMatch(type)('tags')
        && (
          <TagInput
            className={className}
            label={label}
            name={name}
            validate={validate}
            validationOptions={validationOptions}
            initialTags={value}
            tagClassName="bg-warning p-1 mr-1 rounded"
            TagDeleteIcon={()=><FiDelete />}
            placeholder={placeholder}
            helpMessage={helpMessage}
          />
        )
      }
      {
        doesInputTypeMatch(type)('colorPicker')
        && (
          <ColorPicker
            className={className}
            label={label}
            name={name}
            initialValue={value || ''}
            validate={validate}
            validationOptions={validationOptions}
            helpMessage={helpMessage}
            placeholder={placeholder}
          />
        )
      }
      {
        doesInputTypeMatch(type)('datetime')
        && (
          <DateTimeInput
            id={name}
            label={label}
            name={name}
            className={className}
            initialValue={value}
            step={1}
            validate={validate}
            validationOptions={validationOptions}
            helpMessage={helpMessage}
          />
        )
      }
    </Fragment>
  );
};

export default DynamicInputField;
