import React, { useState, Fragment } from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';

import ResetPasswordForm from './ResetPasswordForm';
import { Link } from 'react-router-dom';
import './ResetPassword.scss';

const ResetPassword = (props) => {
  const [ success, setSuccess ] = useState(false);
  const resetPasswordToken = props.match.params.resetPasswordToken || '';
  return (
    <Container className="ResetPassword container-fluid">
      <Row className="p-3">
        <h1 className="m-auto">Password Reset</h1>
      </Row>
      {
        !success
        ? (
          <Fragment>
            <Row className="p-3">
              <p className="m-auto">Please enter your new password for your account.</p>
            </Row>
            <Row className="p-3">
              <ResetPasswordForm
                resetPasswordToken={resetPasswordToken}
                callBackOnSuccess={() => {
                  setSuccess(true);
                }}
              />
            </Row>
          </Fragment>
          )
          : (
            <Row className="p-3">
              <p className="m-auto">Your password is reset.  Please try <Link style={{ cursor: 'pointer' }} to='/login'>log in</Link> with your new password.</p>
            </Row>
          )
      }
    </Container>
  )
};

export default ResetPassword;
