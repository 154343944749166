import React from 'react';
import Row from 'reactstrap/lib/Row';

import './Home.scss';
import LoginSection from './LogInSection/index';
import Helmet from "react-helmet";

const Home = (props) => {
  return (
    <div className="Home container-fluid">
      <Helmet>
        <title>Log In for ProgressMAKR</title>
        <meta
          name="keywords"
          content="progressmaker, progress maker, productivity tracker, progress tracker, bullet journal, habit tracker, habit journal, wellbeing, life balance, mood tracker"
        />
        <meta
          name="description"
          content="Log In page for ProgressMAKR, online app to keep track of your progress and wellbeing for yourself and with others. Try Free Today and create positive habits to make a difference in your life."
        />
      </Helmet>
      <Row className="h-100">
        <div className="col-lg-6 m-auto">
          <div className="loginSection shadow px-4 pb-3 m-1">
            <LoginSection {...props} />
          </div>
        </div>
      </Row>
    </div>
  )
};

export default Home;
