import { lowerCaseValidationFunc } from '../../../components/form/validationFuncs';
import {lowerCasePattern} from "../../../components/form/validationPatterns";

const logInFieldsArr = [
  { type: 'text', label: 'Username', name: 'username', initialValue: '',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 6, errorMessage: 'Username should not be less than 6 characters long.' },
      maxLength: { value: 20, errorMessage: 'Username should not be more than 20 characters long.' },
      pattern: { value: lowerCasePattern, errorMessage: 'Please use lower case characters without spaces for username.'} // lower case only
    }
  },
  {
    type: 'password', label: 'Password', name: 'password', initialValue: '', placeholder: '',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 8, errorMessage: 'Password should not be less than 8 characters long.' },
      maxLength: { value: 20, errorMessage: 'Password should not be more than 20 characters long.' },
    }
  },
];

export default logInFieldsArr;
