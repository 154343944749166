import React from 'react';
import FormBox from '../../form';
import { CREATE_CATEGORY, UPDATE_CATEGORY, MY_CATEGORY, MY_CATEGORIES } from "../apollo";
import categoryFieldsArr from './categoryFieldsArr';
import { useQuery } from "react-apollo-hooks";
import LoadingPage from '../../../pages/LoadingPage';

const CategoryForm = ({ categoryId, showEditScreen }) => {
  // get myItem by Query
  const {error, data, loading } = useQuery(MY_CATEGORY, { variables: { id: categoryId }, suspend: false, fetchPolicy: "cache-and-network" });
  if (loading) return <LoadingPage />;
  // fieldsData from Query
  const fieldsData = data.myCategory[0] || {};
  // merge the data into inputFieldsArr.
  const inputFieldsArr = categoryFieldsArr
    .map(fieldObj => {
      fieldObj.value = fieldsData[fieldObj.name] || fieldObj.initialValue;
      return fieldObj;
    });

  return (
    <FormBox
      itemId={categoryId}
      mutation={categoryId ? UPDATE_CATEGORY : CREATE_CATEGORY}
      refetchQueries={[{ query: MY_CATEGORIES }]}
      buttonText="Save"
      cancelFunc={() => showEditScreen(false)}
      inputFieldsArr={inputFieldsArr}
      callbackOnSuccess={() => {
        setTimeout(() => {
          showEditScreen(false);
        }, 1);
      }}
    />
  );
};

export default CategoryForm;
