import React, { useState } from 'react';
import Row from 'reactstrap/lib/Row';
import SignUpForm from './SignUpForm';
import './SignUp.scss';
import Helmet from "react-helmet";

const SignUp = (props) => {
  const [signedUp, setSignedUp] = useState(false);
  return (
    <div className="SignUp container-fluid">
      <Helmet>
        <title>Free Sign Up For ProgressMAKR</title>
        <meta
          name="keywords"
          content="progressmaker, progress maker, productivity tracker, progress tracker, bullet journal, habit tracker, habit journal, wellbeing, life balance, mood tracker"
        />
        <meta
          name="description"
          content="Free Sign Up for ProgressMAKR, online app to keep track of your progress and wellbeing for yourself and with others. Try Free Today and create positive habits to make a difference in your life."
        />
      </Helmet>
      <Row className="h-100">
        <div className="col-lg-6 m-auto">
          <div className="signupSection shadow px-4 pb-3 m-1">
            <div className="mt-4 p-3">
              <h1 className="text-center">Sign Up</h1>
            </div>
            {
              !signedUp ? (
                <SignUpForm
                  setSignedUp={setSignedUp}
                  {...props}
                />
              ) : (
                <div className="alert alert-info">
                  <p className="mb-1">Success!</p>
                  <p className="mb-1">We have sent you a confirmation link via email.</p>
                  <p className="mb-1">After your confirmation, you will be able to fully log in.</p>
                  <p className="mb-1">Thank you for signing up.</p>
                </div>
              )
            }
          </div>
        </div>
      </Row>
    </div>
  )
};

export default SignUp;
