import {lowerCasePattern} from "../../form/validationPatterns";

const categoryFieldsArr = [
  { type: 'text', label: 'Title', name: 'title', initialValue: null,
    validationOptions: {
      required: {value: true, errorMessage: 'This field is required.'},
      minLength: {value: 3, errorMessage: 'Title should not be less than 3 characters long.'},
      maxLength: {value: 30, errorMessage: 'title should not be more than 30 characters long.'}
    }
  },
  { type: 'colorPicker', label: 'Color', name: 'color', initialValue: '',
    validationOptions: {
      required: {value: true, errorMessage: 'This field is required.'},
      minLength: {value: 3, errorMessage: 'This field should not be less than 3 characters long.'},
      maxLength: {value: 30, errorMessage: 'This field should not be more than 30 characters long.'}
    },
    helpMessage: 'Please pick a color for this personal category.'
  },
  {
    type: 'checkbox', label: 'Published', name: 'published', initialValue: false,
    helpMessage: "If you don't publish this category, no item even published one will not be published in public Progress Posts page."
  },
  { type: 'textarea', label: 'Description', name: 'description', initialValue: '' },
  { type: 'textarea', label: 'Notes', name: 'notes', initialValue: '' },
];

export default categoryFieldsArr;
