import React, { useState } from 'react';
import { Tooltip as ReactstrapTooltip } from 'reactstrap';

const Tooltip = ({ target, placement, comment, autohide }) => {
  const [ tooltipOpen, setTooltipOpen ] = useState(false);
  const toggle = () => {
    setTooltipOpen(() => !tooltipOpen);
  };
  return (
    <ReactstrapTooltip
      placement={placement}
      isOpen={tooltipOpen}
      autohide={autohide}
      target={target}
      toggle={toggle}
      modifiers={{ flip: { behavior: ['bottom'] } }}
    >
      {
        comment
      }
    </ReactstrapTooltip>
  );
};

export default Tooltip;
