import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import { Button } from "reactstrap";

const Footer = () => {
  return (
    <div className="Footer bg-white shadow-sm p-2 text-center d-flex flex-wrap justify-content-center align-items-center">
      <div className="mb-0 pr-3">&copy;{new Date().getFullYear()} Abacus Learning Lab, All Rights Reserved</div>
      <div className="d-flex flex-wrap justify-content-center align-items-center">
        <div className="d-flex align-items-center justify-content-center flex-wrap">
          <a
            href="https://app.termly.io/document/terms-and-conditions/7895ca5d-3bb1-4325-9e93-da1e2ef61797"
            className="pr-2 mb-0 text-nowrap"
          >
            Terms & Conditions
          </a>
          <a
            href="https://app.termly.io/document/privacy-notice/e8973a07-bd64-4df1-8d02-c3ef88b8c7ee"
            className="pr-2 mb- text-nowrap"
          >
            Privacy Policy
          </a>
          <a
            href="https://app.termly.io/document/cookie-policy/bcbc2d3c-adbf-4923-ae4b-f9ddef3c742a"
            className="pr-2 mb- text-nowrap"
          >
            Cookie Policy
          </a>
          <Link style={{ cursor: 'pointer' }} to="/acceptableusepolicy" className="pr-2 mb-0 text-nowrap">
            Acceptable Use Policy
          </Link>
          <a
            href="https://app.termly.io/document/disclaimer/0fbd974a-51c7-40d8-bb00-edcba6c9e91a"
            className="pr-2 mb- text-nowrap"
          >
            Disclaimer
          </a>
        </div>
        <div>
          <Button
            size="sm"
            outline
            onClick={() => {
              if (typeof window !== 'undefined') {
                window.displayPreferenceModal();
              }
            }}>Manage Cookie Preferences
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
