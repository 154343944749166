import React from 'react';
import { Collapse } from 'reactstrap';
import './CollapsibleSection.scss';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const CollapsibleSection = ({ open, onClick, title, className, children }) => {
  return (
    <div className="CollapsibleSection mb-3 w-100">
      <div
        className={`${className} py-3 px-4 d-flex justify-content-between align-items-center`}
        style={{ cursor: 'pointer' }}
        onClick={onClick}
      >
        <h2
          className="sectionTitle"
        >
          { title }
        </h2>
        {
          open
          ? (
              <IoIosArrowUp
                // color="white"
                size={25}
              />
            )
            :
            (
              <IoIosArrowDown
                // color="white"
                size={25}
              />
            )
        }
      </div>
      <Collapse
        isOpen={open}
      >
        <div className="p-4 border mt-1 text-left">
          {
            children
          }
        </div>
      </Collapse>
    </div>
  );
};

export default CollapsibleSection;

