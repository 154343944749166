import React, { Fragment, useState, useEffect } from 'react';
import { selectErrorMessage } from './errorHandlingFuncs';
import { FiArrowDownCircle, FiArrowUpCircle, FiEdit } from 'react-icons/fi';
import Label from "reactstrap/lib/Label";
import Slider from 'react-rangeslider';
import './Input.scss';
import wellbeingColors from '../../pages/MyArea/WellbeingSection/wellbeingColors';
import Button from 'reactstrap/lib/Button';

const Input = ({ key, className, type, label, link, rangeLabels, name, initialValue, helpMessage, validate, validationOptions, openCategoryModal, optionList, children }) => {
  const [value, setValue] = useState(initialValue);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState("");
  const [ color, setColor ] = useState('');
  const Editor = typeof window !== 'undefined' ? require('../Editor').default : ()=><textarea />;
  useEffect(() => {
    if (touched) {
      setError(selectErrorMessage(validationOptions, value, type));
    } else {
      setError("");
    }
  }, [touched, value, validationOptions, type]);

  useEffect(() => {
    if(name === 'categoryId') {
      const foundOption = name === 'categoryId' ? optionList.find(option => option.id === value) : null;
      const selectedColor = foundOption ? foundOption.color : '';
      setColor(selectedColor);
    }
  }, [value, name, optionList]);

  useEffect(() => {
    if (validate) {
      setError(selectErrorMessage(validationOptions, value, type));
    }
  }, [validate, value, validationOptions, type]);


  const labelBackGroundColor = color ? { backgroundColor: color, padding: '0.2rem' } : {};
  const labelColor = wellbeingColors[name] ? wellbeingColors[name] : '';

  return (
    <div key={key} className={`Input form-group ${type === 'range' ? 'bg-light border p-2 rounded' : ''}`}>
      {
        label && type !== 'checkbox'
        && (
          <label
            htmlFor={name}
          >
            <span
              style={{ ...labelBackGroundColor, color: labelColor }}
            >
              {label}
            </span>
            {
              type==="range" && (
                <Fragment>
                  &nbsp;
                  <span>({(!value && value !==0) ? "Not Selected" : value })</span>
                  <Button
                    color="link"
                    size="sm"
                    onClick={(e) => {
                      e.preventDefault();
                      setValue("");
                    }}
                  >
                    Clear
                  </Button>
                </Fragment>
              )
            }
            {
              helpMessage && type === "range"
              && (
                <p className="pl-4 form-text text-muted mb-0">{ helpMessage }</p>
              )
            }
            {
              name === "categoryId"
              && (
                <Fragment>
                  &nbsp;
                  &nbsp;
                  <span>Create/edit categories</span>
                  &nbsp;
                  <FiEdit
                    size={20}
                    className="text-primary mb-1"
                    style={{ cursor: 'pointer' }}
                    onClick={() => openCategoryModal(true)}
                  />
                </Fragment>
              )
            }
            {
              type === 'number'
              && (
                <span>
                  &nbsp;&nbsp;
                  <FiArrowUpCircle
                    tabIndex="0"
                    size={20}
                    color="blue"
                    style={{ cursor: 'pointer' }}
                    onClick={()=> setValue(+value+1)}
                    onBlur={() => setTouched(true)}
                  />
                  &nbsp;
                  <FiArrowDownCircle
                    tabIndex="0"
                    size={20}
                    color="942933"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setValue(+value-1)}
                    onBlur={() => setTouched(true)}
                  />
                  &nbsp;
              </span>
              )
            }
          </label>
        )
      }
      {
        type === "select"
        && (
          <select
            className="form-control"
            id={name}
            name={name}
            onBlur={() => setTouched(true)}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          >
            { children }
          </select>
        )
      }
      {
        type === 'checkbox'
        && (
          <Label htmlFor={name} className="d-flex align-items-center" check>
            <input
              type="checkbox"
              name={name}
              value={!!value}
              checked={!!value}
              onChange={(e) => setValue(e.target.checked)}
              onBlur={() => setTouched(true)}
            />
            {
              label && (
                <span className="pl-2">{ label.includes('terms and conditions') ? <Fragment>I agree to <a href={link} target="_blank" rel="noopener noreferrer">terms and conditions.</a></Fragment> : label }</span>
              )
            }
          </Label>
        )
      }
      {
        type === 'editor'
        && (
          <div>
            <input
              id={name}
              name={name}
              value={value}
              readOnly
              style={{ display: 'none' }}
            />
            <Editor
              className="overflow-auto"
              value={value}
              handleTextChange={(value) => setValue(value)}
            />
          </div>
        )
      }
      {
        type === 'range' && (
          <div className={`range-${name} mx-2 px-2 pb-4`}>
            <input
              id={name}
              name={name}
              value={value}
              readOnly
              style={{ display: 'none' }}
            />
            <Slider
              min={0}
              max={10}
              step={1}
              value={+value}
              labels={rangeLabels}
              onChange={value => {
                setValue(value);
              }}
              onChangeComplete={() => setTouched(true)}
            />
          </div>
        )
      }
      {
        type !== 'select' && type !== 'checkbox' && type !== 'editor' && type !== 'range'
        && (
          <input
            className="form-control"
            id={name}
            name={name}
            type={type}
            onBlur={() => setTouched(true)}
            onChange={e => {
              if (type === 'number') {
                setValue(+e.target.value);
              } else {
                setValue(e.target.value);
              }
            }}
            value={value}
          />
        )
      }
      {
        helpMessage && type !== "range"
        && (
          <small className="form-text text-muted">{ helpMessage }</small>
        )
      }
      {error && <p className="text-danger">{error}</p>}
    </div>
  );
};

export default Input;
