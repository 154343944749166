import React from "react";

const ChartTooltip =(props) => {
  const { active, payload, label } = props;
  const dataSet = (payload && payload[0] && payload[0].payload) || {};
  if (active) {
    return (
      <div className="custom-tooltip bg-white p-3 border border-secondary">
        <p className="label mb-0">{dataSet.title}</p>
        <p className="mb-0">Total Tally: {dataSet.totalProgressTally}</p>
      </div>
    );
  }
  return null;
};

export default ChartTooltip;
