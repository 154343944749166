import React, {useEffect, useState} from 'react';
import moment from "moment";
import { FiEdit, FiTrash } from 'react-icons/fi';
import ProgressRecordDisplay from "../../pages/MyArea/ProgressRecordDisplay";
import {MY_ITEMS, MY_PROGRESS_RECORDS} from "../../pages/MyArea/apollo";
import Modal from "../Modal";

const ProgressRecordsModal = ({ data, openModal, modalStatus, selectedProgressRecord, setSelectedProgressRecord, setDeleteModalOpen }) => {
  const [ isPREditMode, setIsPREditMode ] = useState(false);
  const selectedDateStr = modalStatus.records[0] && modalStatus.records[0].latestProgressDate;
  const selectedYear = moment(selectedDateStr).format('dddd D MMM YYYY');
  const daysTotal = modalStatus.records.reduce((acc, record) => acc + record.progressTally, 0);

  useEffect(() => {
    openModal((prevState)=> {
      if (prevState.isOpen) {
        // if data has changed and modal state is open, then update the records with the new data.
        const records = (data && data.myProgressRecords && data && data.myProgressRecords
          .filter(record => {
            return record.latestProgressDate.includes(prevState.targetDateStr) && record.item.id === prevState.itemId;
          }))|| [];

        return { ...prevState, records };
      } else {
        return prevState;
      }
    });
  }, [data]);

  return (
    <Modal
      size="xl"
      title={`Progress Records on ${selectedYear}`}
      isOpen={modalStatus.isOpen}
      closeModal={()=> openModal({ isOpen: false, records: [], itemId: null, targetDateStr: '' })}
      centered
    >
      <div className="ProgressRecordsPopup">
        <p className="mb-1 display-4 p-2">
          Day's total: { daysTotal > 0 ? `+${daysTotal}` : daysTotal }
        </p>
        {
          modalStatus.records
            .sort((a, b) => {
              const first = a.latestProgressDate ? a.latestProgressDate : '';
              const second = b.latestProgressDate ? b.latestProgressDate : '';
              if (first > second) return -1;
              if (first < second) return 1;
              return 0;
            })
            .map((record) => {
              const { id, latestProgressDate, progressTally, notes } = record || {};
              const dateTimeStr = (latestProgressDate && moment(latestProgressDate).format('h:mm a')) || '';
              return (
                <div
                  key={id}
                  className="alert-info p-2 m-2 rounded"
                >
                  <p className={ `mb-2 mr-4 ml-1 ${progressTally >= 0 ? 'text-success' : 'text-danger'}` }>
                    {dateTimeStr}: {progressTally > 0 ? `+${progressTally}` : progressTally}
                  </p>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="mb-2">
                      <FiEdit
                        size={20}
                        className="text-primary"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedProgressRecord(id);
                          setIsPREditMode(true);
                        }}
                      />
                      &nbsp;
                      <FiTrash
                        size={20}
                        className="text-danger"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedProgressRecord(id);
                          setDeleteModalOpen(true);
                        }}
                      />
                    </div>
                  </div>
                  <ProgressRecordDisplay
                    record={record}
                    refetchQueries={[{ query: MY_ITEMS }, { query: MY_PROGRESS_RECORDS }]}
                    isEditMode={isPREditMode && id === selectedProgressRecord}
                    setIsEditMode={setIsPREditMode}
                  />
                </div>
              )
            })
        }
      </div>
    </Modal>
  );
};

export default ProgressRecordsModal;
