import React from 'react';
import FormBox from '../../../../components/form';
import dataRangeFieldsArr from './dataRangeFieldsArr';

const DateRangeForm = ({ initialRange, wellbeingDateRange, setWellbeingDateRange }) => {


  const inputFieldsArr = [
    {
      ...dataRangeFieldsArr[0],
      groupFields: [
        { ...dataRangeFieldsArr[0].groupFields[0], value: (wellbeingDateRange && wellbeingDateRange[0]) || dataRangeFieldsArr[0].groupFields[0].initialValue },
        { ...dataRangeFieldsArr[0].groupFields[1], value: (wellbeingDateRange && wellbeingDateRange[1]) || dataRangeFieldsArr[0].groupFields[1].initialValue }
      ]
    }
  ];

  return (
    <FormBox
      buttonText="Select"
      inputFieldsArr={inputFieldsArr}
      callbackOnSuccess={(variables) => {
        const { fromDateTime, toDateTime } = variables || {};
        if (!fromDateTime || !toDateTime) {
          setWellbeingDateRange(initialRange);
        } else {
          const startTime = fromDateTime.getTime();
          const endTime = toDateTime.getTime();
          if (!Number.isNaN(startTime) && !Number.isNaN(endTime) && startTime <= endTime) {
            setWellbeingDateRange([ fromDateTime, toDateTime]);
          } else {
            setWellbeingDateRange(initialRange);
          }
        }
      }}
    />
  );
};

export default DateRangeForm;
