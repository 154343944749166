import React from 'react';
import FormBox from '../../components/form';
import { REQUEST_NEW_PASSWORD_LINK } from './apollo';
import requestNewPasswordLinkFieldsArr from './requestNewPasswordLinkFieldsArr';

const RequestNewPasswordLinkForm = ({ callbackOnSuccess }) => (
  <FormBox
    mutation={REQUEST_NEW_PASSWORD_LINK}
    buttonText="Send"
    alternativeLinks={[{ title: 'Login', to: '/login' }]}
    inputFieldsArr={requestNewPasswordLinkFieldsArr}
    authenticate={false}
    callbackOnSuccess={callbackOnSuccess}
  />
);

export default RequestNewPasswordLinkForm;

