import gql from 'graphql-tag';

export const USERS_WHO_LIKE_THE_ITEM = gql`
    query usersWhoLikeTheItem($id: ID!) {
        usersWhoLikeTheItem(id: $id) {
            id
            username
            avatarUrl
        }
    }
`;
