import React, { useEffect, useState } from 'react';
import FormBox from '../../form';
import wellbeingRecordFieldsArr from './WellbeingRecordFieldsArr';
import { useMutation } from "react-apollo-hooks";
import {
  CREATE_WELLBEING_RECORD,
  UPDATE_WELLBEING_RECORD
} from './apollo';
import { MY_WELLBEING_RECORDS } from '../../../pages/MyArea/apollo';
import './WellbeingRecordForm.scss';

const WellbeingRecordForm = ({ wellbeingDateRange, setUpdatedRecord, updatedRecord, wellbeingRecordId, openModal, closePayloadModal }) => {

  const saveWellbeingRecord = useMutation(
    (wellbeingRecordId ? UPDATE_WELLBEING_RECORD : CREATE_WELLBEING_RECORD),
    {
      refetchQueries: () => [{ query: MY_WELLBEING_RECORDS, variables: { gte: wellbeingDateRange && wellbeingDateRange[0], lte: new Date() } }],
      suspense: false
    }
  );
  const handleSaveWellbeingRecord = async (variables) => {
    try {
      const result = await saveWellbeingRecord({ variables: wellbeingRecordId ? { ...variables, id: wellbeingRecordId } : variables });
      const updatedPayload = wellbeingRecordId
        ? ((result && result.data && result.data.updateWellbeingRecord) || {})
        : ((result && result.data && result.data.createWellbeingRecord) || {});
      const { createdAt } = updatedPayload;
      updatedPayload.name = createdAt;
      await setUpdatedRecord && setUpdatedRecord(updatedPayload);
      openModal(false);
    } catch (err) {
      console.log('err>>', err);
    }

  };

  // merge the data into inputFieldsArr.
  const inputFieldsArr = wellbeingRecordFieldsArr
    .map(fieldObj => {
      // If wellbeingRecordId exists, then it must be just updating, so set initial ErrorStatus for each field to fault.
      if (wellbeingRecordId) fieldObj.errorStatus = false;
      fieldObj.value = (updatedRecord && updatedRecord[fieldObj.name]) || fieldObj.initialValue;
      return fieldObj;
    });

  return (
    <div className="WellbeingRecordForm">
      <FormBox
        buttonText="Submit"
        cancelFunc={() => {
          if(wellbeingRecordId) {
            openModal(false);
          } else {
            closePayloadModal();
            openModal(false);
          }
        }}
        inputFieldsArr={inputFieldsArr}
        callbackOnSuccess={(variables) => {
          handleSaveWellbeingRecord(variables);
        }}
      />
    </div>
  );
};

export default WellbeingRecordForm;
