import gql from "graphql-tag";

export const GET_TOAST_MESSAGE = gql`
    {
        toastMessage @client {
            message
            appearance
        }
    }
`;

export const SET_MESSAGE = gql`
    mutation SetMessage($message: String!, $appearance: String!) {
        setMessage(message: $message, appearance: $appearance) @client {
            message
            appearance
        }
    }
`;

export const setMessage = (parent, { message, appearance }, { cache }, info) => {
  const toastMessage = {
    message,
    appearance,
    __typename: "toastMessage"
  };
  const data = {toastMessage};
  cache.writeData({data});
  return toastMessage;
};
