import React, { useState, useEffect } from 'react';
import {useQuery} from 'react-apollo';
import LoadingPage from '../LoadingPage';
import {ITEMS, ITEMS_COUNT, ME} from "./apollo";
import {
  Container,
  Row,
} from 'reactstrap/lib';
import './ProgressPosts.scss';
import PublicItemCards from '../../components/PublicItemCards';
import ListMenuBar from './ListMenuBar';
import Helmet from "react-helmet";

const ProgressPosts = ({ authenticated }) => {
  // const [ isListView, setToListView ] = useState(false);
  // const [ sortCol, selectSortCol ] = useState( { col: '', state: '' });
  const { data: meData, loading: meLoading } = useQuery(ME, { skip: !authenticated, suspend: false });
  const { id: myId, username } = (meData && meData.me) || {};
  const [ filterTerm, setFilterTerm ] = useState('');
  const [ perPage, setPerPage ] = useState(20);
  const [ currentPage, setCurrentPage ] = useState(1);
  const skippedPages = currentPage === 1 ? 0 : ((currentPage-1) * perPage);
  const { error: countError, data: countData, loading: countLoading } = useQuery(ITEMS_COUNT, { suspend: false, fetchPolicy: "network-only" });
  const itemQueryVariables = { query: filterTerm, first: perPage, skip: skippedPages };
  const { error, data, loading } = useQuery(ITEMS, { variables: itemQueryVariables, suspend: false, fetchPolicy: "network-only" });
  const newData = (data && data.items) ? data.items.map(item => ({
    ...item,
    fill: item.categoryColor ? item.categoryColor : 'gainsboro',
  })) : [];
  const [items, setItems] = useState([newData]);
  const { itemsCount=0 } = countData || {};
  useEffect(() => {
    if (!loading) {
      const newData = (data && data.items) ? data.items.map(item => ({
        ...item,
        fill: item.categoryColor ? item.categoryColor : 'gainsboro',
      })) : [];
      setItems([...newData]);
    }
  }, [loading, data]);

  const filteredListLength = (newData && newData.length) || 0;
  const maxPage = Math.ceil(itemsCount / perPage);
  const handlePerPageChange = (e) => {
    const perPageValue = e.target.value;
    if (perPageValue) {
      setPerPage(parseInt(perPageValue));
      selectPage(1);
    }
  };
  const selectPage = (pageNum) => {
    setCurrentPage(pageNum);
  };

  // const toggleSort = (col) => {
  //   if (col !== sortCol.col) {
  //     selectSortCol({ col, state: 'ascending' });
  //   } else {
  //     switch (sortCol.state) {
  //       case "ascending":
  //         selectSortCol({ col, state: 'descending' });
  //         break;
  //       case "descending":
  //         selectSortCol({ col: null, state: null });
  //         setItems([...data.myItems]);
  //         break;
  //       default:
  //     }
  //   }
  // };

  // const sortByCol = (a, b) => {
  //   let first = "";
  //   let second = "";
  //   let sortType = "";
  //   switch (sortCol.col) {
  //     case "category":
  //       first = a.category ? a.category.title.toLowerCase() : '';
  //       second = b.category ? b.category.title.toLowerCase() : '';
  //       sortType = "string";
  //       break;
  //     case "item":
  //       first = a.title.toLowerCase();
  //       second = b.title.toLowerCase();
  //       sortType = "string";
  //       break;
  //     case "published":
  //       first = a.published;
  //       second = b.published;
  //       sortType = "string";
  //       break;
  //     case "latestProgress":
  //       first = a.latestProgressRecord ? a.latestProgressRecord.latestProgressDate : '';
  //       second = b.latestProgressRecord ? b.latestProgressRecord.latestProgressDate : '';
  //       sortType = "string";
  //       break;
  //     case "tally":
  //       first = a.totalProgressTally;
  //       second = b.totalProgressTally;
  //       sortType = "number";
  //       break;
  //     default:
  //       return 0;
  //   }
  //   if (sortType === "number") {
  //     switch (sortCol.state) {
  //       case "ascending":
  //         return first - second;
  //       case "descending":
  //         return second - first;
  //       default:
  //         return 0;
  //     }
  //   }
  //   if (sortType === "string") {
  //     switch (sortCol.state) {
  //       case "ascending":
  //         if (first < second) return -1;
  //         if (first > second) return 1;
  //         return 0;
  //       case "descending":
  //         if (first > second) return -1;
  //         if (first < second) return 1;
  //         return 0;
  //       default:
  //         return 0;
  //     }
  //   }
  // };
  if (error || countError) return <LoadingPage error={error} />;

  return (
    <Container className="ProgressPosts h-100">
      <Helmet>
        <title>ProgressMAKR - Progress Posts</title>
        <meta
          name="keywords"
          content="share progress, progressmaker, progress maker, productivity tracker, progress tracker, bullet journal, habit tracker, habit journal, wellbeing, life balance, mood tracker"
        />
        <meta
          name="description"
          content="Online app to keep track of your progress and wellbeing. You can share your progress on this page with others.  Try Free Today and create positive habits to make a difference in your life."
        />
      </Helmet>
      <Row className="pb-2">
        <div className="col-sm-12 px-0">
          <div className="titleSection" />
          <h1
            className="p-4 text-center mt-1"
          >
            ProgressMAKR
          </h1>
          <p className="display-3 mb-4 text-center d-flex flex-wrap align-items-center justify-content-center"><span>Share your progress,</span>&nbsp;<span>support and learn with others</span></p>
          <p className="display-4 text-center mt-3 mb-0">Total: { itemsCount } posts </p>
          {
            newData && (
              <ListMenuBar
                listName="usersList"
                listLength={itemsCount}
                filteredListLength={filteredListLength}
                maxPage={maxPage}
                currentPage={currentPage}
                selectPage={selectPage}
                perPage={perPage}
                handlePerPageChange={handlePerPageChange}
                // switchingWidth={1110}
                // width={width}
                // isCardsView={isCardsView}
                // showCardsView={showCardsView}
                searchTerm={filterTerm}
                setSearchTerm={setFilterTerm}
              />
            )
          }
        </div>
      </Row>
      <Row className="mb-3">
        <PublicItemCards
          authenticated={authenticated}
          itemQueryVariables={itemQueryVariables}
          filterTerm={filterTerm}
          items={newData
              // .sort(sortByCol)
          }
          itemsLoading={loading}
        />
      </Row>
    </Container>
  )
};

export default ProgressPosts;
