import gql from 'graphql-tag';

export const DELETE_ACCOUNT = gql`
    mutation deleteUser(
        $password: String!,
    ){
        deleteUser(
            password: $password
        ) {
            status
        }
    }
`;
