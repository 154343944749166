import React from 'react';
import { Link } from 'react-router-dom';
const AlternativeLink = ({ title, to }) => {
  return to
  ? (
      <Link
        to={to}
        className="px-2"
        style={{ cursor: 'pointer' }}
      >
        {title}
      </Link>
    ) : null;
};

export default AlternativeLink;
