import React from 'react';
import Modal from '../Modal';
import {Link} from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { USERS_WHO_LIKE_THE_ITEM } from './apollo';
import FollowButton from "../PublicItemCards/FollowButton";
import AvatarImage from "../AvatarImage";

const LikedByModal = ({ authenticated, myId, postId, followingIds, likedBy=[], toggleFollow, isModalOpen, closeModal }) => {
  const { data, loading, error } = useQuery(USERS_WHO_LIKE_THE_ITEM, { variables: { id: postId }, skip: !isModalOpen, suspend: false, fetchPolicy: "network-only" });
  const likedByUsers = (data && data.usersWhoLikeTheItem) || [];
  return (
    <Modal
      isOpen={isModalOpen}
      title="Likes"
      closeModal={closeModal}
    >
      <div className="p-2">
        <p>These people like this post.</p>
        {
          likedByUsers.map(({ id, username, avatarUrl }) => {
            const followingThisUser = id && followingIds && followingIds.includes(id);
            return (
              <p key={id} className="h3 mb-3">
                <AvatarImage
                  avatarUrl={avatarUrl}
                  className="mr-2"
                  style={{ width: '3rem', height: '3rem', borderRadius: "100%", objectFit: "cover" }}
                />
                <Link style={{ cursor: 'pointer' }} to={`/profile/${username}`}>
                  <span className="pr-2">
                    { username }
                  </span>
                </Link>
                {
                  id !== myId && (
                    <FollowButton
                      id={id}
                      authorId={id}
                      followingThisUser={followingThisUser}
                      toggleFollow={toggleFollow}
                      authenticated={authenticated}
                    />
                  )
                }
              </p>
            );
          })
        }
      </div>
    </Modal>
  );
};

export default LikedByModal;
