import gql from "graphql-tag";

export const ME = gql`
    query {
        me {
            id
            username
            following {
                id
                username
                avatarUrl
            }
        }
    }
`;

export const ITEMS_COUNT = gql`
    query {
        itemsCount
    }
`;

export const ITEMS = gql`
  query items($query: String, $first: Int, $skip: Int) {
    items (query: $query, first: $first, skip: $skip) {
      id
      userId
      username
      avatarUrl
      title
      published
      likedByCount
      likedUsers  
      categoryId
      categoryTitle
      categoryColor
      description
      tags
      latestProgressDate
      latestProgressTally
      totalProgressTally
    }
  }
`;

export const LIKE = gql`
    mutation like($id: ID!, $status: Boolean) {
        like(id: $id, status: $status) {
            id
        }
    }
`;

export const FOLLOW = gql`
    mutation follow($id: ID!, $status: Boolean) {
        follow(id: $id, status: $status) {
            id
        }
    }
`;

export const UPDATE_ITEM = gql`
    mutation updateItem(
        $id: ID!,
        $title: String!,
        $published: Boolean!,
        $tags: [String],
        $categoryId: ID,
        $description: String,
        $notes: String
    ){
        updateItem(
            id: $id,
            data: {
                title: $title,
                published: $published,
                tags: $tags,
                categoryId: $categoryId
                description: $description,
                notes: $notes,
            }
        ) {
            id
            title
        }
    }
`;
