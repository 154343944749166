import React from 'react';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';

const SortingButton = ({ title, targetCol, sortCol, onClick }) => {
  const targetMatch = targetCol === sortCol.col;
  const getMatchingBtnClass = (targetMatch, targetCol, sortCol) => {
    if (
      (targetMatch && sortCol.state === "ascending")
      || (targetMatch && sortCol.state === "descending")
    ) {
      return 'btn-primary';
    }
    return 'btn-outline-primary';
  };

  return (
    <button
      className={`m-2 d-flex rounded-0 btn ${getMatchingBtnClass(targetMatch, targetCol, sortCol)} align-items-center`}
      onClick={onClick}
    >
      { title }
      {
        targetMatch && sortCol.state === 'ascending'
        && (
          <TiArrowSortedUp className="pl-1" />
        )
      }
      {
        targetMatch && sortCol.state === 'descending'
        && (
          <TiArrowSortedDown className="pl-1" />
        )
      }
    </button>
  );
};

export default SortingButton;
