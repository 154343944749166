import React from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import CategoryForm from './CategoryForm';

const Category = (props) => {
  const { categoryId, selectedCategoryName } = props;
  return (
    <Container className="pt-3">
      <Row>
        <h2 className="m-auto">{categoryId ? `Edit Category: ${selectedCategoryName}` : 'Create A New Category'}</h2>
      </Row>
      <Row>
        <CategoryForm
          categoryId={categoryId}
          {...props}
        />
      </Row>
    </Container>
  )
};

export default Category;
