import React, {useState, Fragment} from 'react';
import { GET_AUTHENTICATION_STATUS, LOG_OUT } from '../AuthProvider/apollo';
import { Link, useHistory } from 'react-router-dom';
import { Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';

import { FiMenu, FiX } from "react-icons/fi";
import { useQuery, useMutation } from 'react-apollo-hooks';
import Dropdown from './Dropdown';
import logo from '../../assets/logo.svg';
import pageLinks from './pageLinks';
import useWindowWidth from '../../utils/useWindowWidth';
import './Menubar.scss';

const isTargetLink = (path, pathname) => {
  if (pathname.includes('/profile') && path === '/progressposts') return true;
  if (pathname.includes('/item/edit') && path === '/myarea') return true;
  if(path === '/') {
    return path === pathname;
  } else {
    return pathname.includes(path);
  }
};

const Menubar = ({ client, fixed }) => {
  const switchScreenSize = 992;
  const width = useWindowWidth();
  const history = useHistory();
  const { pathname } = (history && history.location) || '';
  const [ isOpen, setStatus] = useState(false);
  const { data, loading } = useQuery(GET_AUTHENTICATION_STATUS, { suspend: false });
  const executeLogout = useMutation(LOG_OUT, { suspend: false });

  const toggle = () => {
    setStatus(!isOpen);
  };
  const logOut = async () => {
    executeLogout();
    setTimeout(() => {
      client && client.resetStore();
      history.push('/login');
    }, 100);
  };

  return (
    <div
      className="Menubar"
    >
      {
        width < switchScreenSize && (
          <div
            className="navOverlay bg-white h-100"
            style={ isOpen ? { width: "100%", right: "0" } : {} }
          >
            <NavbarToggler
              onClick={toggle}
              className="closeMenuIcon shadow py-2 bg-white"
              style={ isOpen ? { right: "0.5rem" } : {} }
            >
              <FiX
                className="text-primary"
                size={25}
              />
            </NavbarToggler>
            <div className="navOverlay-content text-center">
              <NavItem>
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() => { setStatus(false) }}
                  className={`nav-link ${isTargetLink('/', pathname) ? "active" : ""}`} to="/">Progress Posts</Link>
              </NavItem>
              <NavItem>
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() => { setStatus(false) }}
                  className={`nav-link ${isTargetLink('/howtouse', pathname) ? "active" : ""}`} to="/howtouse">How To Use</Link>
              </NavItem>
              <NavItem>
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() => { setStatus(false) }}
                  className={`nav-link ${isTargetLink('/about', pathname) ? "active" : ""}`} to="/about">About</Link>
              </NavItem>
              { data && data.authenticationStatus && data.authenticationStatus.authenticated
                ? (
                  <Fragment>
                    {
                      pageLinks.map(({ title, path }) => {
                        return (
                          <NavItem key={path}>
                            <Link
                              style={{ cursor: 'pointer' }}
                              onClick={() => { setStatus(false) }}
                              className={`nav-link ${isTargetLink(path, pathname) ? "active" : ""}`} to={path}>{ title }</Link>
                          </NavItem>
                        );
                      })
                    }
                    <NavItem className="px-3">
                      <Dropdown
                        pathname={pathname}
                        items={[{ name: 'Settings', link: '/settings' }]}
                        closeNav={() => { setStatus(false) }}
                        logOut={logOut}
                      />
                    </NavItem>
                  </Fragment>
                )
                : (
                  <Fragment>
                    <NavItem>
                      <Link style={{ cursor: 'pointer' }} className="nav-link" to="/login" onClick={() => { setStatus(false) }}>Log In</Link>
                    </NavItem>
                    <NavItem>
                      <Link style={{ cursor: 'pointer' }} className="nav-link" to="/signup" onClick={() => { setStatus(false) }}>Sign Up</Link>
                    </NavItem>
                  </Fragment>
                )
              }
            </div>
          </div>
        )
      }
      <Navbar
        className="shadow-sm py-0"
        color="white"
        light
        expand="lg"
        fixed={fixed}
      >
        <Link style={{ cursor: 'pointer' }} className="navbar-brand" to="/">
          <h4>
            <img
              className="mx-2"
              src={logo}
              alt='logo'
              style={{ height: '3.5rem' }}
            />
            ProgressMAKR
          </h4>
        </Link>
        <Nav className="ml-auto align-items-center" navbar>
          <div className="nav ml-auto align-items-center">
            {
              width < switchScreenSize ? (
                <NavbarToggler
                  onClick={toggle}
                  className="openMenuIcon shadow py-2 bg-white"
                >
                  <FiMenu
                    className="text-primary"
                    size={25}
                  />
                </NavbarToggler>
              ) : (
                <Fragment>
                  <NavItem>
                    <Link style={{ cursor: 'pointer' }} className={`nav-link ${isTargetLink('/', pathname) ? "active" : ""}`} to="/">Progress Posts</Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      style={{ cursor: 'pointer' }}
                      className={`nav-link ${isTargetLink('/howtouse', pathname) ? "active" : ""}`} to="/howtouse">How To Use</Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      style={{ cursor: 'pointer' }}
                      className={`nav-link ${isTargetLink('/about', pathname) ? "active" : ""}`} to="/about">About</Link>
                  </NavItem>
                  { data && data.authenticationStatus && data.authenticationStatus.authenticated
                    ? (
                      <Fragment>
                        {
                          pageLinks.map(({ title, path }) => {
                            return (
                              <NavItem key={path}>
                                <Link style={{ cursor: 'pointer' }} className={`nav-link ${isTargetLink(path, pathname) ? "active" : ""}`} to={path}>{ title }</Link>
                              </NavItem>
                            );
                          })
                        }
                        <NavItem className="px-3">
                          <Dropdown
                            pathname={pathname}
                            items={[{ name: 'Settings', link: '/settings' }]}
                            logOut={logOut}
                          />
                        </NavItem>
                      </Fragment>
                    )
                    : (
                      <Fragment>
                        <NavItem>
                          <Link style={{ cursor: 'pointer' }} className="nav-link" to="/login">Log In</Link>
                        </NavItem>
                        <NavItem>
                          <Link style={{ cursor: 'pointer' }} className="nav-link" to="/signup">Sign Up</Link>
                        </NavItem>
                      </Fragment>
                    )
                  }
                </Fragment>
              )
            }
          </div>
        </Nav>

      </Navbar>
    </div>
  );
};

export default Menubar;
