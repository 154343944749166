import React from 'react';
import {
  PieChart as RecharsPieChart, Pie, ResponsiveContainer, Tooltip
} from 'recharts';
import ChartTooltip from '../ChartTooltip';

const PieChart = ({ height, dataKey, data }) => {
  return (
    <ResponsiveContainer
      height={height || 400}
    >
      <RecharsPieChart>
        <Pie
          data={data}
          dataKey={dataKey}
          innerRadius="30%"
          outerRadius="80%"
          startAngle={90}
          endAngle={450}
        />
        <Tooltip content={<ChartTooltip />}/>
      </RecharsPieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;


