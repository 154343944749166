import { emailPattern } from "../../../components/form/validationPatterns";

const changeEmailFieldsArr = [
  { type: 'email', label: 'New Email', name: 'newEmail', initialValue: '', placeholder: '',
    helpMessage: 'Please enter your new email address to replace your current one.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      pattern: { value: emailPattern, errorMessage: 'Please enter a valid email address.' }
    }
  },
  { type: 'email', label: 'Confirm New Email', name: 'confirmNewEmail', initialValue: '', placeholder: '',
    helpMessage: 'Please enter your new email address one more time.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      pattern: { value: emailPattern, errorMessage: 'Please enter a valid email address.' }
    }
  },
  {
    type: 'password', label: 'Password', name: 'password', initialValue: '', placeholder: '',
    helpMessage: 'Please enter your password to confirm.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 8, errorMessage: 'Password should not be less than 8 characters long.' },
      maxLength: { value: 20, errorMessage: 'Password should not be more than 20 characters long.' },
    }
  }
];

export default changeEmailFieldsArr;
