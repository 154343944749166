import React, { useEffect, useRef } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useQuery } from "react-apollo-hooks";
import { GET_AUTHENTICATION_STATUS } from '../AuthProvider/apollo';
import { GET_TOAST_MESSAGE } from './apollo';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const ToastMessenger = () => {
  const { data: toastData  } = useQuery(GET_TOAST_MESSAGE, { suspend: false }) || {};
  const { data } = useQuery(GET_AUTHENTICATION_STATUS, { suspend: false }) || {};
  const { addToast } = useToasts();
  const { authenticated=null }  = data.authenticationStatus || {};
  const prevAuthStatus = usePrevious(authenticated);
  const { message, appearance } = toastData.toastMessage || {};
  const prevMessage = usePrevious(message);
  const prevAppearance = usePrevious(appearance);
  useEffect(() => {
    if (prevAuthStatus !== authenticated) {
      if (prevAuthStatus !==null && authenticated) {
        addToast('You are logged in.', { appearance: 'success', autoDismiss: true });
      }
      if (prevAuthStatus === true && authenticated === null) {
        addToast('You are logged out.', { appearance: 'info', autoDismiss: true, autoDismissTimeout: 8000 });
      }
    }
  }, [prevMessage, message, prevAppearance, appearance, prevAuthStatus, authenticated, addToast]);
  useEffect(() => {
    if (authenticated && message) {
      if (prevMessage !== message || prevAppearance !== appearance) {
        addToast(message, { appearance, autoDismiss: true, autoDismissTimeout: 8000 });
      }
    }
  }, [prevMessage, message, prevAppearance, appearance, addToast, prevAuthStatus, authenticated]);
  return null;
};

export default ToastMessenger;
