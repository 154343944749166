import React from 'react';
import LogInForm from "./LogInForm";

const LogInSection = (props) => {
  return (
    <div>
      <div className="mt-4 p-3">
        <h1 className="text-center">Log In</h1>
      </div>
      <div>
        <LogInForm
          {...props}
        />
      </div>
    </div>
  );
};

export default LogInSection;
