import gql from "graphql-tag";

export const MY_LIBRARY_IMAGES = gql`
    query myLibraryImages {
        myLibraryImages {
            Name
            Prefix
            KeyCount
            folderSize
            Contents {
                Key
                LastModified
                Size
            }
        }
    }
`;

export const UPLOAD_LIBRARY_IMAGE = gql`
    mutation uploadLibraryImage($file: Upload!) {
        uploadLibraryImage(file: $file) {
            status
            errors
        }
    }
`;

export const DELETE_LIBRARY_IMAGE = gql`
    mutation deleteLibraryImage($key: ID!) {
        deleteLibraryImage(key: $key) {
            status
            errors
        }
    }
`;
