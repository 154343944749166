import React from 'react';
import Form from '../../../components/form';
import ExampleFormFieldsArr from './ExampleFormFieldsArr';

const ExampleForm = () => {
  return (
    <Form
      // mutation={RESET_PASSWORD}
      buttonText="Submit"
      inputFieldsArr={ExampleFormFieldsArr}
      callbackOnSuccess={(variables) => { console.log('form submission requested with: ', variables)}}
    />
  );
};

export default ExampleForm;
