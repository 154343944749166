import React from 'react';
import FormBox from '../../components/form';
import resetPasswordFieldsArr from './resetPasswordFieldsArr';
import { RESET_PASSWORD } from './apollo';

const ResetPasswordForm = ({ resetPasswordToken, callBackOnSuccess }) => {
  const inputFieldsArr = resetPasswordFieldsArr
    .map(fieldObj => {
        fieldObj.value = fieldObj.initialValue;
      // }
      return fieldObj;
    });

  return (
    <FormBox
      mutation={RESET_PASSWORD}
      resetPasswordToken={resetPasswordToken}
      comparePasswords
      buttonText="Submit"
      alternativeLinks={[{ title: 'Back to home page', to: '/' }]}
      inputFieldsArr={inputFieldsArr}
      callbackOnSuccess={callBackOnSuccess}
    />
  );
};

export default ResetPasswordForm;
