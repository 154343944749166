const getDaysOfMonth = (year, month) => {
  const names = [ 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa' ];
  const date = new Date(year, month - 1, 1);
  const result = [];
  while (date.getMonth() === month - 1) {
    result.push({ date: ("0" + date.getDate()).slice(-2), day: names[date.getDay()]});
    date.setDate(date.getDate() + 1);
  }
  return result;
};

export default getDaysOfMonth;
