import React, {Fragment, useState} from "react";
import {useMutation, useQuery} from "react-apollo-hooks";
import {FOLLOW, ITEMS, LIKE, ME} from "../../pages/ProgressPosts/apollo";
import {USER_ITEMS, ITEMS_USER_LIKES, FOLLOWING} from "../../pages/Profile/apollo";
import LoadingPage from "../../pages/LoadingPage";
import {Link} from "react-router-dom";
import moment from "moment";
import Highlighter from "react-highlight-words";
import FollowButton from "./FollowButton";
import LikesButtons from "../../pages/ProgressPost/LikesButtons";
import LikedByModal from "../LikedByModal";
import AvatarImage from "../AvatarImage";

const PublicItemCards = ({ parentId, authenticated, itemQueryVariables, filterTerm, items, profileName='', hideFollowing, hideProfileLink, itemsLoading, refetch }) => {
  const [ likedByModalOpen, setLikedByModalOpen ] = useState(false);
  const [ selectedPostId, setSelectedPostId ] = useState(null);
  const { data: meData, loading: meLoading } = useQuery(ME, { skip: !authenticated, suspend: false });
  const refetchQueriesAfterLike = profileName ? [
    { query: USER_ITEMS, variables: { username: profileName }, suspend: false },
    { query: ITEMS_USER_LIKES, variables: { username: profileName }, suspend: false },
    ] : [{ query: ITEMS, variables: itemQueryVariables, suspend: false }];
  const toggleLike = useMutation(
    LIKE,
    {
      refetchQueries: refetchQueriesAfterLike,
      suspense: false,
    }
  );
  const toggleFollow = useMutation(
    FOLLOW,
    {
      refetchQueries: [{ query: ME }, { query: FOLLOWING, variables: { username: profileName } }],
      suspense: false
    }
  );
  if (meLoading || itemsLoading) return <LoadingPage />;
  const { id: myId, following } = (meData && meData.me) || {};
  const followingIds = (following && following.map(user => user && user.id)) || [];
  return (
    <Fragment>
      <LikedByModal
        authenticated={authenticated}
        myId={myId}
        postId={selectedPostId}
        followingIds={followingIds}
        // likedBy={likedBy}
        toggleFollow={(obj) => {
          toggleFollow(obj);
          refetch && refetch();
        }}
        isModalOpen={likedByModalOpen}
        closeModal={() => {
          setLikedByModalOpen(false);
          setSelectedPostId(null);
        }}
      />
      {
        items && items.map((item) => {
          const { id, title, userId: authorId, username, avatarUrl, categoryTitle, tags, description='', totalProgressTally=0, latestProgressTally=0, latestProgressDate, likedByCount } = item || {};
          const maxDescLength = 100;
          const truncatedDesc = description.length > maxDescLength ? `${description.substring(0, maxDescLength)}...` : description;
          const followingThisUser = authorId && followingIds.includes(authorId);
          return (
            <div
              key={id}
              className="col-xl-3 col-lmd-4 col-smd-6 mb-3"
            >
              <div
                className="card h-100 shadow border-1 rounded"
              >
                <div className="card-header d-flex justify-content-between align-items-center shadow-sm">
                  <Link style={{ cursor: 'pointer' }} to={`/progressposts/${id}`}>
                    <Highlighter
                      highlightClassName="bg-warning"
                      searchWords={[filterTerm]}
                      autoEscape={true}
                      textToHighlight={title}
                    />
                  </Link>
                  <LikesButtons
                    uniquePrefix={parentId}
                    id={id}
                    likedByCount={(likedByCount) || 0}
                    toggleLike={toggleLike}
                    authenticated={authenticated}
                    setLikedByModalOpen={() => {
                      setSelectedPostId(id);
                      setLikedByModalOpen(true);
                    }}
                  />
                </div>
                <div
                  className="card-body overflow-hidden rounded-bottom"
                >
                  <p className="d-flex align-items-center flex-wrap">
                    <AvatarImage
                      avatarUrl={avatarUrl}
                      style={{ width: '3rem', height: '3rem', borderRadius: "100%", objectFit: "cover" }}
                    />
                    {
                      hideProfileLink ? (
                        <span className="px-2">
                          <Highlighter
                            highlightClassName="bg-warning"
                            searchWords={[filterTerm]}
                            autoEscape={true}
                            textToHighlight={username}
                          />
                        </span>
                      ) : (
                        <Link style={{ cursor: 'pointer' }} to={`/profile/${username}`}>
                          <span className="px-2">
                            <Highlighter
                              highlightClassName="bg-warning"
                              searchWords={[filterTerm]}
                              autoEscape={true}
                              textToHighlight={username}
                            />
                          </span>
                        </Link>
                      )
                    }
                    {
                      !hideFollowing && authorId !== myId && (
                        <FollowButton
                          id={id}
                          authorId={authorId}
                          followingThisUser={followingThisUser}
                          toggleFollow={async (obj)=>{
                            await toggleFollow(obj);
                            refetch && refetch();
                          }}
                          authenticated={authenticated}
                        />
                      )
                    }
                  </p>
                  <p className="mb-1 d-flex">
                    Total Tally: &nbsp;
                    <span className={`d-flex align-items-center badge badge-pill px-2 ${totalProgressTally > 0 ? 'badge-primary' : 'badge-light' }`}>
                        <Highlighter
                          highlightClassName="bg-warning"
                          searchWords={[filterTerm]}
                          autoEscape={true}
                          textToHighlight={(totalProgressTally && totalProgressTally.toString()) || ''}
                        />
                      </span>
                  </p>
                  <p className="mb-1">
                    Latest Progress:
                    <br />
                    {
                      latestProgressDate
                      ? (
                        <Fragment>
                          <span>{moment(latestProgressDate).format('ddd D MMM YYYY, hh:mm a')}</span>
                        </Fragment>
                      ) : (
                        <span>No progress yet</span>
                        )
                    }
                    &nbsp;&nbsp;
                    {
                      latestProgressTally
                      && (
                        latestProgressTally > 0
                          ? <Highlighter
                            highlightClassName="bg-warning"
                            searchWords={[filterTerm]}
                            autoEscape={true}
                            textToHighlight={`(+${latestProgressTally})`}
                          />
                          : <Highlighter
                            highlightClassName="bg-warning"
                            searchWords={[filterTerm]}
                            autoEscape={true}
                            textToHighlight={latestProgressTally}
                          />
                      )
                    }
                  </p>
                  {
                    latestProgressDate && (
                      (<Highlighter
                        highlightClassName="bg-warning"
                        searchWords={[filterTerm]}
                        autoEscape={true}
                        textToHighlight={moment(latestProgressDate).format('YYYY-MM-DD')}
                      />)
                    )
                  }
                  <p className="mb-1">
                    User Category:
                    {
                      categoryTitle && (
                        <span className="px-2 m-1 rounded d-inline-block">
                          <Highlighter
                            highlightClassName="bg-warning"
                            searchWords={[filterTerm]}
                            autoEscape={true}
                            textToHighlight={categoryTitle}
                          />
                        </span>
                      )
                    }
                  </p>
                  <p className="">
                    tags:
                    &nbsp;
                    {
                      tags && (
                        <Highlighter
                          highlightClassName="bg-warning"
                          searchWords={[filterTerm]}
                          autoEscape={true}
                          textToHighlight={tags}
                        />
                      )
                    }
                  </p>
                  <p className="mb-1">
                    Description:
                    <br/>
                    { description
                      && (
                        <Highlighter
                          highlightClassName="bg-warning"
                          searchWords={[filterTerm]}
                          autoEscape={true}
                          textToHighlight={truncatedDesc}
                        />
                      )
                    }
                  </p>
                </div>
              </div>
            </div>
          );
        })
      }
    </Fragment>
  );
};

export default PublicItemCards;
