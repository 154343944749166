import { emailPattern, lowerCasePattern, domainNamePattern } from '../../../components/form/validationPatterns';

const ExampleFormFieldsArr = [
  { type: 'number', label: 'Some Number', name: 'someNumber',
    placeholder: '',
    helpMessage: 'This is a help message.',
    initialValue: '',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minValue: { value: 10, errorMessage: 'Minimum is 2 digits.' },
      maxValue: { value: 999, errorMessage: 'Maximum is 3 digits.' }
    }
  },
  { type: 'text', label: 'Domain Name', name: 'domainName', initialValue: '',
    helpMessage: 'This is a help message.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      pattern: { value: domainNamePattern, errorMessage: 'Please enter a valid domain name.' }
    }
  },
  { type: 'text', label: 'Some Text', name: 'someText', initialValue: '',
    helpMessage: 'This is a help message.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 2, errorMessage: 'It should not be less than 2 characters long.' },
      maxLength: { value: 100, errorMessage: 'It should not be more than 100 characters long.' }
    }
  },
  { type: 'textarea', label: 'Some Text Area', name: 'someTextArea', initialValue: '',
    helpMessage: 'This is a help message.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 2, errorMessage: 'It should not be less than 2 characters long.' },
      maxLength: { value: 800, errorMessage: 'It should not be more than 800 characters long.' }
    }
  },
  { type: 'select', label: 'Some List', name: 'someList', initialValue: [],
    helpMessage: 'This is a help message.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
    },
    optionList: [{ id: '1234', label: 'Choice 1' }, { id: '23456', label: 'Choice 2'}, { id: '27890', label: 'Choice 3'}]
  },
  { type: 'text', label: 'Username', name: 'username', initialValue: '',
    helpMessage: 'This is a help message.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 6, errorMessage: 'Username should not be less than 6 characters long.' },
      maxLength: { value: 20, errorMessage: 'Username should not be more than 20 characters long.' },
      pattern: { value: lowerCasePattern, errorMessage: 'Please use lower case characters without spaces for username.'} // lower case only
    }
  },
  { type: 'email', label: 'Email', name: 'email', initialValue: '', placeholder: '',
    helpMessage: 'This is a help message.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      pattern: { value: emailPattern, errorMessage: 'Please enter a valid email address.' }
    }
  },
  {
    type: 'password', label: 'Password', name: 'password', initialValue: '', placeholder: '',
    helpMessage: 'This is a help message.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 8, errorMessage: 'Password should not be less than 8 characters long.' },
      maxLength: { value: 20, errorMessage: 'Password should not be more than 20 characters long.' },
    }
  },
  { type: 'checkbox', label: 'Some Checkbox 1', name: 'someCheckbox1', initialValue: false,
    helpMessage: 'This is a help message.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
    }
  },
  { type: 'checkbox', label: 'Some Checkbox 2', name: 'someCheckbox2', initialValue: false,
    helpMessage: 'This is a help message.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
    }
  },
  { type: 'checkbox', label: 'Some Checkbox 3', name: 'someCheckbox3', initialValue: false,
    helpMessage: 'This is a help message.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
    }
  },
  {
    type: 'group', label: 'Group 1', name: 'group1',
    groupOuterClassName: 'mb-2',
    groupInnerClassName: 'p-2 bg-light d-flex justify-content-around',
    groupFields: [
      { type: 'checkbox', label: 'Group Checkbox 1', name: 'groupCheckbox1', initialValue: false,
        helpMessage: 'This is a help message.',
        validationOptions: {
          required: { value: true, errorMessage: 'This field is required.' },
        }
      },
      { type: 'checkbox', label: 'Group Checkbox 2', name: 'groupCheckbox2', initialValue: false,
        helpMessage: 'This is a help message.',
        validationOptions: {
          required: { value: true, errorMessage: 'This field is required.' },
        }
      },
      { type: 'checkbox', label: 'Group Checkbox 3', name: 'groupCheckbox3', initialValue: false,
        helpMessage: 'This is a help message.',
        validationOptions: {
          required: { value: true, errorMessage: 'This field is required.' },
        }
      }
    ]
  },
  { type: 'datetime', label: 'Some Date Time', name: 'someDateTime', initialValue: new Date(),
    helpMessage: 'This is a help message.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
    }
  },
  { type: 'tags', label: 'Tags', name: 'tags', initialValue: [], helpMessage: 'This is a help message.',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
    }
  },
];

export default ExampleFormFieldsArr;
