import React from 'react';

const ListForm = ({ listLength, listName, filteredListLength, maxPage, currentPage, selectPage, perPage, handlePerPageChange, SearchComponent }) => {
  if (!listLength) return null;
  return (
    <div
      className="d-flex align-items-center justify-content-center flex-wrap"
    >
      <p className="mb-0 py-1 pr-sm-3 text-nowrap">Displayed Posts: <span>{ filteredListLength } items</span></p>
      <div className="py-1">
        <select
          value={perPage}
          onChange={handlePerPageChange}
          className="rounded-0"
          id={`itemsPerPage-${listName}`}
          name={`itemsPerPage-${listName}`}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
        &nbsp;
        <label
          htmlFor={`itemsPerPage-${listName}`}
          className="mb-0"
        >
          items per page
        </label>
      </div>
      <div className="mx-4 py-1">
        <button
          className="btn btn-sm btn-sm"
          style={{ minWidth: '10px'}}
          onClick={() => selectPage(1)}
          disabled={currentPage <= 1}
        >
          {
            maxPage < 1 ? '0' : '1'
          }
        </button>
        {
          currentPage > 1
          && (
            <button
              className="btn btn-link btn-sm px-0"
              onClick={() => {
                if(currentPage>1) selectPage(currentPage-1);
              }}
            >
              Previous
            </button>
          )
        }
        &nbsp;
        <input
          name={`selectedPageNum-${listName}`}
          style={{ width: '3rem' }}
          type="number"
          value={maxPage <1 ? maxPage : currentPage}
          onChange={(e) => {
            const selectedPage = e.target.value;
            if (!selectedPage) selectPage(1);
            const parsedPage = parseInt(selectedPage);
            if (parsedPage > 0 && parsedPage <= maxPage) selectPage(parsedPage);
          }}
          onBlur={() => {
            if (!currentPage) selectPage(1);
          }}
        />
        &nbsp;
        <span style={{width: '7rem'}}>
              out of { maxPage } pages
            </span>
        &nbsp;
        {
          currentPage < maxPage && (
            <button
              className="btn btn-link btn-sm px-0"
              onClick={() => {
                if (currentPage < maxPage) selectPage(currentPage+1);
              }}
            >
              Next
            </button>
          )
        }
        <button
          className="btn btn-sm btn-sm"
          style={{ minWidth: '10px'}}
          onClick={() => selectPage(maxPage)}
          disabled={currentPage >= maxPage}
        >
          { maxPage }
        </button>
      </div>
    </div>
  )
};

export default ListForm;
