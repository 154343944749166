import React from 'react';
import FormBox from '../../../components/form';
import { CHANGE_EMAIL } from './apollo';
import changeEmailFieldsArr from './ChangeEmailFieldsArr';
import {MY_PROFILE} from "../apollo";

const ChangeEmailForm = () => {
  return (
    <div>
      <p>Please confirm below to change your email.</p>
      <FormBox
        mutation={CHANGE_EMAIL}
        refetchQueries={[{ query: MY_PROFILE }]}
        buttonText="Confirm"
        inputFieldsArr={changeEmailFieldsArr}
        compareEmails
        successMessage="Your email has been changed successfully."
      />
    </div>
  );
};

export default ChangeEmailForm;

