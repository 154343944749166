import React from 'react';
import ConfirmationModal from '../ConfirmationModal';
import deleteFieldsArr from './deleteFieldsArr';

const DeleteConfirmationModal = ({ buttonColor, buttonText, message, selectedItem, isModalOpen, openModal, callbackOnConfirm }) => {
  return (
    <ConfirmationModal
      buttonColor={buttonColor}
      buttonText={buttonText}
      message={message}
      selectedItem={selectedItem}
      isModalOpen={isModalOpen}
      openModal={openModal}
      inputFieldsArr={deleteFieldsArr}
      callbackOnConfirm={callbackOnConfirm}
      hideModalFooter
    />
  )
};

export default DeleteConfirmationModal;
