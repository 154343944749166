import React, { useState, useRef } from 'react';
import { FiCamera } from "react-icons/fi";
import { useQuery, useMutation } from 'react-apollo-hooks';
import './ImageLibrary.scss';
import { FiTrash2, FiCheckSquare, FiRotateCw } from "react-icons/fi";
import { MY_LIBRARY_IMAGES, UPLOAD_LIBRARY_IMAGE, DELETE_LIBRARY_IMAGE } from './apollo';
import LoadingPage from "../../../pages/LoadingPage";
import Button from 'reactstrap/lib/Button';
import Resizer from 'react-image-file-resizer';
import Modal from "../../Modal";
import CameraCapture from "../../CameraCapture";

const ImageSelector = ({ isOpen, insertImage, insertVideo }) => {
  const inputRef = useRef(null);
  const [ selectedFile, setSelectedFile ] = useState(null);
  const [ uploadPreviewImage, setUploadPreviewImage ] = useState(null);
  const [ cameraOpen, setCameraOpen ] = useState(false);
  const [ rotation, setRotation ] = useState(0);
  const [ loading, setLoading ] = useState(false);
  const [ messages, setMessages ] = useState({ message: '', isError: false });

  const {error, data, loading: imagesLoading } = useQuery(MY_LIBRARY_IMAGES, { skip: !isOpen, suspend: false });
  const  { Name, Prefix, KeyCount, Contents } = (data && data.myLibraryImages) || {};
  const uploadFile = useMutation(
    UPLOAD_LIBRARY_IMAGE,
    {
      refetchQueries: [{ query: MY_LIBRARY_IMAGES, fetchPolicy: 'network-only' }],
      suspense: false
    }
  );
  const deleteImage = useMutation(
    DELETE_LIBRARY_IMAGE,
    {
      refetchQueries: [{ query: MY_LIBRARY_IMAGES, fetchPolicy: 'network-only' }],
      suspense: false
    }
  );
  if (imagesLoading) return <LoadingPage />;

  const onHandleSubmit = async (file) => {
    if (file || selectedFile) {
      setLoading(true);
      try {
        const result = await uploadFile({ variables: { file: file || selectedFile } });
        const { status, errors } = (result && result.data && result.data.uploadLibraryImage) || {};
        setLoading(false);
        if (status) {
          inputRef.current.value = '';
          setSelectedFile(null);
          setUploadPreviewImage(null);
          setRotation(0);
          setMessages({ message: 'Media successfully uploaded!', isError: false });
        } else {
          setMessages({ message: errors ? errors.join(', '): "Something went wrong for upload", isError: true });
        }
      } catch (err) {
        setLoading(false);
        setMessages({ message: 'Failed to upload. Something went wrong.  Please check and try again.', isError: true });
      }
    }
  };

  const setCameraFile = async (file) => {
    await setCameraOpen(false);
    await onHandleSubmit(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setRotation(0);
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      const maxFileSize = 1000000; // 1MB
      const maxFileSizeInMB = maxFileSize/1000000;
      if (file.size <= maxFileSize) {
        setSelectedFile(file);
        setUploadPreviewImage(URL.createObjectURL(file));
      } else {
        const compressFormat = file.type.split('/')[1].toUpperCase();
        Resizer.imageFileResizer(
          file,
          900, //maxWidth (ratio is preserved)
          900, //maxHeight (ratio is preserved)
          compressFormat, //compressFormat	Can be either JPEG, PNG or WEBP.
          100, //quality
          0, //rotation
          blob => {
            const resizedFile = new File([blob], file.name, { type: file.type });
            setSelectedFile(resizedFile);
            setUploadPreviewImage(URL.createObjectURL(resizedFile));
          },
          'blob'
        );
      }
    } else {
      setSelectedFile(null);
      setUploadPreviewImage(null);
    }
    // if (file && file.size > maxFileSize) {
    //   const currentFileSize = (file.size/1000000).toFixed(2);
    //   setMessages({ message: `The selected file (${currentFileSize}MB) exceeds the max file size of ${maxFileSizeInMB}MB.  Please reduce its file size and try to upload it again.`, isError: true });
    // } else {
    //   setMessages({ message: '', isError: false });
    // }
  };

  const rotateImage = () => {
    const newRotation = (rotation + 90) === 360 ? 0 : rotation + 90;
    const compressFormat = selectedFile.type.split('/')[1].toUpperCase();
    Resizer.imageFileResizer(
      selectedFile,
      900, //maxWidth (ratio is preserved)
      900, //maxHeight (ratio is preserved)
      compressFormat, //compressFormat	Can be either JPEG, PNG or WEBP.
      100, //quality
      newRotation, //rotation
      blob => {
        // setRotation(newRotation);
        const resizedFile = new File([blob], selectedFile.name, { type: selectedFile.type });
        setSelectedFile(resizedFile);
        setUploadPreviewImage(URL.createObjectURL(resizedFile));
      },
      'blob'
    );
  };

  const handleDelete = async (objKey) => {
    setLoading(true);
    try {
      const result = await deleteImage({ variables: { key: objKey } });
      const { status, errors } = (result && result.data && result.data.deleteLibraryImage) || {};
      setLoading(false);
      if (status) {
        setMessages({ message: 'Image successfully deleted!', isError: false });
      } else {
        setMessages({ message: errors ? errors.join(', '): "Failed to delete. Something went wrong.  Please check and try again.", isError: true });
      }
    } catch (err) {
      setLoading(false);
      setMessages({ message: 'Failed to delete. Something went wrong.  Please check and try again.', isError: true });
      console.log(err);
    }
  };

  const handleSelectedMedia = (mediaSrc, mediaType) => {
    // if image file, then insertImage. if video file, then insertVideo.
    if (mediaType === 'image') {
      insertImage(mediaSrc);
    }
    if (mediaType === 'video') {
      insertVideo(mediaSrc);
    }
  };

  return (
    <div className="ImageSelector">
      <Modal
        title="Camera Capture"
        size="xl"
        className="rounded-0"
        isOpen={cameraOpen}
        closeModal={() => setCameraOpen(false)}
        centered
      >
        <CameraCapture setCameraFile={setCameraFile} />
      </Modal>
      <div className="alert alert-info rounded-0">
        <p className="mb-0">You can create your own media library by uploading your images from files on your device.</p>
        <p className="mb-0">Also, you can directly take a photo or shoot a short video using a camera on your device.</p>
        <p className="mb-0">
          From your image library below, just select an image
          <FiCheckSquare
            className="mx-1"
            color="blue"
            role="button"
          />
          to be inserted into the editor.
        </p>
      </div>
      {/*<div className="m-3 p-3 shadow border border-secondary">*/}
      {/*  <h3>Image Url</h3>*/}
      {/*  <div className="content m-auto p-3">*/}
      {/*    {*/}
      {/*      imagePreviewUrl && (*/}
      {/*        <div>*/}
      {/*          <p>Preview</p>*/}
      {/*          <img src={imagePreviewUrl} alt={imagePreviewUrl} />*/}
      {/*        </div>*/}
      {/*      )*/}
      {/*    }*/}
      {/*  </div>*/}
      {/*  <div className="input-group my-3">*/}
      {/*    <input*/}
      {/*      type="text"*/}
      {/*      className="form-control"*/}
      {/*      placeholder="Embed URL"*/}
      {/*      aria-label="image url"*/}
      {/*      aria-describedby="imageUrl"*/}
      {/*      value={imagePreviewUrl}*/}
      {/*      onChange={(e) => setImagePreviewUrl(e.target.value)}*/}
      {/*    />*/}
      {/*      <div className="input-group-append">*/}
      {/*        <FiCheckSquare*/}
      {/*          className="mx-1"*/}
      {/*          color="blue"*/}
      {/*          role="button"*/}
      {/*          style={{ cursor: "pointer"}}*/}
      {/*          onClick={() => handleSelectedMedia(imagePreviewUrl, 'image')}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="ImageLibrary my-3 p-3 shadow border border-secondary">
        <div className="content m-auto py-2">
          <div className="border border-info my-2 p-2">
            <h3>Use Camera</h3>
            <p className="mb-0">
              Please allow your camera to be used.  Not all browsers can use this functionality.
            </p>
            <FiCamera
              role="button"
              size={30}
              style={{ cursor: "pointer" }}
              className="m-3"
              onClick={() => setCameraOpen(true)}
            />
          </div>
          <div className="border border-info my-2 p-2">
            <h3>Use Files</h3>
            <p className="mb-0">
              You can also upload your image files from your device if you prefer.
            </p>
            <div className={uploadPreviewImage ? "p-3": ""}>
              {
                uploadPreviewImage && (
                  <div>
                    <p>
                      Preview
                    </p>
                    <div
                      className="m-2"
                    >
                      Rotate image
                      &nbsp;
                      <FiRotateCw
                        color="blue"
                        role="button"
                        onClick={rotateImage}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                    <div>
                      <img src={uploadPreviewImage} alt={uploadPreviewImage} />
                    </div>
                  </div>
                )
              }
            </div>
            <form>
              <div className="form-group mb-0 py-2">
                <input
                  ref={inputRef}
                  name="uploadFile"
                  type="file"
                  accept="image/gif, image/jpeg, image/png"
                  onChange={handleFileChange}
                  className="overflow-auto"
                />
                <Button
                  className="my-2"
                  color="success"
                  type="button"
                  disabled={!selectedFile}
                  onClick={() => onHandleSubmit()}
                >
                  Upload The New Image
                </Button>
              </div>
              {
                messages.message && (
                  <div className={`alert ${ messages.isError ? 'alert-danger' : 'alert-success' } m-3`}>{messages.message}</div>
                )
              }
            </form>
          </div>
        </div>
        <h2>Media Library</h2>
        <div className="gridContainer">
          {
            Contents && Contents.map(imageObj => {
              const objKey = imageObj.Key;
              const title = objKey.replace(`${Prefix}/`, '');
              const mediaSrc = `https://${Name}.s3.eu-west-2.amazonaws.com/${objKey}`;
              const mediaType = (objKey.endsWith('.webm') || objKey.endsWith('.mp4')) ? 'video' : 'image';
              return (
                <div key={objKey+imageObj.LastModified} className="gridItem card m-2 shadow">
                  <div>
                    {
                      (objKey.endsWith('.webm') || objKey.endsWith('.mp4')) ? (
                        <video
                          className="card-img-top"
                          src={mediaSrc}
                          alt={objKey}
                          controls
                          playsInline
                        />
                      ): (
                        <img className="card-img-top" src={mediaSrc} alt={objKey} />
                      )
                    }
                  </div>
                  <div className="card-body bg-light">
                    <p className="card-title">{title}</p>
                    <div className="card-text d-flex align-items-center">
                      <div
                        role="button"
                        style={{ cursor: "pointer"}}
                        onClick={() => handleDelete(objKey)}
                      >
                        <span>Delete</span>
                        <FiTrash2
                          className="mx-1 mb-1"
                          color="red"
                        />
                      </div>
                      &nbsp;
                      <div
                        role="button"
                        style={{ cursor: "pointer"}}
                        onClick={() => handleSelectedMedia(mediaSrc, mediaType)}
                      >
                        <span>Insert this media</span>
                        <FiCheckSquare
                          className="mx-1 mb-1"
                          color="blue"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default ImageSelector;
