import React, { useState, useEffect } from 'react';
import {selectErrorMessage} from "./errorHandlingFuncs";
import { FiPlusSquare } from 'react-icons/fi';

const TagInput = ({ label, name, initialTags, validate, validationOptions, className, tagClassName, placeholder, helpMessage, TagDeleteIcon }) => {
  const initialTagsArr = (initialTags && initialTags.split(', ')) || [];
  const [ tags, setTags ] = useState(initialTagsArr);
  const [touched, setTouched] = useState(false);
  const [ inputValue, setInputValue ] = useState('');
  const [error, setError] = useState("");

  useEffect(() => {
    if (touched) {
      setError(selectErrorMessage(validationOptions, tags));
    } else {
      setError("");
    }
  }, [touched, tags, validationOptions]);

  useEffect(() => {
    if (validate) {
      setError(selectErrorMessage(validationOptions, tags));
    }
  }, [validate, tags, validationOptions]);

  const handleDelete = i => {
    const newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
  };

  const handleOnKeyDown = e => {
    if(e.keyCode === 13) {
      e.preventDefault();
      const enteredValue = e.target.value.trim();
      const isDuplicate = tags.some(tag => tag === enteredValue);
      if (enteredValue && !isDuplicate) {
        e.preventDefault();
        const newTags = [ ...tags, enteredValue ];
        setTags(newTags);
        setInputValue('');
      }
    }
  };

  const addToTags = (e) => {
    e.preventDefault();
    const enteredValue = inputValue.trim();
    const isDuplicate = tags.some(tag => tag === enteredValue);
    if (enteredValue && !isDuplicate) {
      const newTags = [ ...tags, enteredValue ];
      setTags(newTags);
      setInputValue('');
    }
  };

  return (
    <div className={`form-group ${className}`}>
      {
        label
        && (
          <label>
            {label}
          </label>
        )
      }
      <div
        className="mb-2"
      >
        {
          tags && tags.map((tag, i) => {
            return (
              <span
                key={i}
                className={tagClassName}
              >
                {tag}
                &nbsp;
                <span onClick={() => handleDelete(i)}>
                  { TagDeleteIcon ? <TagDeleteIcon /> : '(x)' }
                </span>
              </span>
            );
          })
        }
      </div>
      <input
        id={name}
        name={name}
        value={tags && tags.join(', ')}
        readOnly
        style={{ display: 'none' }}
      />
      <div className="d-flex align-items-center">
        <input
          type="text"
          className="form-control"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleOnKeyDown}
          placeholder={placeholder}
          onBlur={() => setTouched(true)}
        />
        &nbsp;
        <div
          role="button"
          style={{ cursor: "pointer" }}
          onClick={addToTags}
          className="d-flex align-items-center"
        >
          <span>Add</span>
          &nbsp;
          <FiPlusSquare
            size={20}
            className="text-success"
          />
        </div>
      </div>
      {
        helpMessage
        && (
          <small className="text-muted">{ helpMessage }</small>
        )
      }
      {error && <p className="text-danger">{error}</p>}
    </div>
  );
};

export default TagInput;
