import React, {useState, useEffect} from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from 'reactstrap/lib/Button';
import { FiEdit, FiTrash } from 'react-icons/fi';
import WellbeingRecordForm from "./WellbeingRecordForm";
import Moment from 'react-moment';
import wellbeingColors from '../../pages/MyArea/WellbeingSection/wellbeingColors';

const WellbeingPayloadModal = ({ openDeleteWBRecordModal, setWellbeingRecords, selectedPayload, wellbeingDateRange, isModalOpen, closeModal }) => {
  const [ updatedRecord, setUpdatedRecord ] = useState(selectedPayload);
  const [ isWREditMode, setIsWREditMode ] = useState(false);
  useEffect(() => {
    if (selectedPayload) {
      setUpdatedRecord(selectedPayload);
      if (Object.keys(selectedPayload).length === 0) {
        setIsWREditMode(true);
      }
    }
  }, [selectedPayload]);

  useEffect(() => {
    setWellbeingRecords((prevRecords) => {
      if (!prevRecords || prevRecords.length === 0) {
        return (updatedRecord && Object.keys(updatedRecord).length > 0) ? [ updatedRecord ] : [];
      } else {
        const recordsWithoutDuplicate = prevRecords.filter((record) => record && (record.id !== (updatedRecord && updatedRecord.id)));
        return (updatedRecord && Object.keys(updatedRecord).length > 0) ? [ updatedRecord, ...recordsWithoutDuplicate ] : recordsWithoutDuplicate;
      }
    });
  }, [updatedRecord, setWellbeingRecords]);

  const { name, overallWellbeing, physicalWellbeing, emotionalWellbeing, energyLevel, tags, notes, createdAt, id } = updatedRecord || {};

  return (
    <Modal
      size="xl"
      isOpen={isModalOpen}
      toggle={() => {
        setIsWREditMode(false);
        closeModal();
      }}
    >
      <ModalHeader
        toggle={() => {
          setIsWREditMode(false);
          closeModal();
        }}
      >
        {
          name ? <span>Your Wellbeing Record - <Moment format="dddd D MMM YYYY, hh:mm a">{name}</Moment></span> : "Record Your Wellbeing"
        }
        {
          name && (
            <div className="d-flex flex-wrap align-items-center">
              <FiEdit
                size={20}
                className="text-primary"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsWREditMode((prevState)=> {
                    return !prevState;
                  });
                }}
              />
              &nbsp;
              <FiTrash
                size={20}
                className="text-danger"
                style={{ cursor: 'pointer' }}
                onClick={() => openDeleteWBRecordModal(true, { id, title: name })}
              />
            </div>
          )
        }
      </ModalHeader>
      <ModalBody className="">
        {
          !isWREditMode ? (
            <div>
              <p className="mb-0" style={{ color: wellbeingColors['overallWellbeing']}}>Overall Wellbeing: {overallWellbeing}</p>
              <p className="mb-0" style={{ color: wellbeingColors['physicalWellbeing']}}>Physical Wellbeing: {physicalWellbeing}</p>
              <p className="mb-0" style={{ color: wellbeingColors['emotionalWellbeing']}}>Emotional Wellbeing: {emotionalWellbeing}</p>
              <p className="mb-0" style={{ color: wellbeingColors['energyLevel']}}>Energy Level: {energyLevel}</p>
              <p className="mb-0">Tags: {tags}</p>
              <div>
                <p className="mb-0">Notes:</p>
                <div className="ql-editor p-1" dangerouslySetInnerHTML={{__html: notes }} />
              </div>
            </div>
          ) : (
            <WellbeingRecordForm
              setUpdatedRecord={setUpdatedRecord}
              wellbeingRecordId={id}
              updatedRecord={updatedRecord}
              openModal={setIsWREditMode}
              closePayloadModal={() => {
                setIsWREditMode(false);
                closeModal();
              }}
              wellbeingDateRange={wellbeingDateRange}
            />
          )
        }
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          className="rounded-0"
          outline
          onClick={() => {
            setIsWREditMode(false);
            closeModal();
          }}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WellbeingPayloadModal;
