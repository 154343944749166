import React from 'react';
import Highlighter from 'react-highlight-words';
import PublishedCheckBox from '../PublishedCheckBox';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FiEdit, FiTrash, FiPlusSquare } from 'react-icons/fi';
import invert from 'invert-color';

const ListBody = ({
  items, openModal, openProgressRecordModal, showChart, filterTerm
}) => {
  return (
    <tbody
      className="listBody"
      style={{ height: showChart ? '' : '40rem' }}
    >
    {
      items && items
        .map(({
                categoryId, categoryColor, categoryTitle, id, title, latestProgressDate, latestProgressTally, totalProgressTally=0, published, tags
              }) => (
          <tr
            key={id}
          >
            <td className="d-flex justify-content-center align-items-center border-right" width="20%">
              <Link style={{ cursor: 'pointer' }} to={`/progressposts/${id}`}>
                <Highlighter
                  highlightClassName="bg-warning"
                  searchWords={[filterTerm]}
                  autoEscape={true}
                  textToHighlight={title}
                />
              </Link>
            </td>
            <td className="d-flex justify-content-center align-items-center border-right" width="10%">
              <span className={`d-flex align-items-center badge badge-pill ${totalProgressTally > 0 ? 'badge-primary' : 'badge-light' }`}>
                <Highlighter
                  highlightClassName="bg-warning"
                  searchWords={[filterTerm]}
                  autoEscape={true}
                  textToHighlight={totalProgressTally ? totalProgressTally.toString() : ''}
                />
              </span>
            </td>
            <td className="d-flex justify-content-center align-items-center" width="16%">
              {
                latestProgressDate
                && (
                  <Highlighter
                    highlightClassName="bg-warning"
                    searchWords={[filterTerm]}
                    autoEscape={true}
                    textToHighlight={moment(latestProgressDate).format('ddd D MMM YYYY, hh:mm a')}
                  />
                )
              }
            </td>
            <td className="d-flex justify-content-center align-items-center" width="3%">
              {
                latestProgressTally
                && (
                  latestProgressTally > 0
                    ? <Highlighter
                      highlightClassName="bg-warning"
                      searchWords={[filterTerm]}
                      autoEscape={true}
                      textToHighlight={`(+${latestProgressTally})`}
                    />
                    : <Highlighter
                      highlightClassName="bg-warning"
                      searchWords={[filterTerm]}
                      autoEscape={true}
                      textToHighlight={latestProgressTally}
                    />
                )
              }
            </td>
            <td
              className="d-flex justify-content-center align-items-center px-0 border-right"
              width="3%"
            >
              <FiPlusSquare
                size={20}
                className="text-success"
                style={{ cursor: 'pointer' }}
                onClick={() => openProgressRecordModal(true, { id, title })}
              />
            </td>
            <td
              className="d-flex justify-content-center align-items-center border-right" width="20%">
              {
                categoryTitle && (
                  <span
                    className="px-2 my-1 rounded d-inline-block"
                    style={{
                      color: categoryColor ? invert(categoryColor, true) : '',
                      backgroundColor: (categoryColor) || ''
                    }}
                  >
                    <Highlighter
                      highlightClassName="bg-warning"
                      searchWords={[filterTerm]}
                      autoEscape={true}
                      textToHighlight={categoryTitle}
                    />
                  </span>
                )
              }
            </td>
            <td className="d-flex justify-content-center align-items-center border-right" width="12%">
              {
                tags && (
                  <Highlighter
                    highlightClassName="bg-warning"
                    searchWords={[filterTerm]}
                    autoEscape={true}
                    textToHighlight={tags}
                  />
                )
              }
            </td>
            <td className="d-flex justify-content-center align-items-center border-right" width="8%">
              <PublishedCheckBox
                id={id}
                value={published}
              />
            </td>
            <td className="d-flex justify-content-center align-items-center" width="8%">
              <Link style={{ cursor: 'pointer' }} to={`/item/edit/${id}`}>
                <FiEdit
                  size={20}
                  className="text-primary"
                  style={{ cursor: 'pointer' }}
                />
              </Link>
              &nbsp;
              <FiTrash
                size={20}
                className="text-danger"
                style={{ cursor: 'pointer' }}
                onClick={() => openModal(true, { id, title })}
              />
              &nbsp;
            </td>
          </tr>
        ))
    }
    </tbody>
  );
};

export default ListBody;
