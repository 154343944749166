import React, { Component } from 'react';
import './editor.scss';
import Modal from '../Modal';
import ImageSelector from './ImageSelector';
import ReactQuill, { Quill } from 'react-quill';
import BlotFormatter, { ResizeAction, AlignAction, DeleteAction, ImageSpec, IframeVideoSpec } from 'quill-blot-formatter';

import DirectVideoFrameSpec from './DirectVideoFrameSpec';

class CustomResizeAction extends ResizeAction {
  createHandle(position, cursor) {
    const box = document.createElement('div');
    box.classList.add(this.formatter.options.resize.handleClassName);
    box.setAttribute('data-position', position);
    box.style.cursor = cursor;

    if (this.formatter.options.resize.handleStyle) {
      Object.assign(box.style, this.formatter.options.resize.handleStyle);
    }
    box.addEventListener('mousedown', this.onMouseDown);
    box.addEventListener('touchstart', (event) => this.onTouchStart(event, box));
    return box;
  }

  onTouchStart(event, box) {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }
    this.dragHandle = event.target;
    this.setCursor(this.dragHandle.style.cursor);

    if (!this.formatter.currentSpec) {
      return;
    }

    const target = this.formatter.currentSpec.getTargetElement();
    if (!target) {
      return;
    }

    const rect = target.getBoundingClientRect();

    this.dragStartX = event.touches[0].clientX;
    this.preDragWidth = rect.width;
    this.targetRatio = rect.height / rect.width;

    box.addEventListener('touchmove', (event) => this.onTouchMove(event));
    box.addEventListener('touchend', (event)=> this.onTouchEnd(event, box));
  };

  onTouchMove(event) {
    const e = event;
    if (!this.formatter.currentSpec) {
      return;
    }

    const target = this.formatter.currentSpec.getTargetElement();
    if (!target) {
      return;
    }

    const deltaX = e.touches[0].clientX - this.dragStartX;
    let newWidth = 0;

    if (this.dragHandle === this.topLeftHandle || this.dragHandle === this.bottomLeftHandle) {
      newWidth = Math.round(this.preDragWidth - deltaX);
    } else {
      newWidth = Math.round(this.preDragWidth + deltaX);
    }

    const newHeight = this.targetRatio * newWidth;

    target.setAttribute('width', `${newWidth}`);
    target.setAttribute('height', `${newHeight}`);

    this.formatter.update();
  };

  onTouchEnd(event, box) {
    box.removeEventListener('touchmove', this.onTouchMove);
    box.removeEventListener('touchend', this.onTouchEnd);
  };
}

class CustomImageSpec extends ImageSpec {
  getActions() {
    return [AlignAction, CustomResizeAction, DeleteAction];
  }
}

class CustomDirectVideoSpec extends DirectVideoFrameSpec {
  getActions() {
    return [AlignAction, CustomResizeAction, DeleteAction];
  }
}

class CustomIframeVideoSpec extends IframeVideoSpec {
  getActions() {
    return [AlignAction, CustomResizeAction, DeleteAction];
  }
}

const Font = Quill.import('formats/font');
const BaseImage = Quill.import('formats/image');
const BaseVideo = Quill.import('formats/video');
const BlockEmbed = Quill.import('blots/block/embed');

//We do not add Sans Serif since it is the default
Font.whitelist = ['Oxygen', 'OpenSans', 'roboto', 'Lato', 'Baskervville', 'Courgette'];

class Image extends BaseImage {
  static formats(domNode) {
    return ATTRIBUTES.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        if (name === 'style') {
          value = this.sanitize_style(value);
        }
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }

  sanitize_style(style) {
    let style_arr = style.split(";");
    let allow_style = "";
    style_arr.forEach((v, i) => {
      if (WHITE_STYLE.indexOf(v.trim().split(":")[0]) !== -1) {
        allow_style += v + ";"
      }
    })
    return allow_style;
  }
}

class Video extends BaseVideo {
  static formats(domNode) {
    return VIDEO_ATTRIBUTES.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  format(name, value) {
    if (VIDEO_ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        if (name === 'style') {
          value = this.sanitize_style(value);
        }
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }

  sanitize_style(style) {
    let style_arr = style.split(";");
    let allow_style = "";
    style_arr.forEach((v, i) => {
      if (WHITE_STYLE.indexOf(v.trim().split(":")[0]) !== -1) {
        allow_style += v + ";"
      }
    })
    return allow_style;
  }
}

class DirectVideoBlot extends BlockEmbed {
  static create(url) {
    let node = super.create();
    node.setAttribute('src', url);
    node.setAttribute('alt', 'video');
    node.setAttribute('controls', true);
    node.setAttribute('playsInline', true);
    return node;
  }

  static formats(domNode) {
    return VIDEO_ATTRIBUTES.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static value(node) {
    return node.getAttribute('src');
  }

  format(name, value) {
    if (VIDEO_ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        if (name === 'style') {
          value = this.sanitize_style(value);
        }
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }

  sanitize_style(style) {
    let style_arr = style.split(";");
    let allow_style = "";
    style_arr.forEach((v, i) => {
      if (WHITE_STYLE.indexOf(v.trim().split(":")[0]) !== -1) {
        allow_style += v + ";"
      }
    })
    return allow_style;
  }
}
DirectVideoBlot.blotName = 'directVideo';
DirectVideoBlot.tagName = 'video';
DirectVideoBlot.className = "ql-video";

// BEGIN allow image alignment styles
const ATTRIBUTES = [
  'alt',
  'height',
  'width',
  'style'
];
const VIDEO_ATTRIBUTES = [
  'alt',
  'height',
  'width',
  'style'
];

const WHITE_STYLE = ['margin', 'display', 'float'];
Quill.register(Image, true);
Quill.register(Video, true);
Quill.register(DirectVideoBlot);

Quill.register(Font, true);
Quill.register('modules/blotFormatter', BlotFormatter);

export default class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedRange: null
    };
    this.insertImageToEditor = this.insertImageToEditor.bind(this);
    this.insertVideoToEditor = this.insertVideoToEditor.bind(this);
    this.imageHandler = this.imageHandler.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],       // toggled buttons
      ['blockquote', 'code-block'],                    // blocks
      [{ 'header': 1 }, { 'header': 2 }],              // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],    // lists
      [{ 'script': 'sub'}, { 'script': 'super' }],     // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],         // outdent/indent
      [{ 'direction': 'rtl' }],                        // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, false] }],       // header dropdown
      [{ 'color': ['white', 'red', 'green', 'yellow','blue', '#3293ca', '#575452', 'black'] },
        { 'background': ['white', 'red', 'green', 'yellow','blue', '#3293ca','#575452', 'black'] }], // dropdown with defaults
      [{ 'font': ['Oxygen', 'OpenSans', 'roboto', 'Lato', 'Baskervville', 'Courgette'] }],                                // font family
      [{ 'align': [] }],                               // text align
      ['image', 'video', 'link'],
      ['clean'],                                       // remove formatting
    ];
    this.modules = {
      blotFormatter: {
        specs: [
          CustomImageSpec,
          CustomIframeVideoSpec,
          CustomDirectVideoSpec
        ],
      },
      toolbar: {
        container: this.toolbarOptions,
        handlers: {
          image: this.imageHandler
        }
      }
    };
    this.formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'background',
      'list', 'bullet', 'indent', 'align',
      'size', 'color', 'font', 'image', 'video', 'display', 'link', 'margin',
      'alt',
      'height',
      'width',
      'style',
      'float',
      'directVideo'
    ];
  }

  insertImageToEditor(url) {
    const range = this.state.selectedRange;
    if(url && range) {
      this.quillRef.getEditor().insertEmbed(range.index, 'image', url, "user");
      this.quillRef.getEditor().setSelection(range.index+1, "user");
    }
    this.setState({ isOpen: false, selectedRange: null });
  };

  insertVideoToEditor(url) {
    const range = this.state.selectedRange;
    if(url && range) {
      this.quillRef.getEditor().insertEmbed(range.index, 'directVideo', url, "user");
      this.quillRef.getEditor().setSelection(range.index+1, "user");
    }
    this.setState({ isOpen: false, selectedRange: null });
  }

  imageHandler() {
    this.handleOpenModal(true);
    this.setState((prevState) => ({ ...prevState, selectedRange: this.quillRef.getEditor().getSelection() }));
  };

  handleOpenModal (bool) {
    this.setState({ isOpen: bool });
  };

  render() {
    if (typeof window !== 'undefined' && ReactQuill) {
      return (
        <div className={this.props.className}>
          <Modal
            title="Image Selector"
            size="xl"
            className="rounded-0"
            isOpen={this.state.isOpen}
            closeModal={() => this.handleOpenModal(false)}
            centered
          >
            <ImageSelector
              isOpen={this.state.isOpen}
              insertImage={this.insertImageToEditor}
              insertVideo={this.insertVideoToEditor}
            />
          </Modal>
          { this.props.readOnly ?
            <div className={ this.props.value ? "ql-editor border p-2" : "p-0" } dangerouslySetInnerHTML={{__html: this.props.value }}/>
            :
            <ReactQuill
              ref={(el) => this.quillRef = el}
              modules={this.modules}
              formats={this.formats}
              scrollingContainer='quill'
              placeHolder={"Add anything here..."}
              theme="snow"
              value={this.props.value}
              onChange={this.props.handleTextChange}
            />
          }
        </div>
      );
    } else {
      return <textarea />
    }
  }
}
