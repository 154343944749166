import React, { useState, useEffect } from 'react';
import { selectErrorMessage } from './errorHandlingFuncs';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
moment.locale('en');
momentLocalizer();

const DateTimeInput = ({
 key, className, label, name, initialValue, helpMessage, step,
 validate, validationOptions
}) => {
  const [value, setValue] = useState(initialValue);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (touched) {
      setError(selectErrorMessage(validationOptions, value));
    } else {
      setError("");
    }
  }, [touched, value, validationOptions]);

  useEffect(() => {
    if (validate) {
      setError(selectErrorMessage(validationOptions, value));
    }
  }, [validate, value, validationOptions]);

  return (
    <div key={key} className={className}>
      {
        label
        && (
          <label>
            {label}
          </label>
        )
      }
      <DateTimePicker
        max={new Date()}
        id={name}
        name={name}
        format="D MMM YYYY hh:mm a"
        containerClassName="rounded-0 mb-2"
        value={value}
        onChange={(value) => setValue(value)}
        step={step}
        onBlur={() => setTouched(true)}
      />
      <small className="text-muted">{ helpMessage }</small>
      {error && <p className="text-danger">{error}</p>}
    </div>
  )
};

export default DateTimeInput;
