import gql from 'graphql-tag';

export const CREATE_WELLBEING_RECORD = gql`
    mutation createWellbeingRecord(
        $overallWellbeing: Int!,
        $physicalWellbeing: Int,
        $emotionalWellbeing: Int,
        $energyLevel: Int,
        $progressRecordId: ID,
        $tags: String,
        $notes: String
    ){
        createWellbeingRecord(data:{
            overallWellbeing: $overallWellbeing,
            physicalWellbeing: $physicalWellbeing,
            emotionalWellbeing: $emotionalWellbeing,
            energyLevel: $energyLevel,
            progressRecordId: $progressRecordId,
            tags: $tags,
            notes: $notes
        }) {
            id
            overallWellbeing
            physicalWellbeing
            emotionalWellbeing
            energyLevel
            progressRecord {
                id
            }
            tags
            notes
            createdAt
        }
    }
`;

export const UPDATE_WELLBEING_RECORD = gql`
    mutation updateWellbeingRecord(
        $id: ID!,
        $overallWellbeing: Int!,
        $physicalWellbeing: Int,
        $emotionalWellbeing: Int,
        $energyLevel: Int,
        $progressRecordId: ID,
        $tags: String,
        $notes: String
    ){
        updateWellbeingRecord(
            id: $id,
            data:{
                overallWellbeing: $overallWellbeing,
                physicalWellbeing: $physicalWellbeing,
                emotionalWellbeing: $emotionalWellbeing,
                energyLevel: $energyLevel,
                progressRecordId: $progressRecordId,
                tags: $tags,
                notes: $notes
            }
        ) {
            id
            overallWellbeing
            physicalWellbeing
            emotionalWellbeing
            energyLevel
            progressRecord {
                id
            }
            tags
            notes
            createdAt
        }
    }
`;
