import React, {Fragment, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Col, Row} from "reactstrap";
import TalliesChart from "../TalliesChart";
import CategoriesChart from "../CategoriesChart";
import CalendarSection from "../CalendarSection";
import CollapsibleInline from "../../../components/CollapsibleInline";
import {
  FiPieChart,
  FiCalendar,
  FiPlusSquare
} from 'react-icons/fi';
import MyProgressTable from "../MyProgressTable";
import MyProgressCards from "../MyProgressCards";
import moment from "moment";
import {useMutation, useQuery} from "react-apollo-hooks";
import {DELETE_ITEM, MY_ITEMS, MY_PROGRESS_RECORDS} from "../apollo";
import {ITEMS} from "../../ProgressPosts/apollo";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import CategoryModal from "../../../components/CategoryModal";
import ProgressRecordModal from "../../../components/ProgressRecordModal";
import LikedByModal from "../../../components/LikedByModal";
import LoadingPage from "../../LoadingPage";
import SortingButton from "../MyProgressCards/SortingButton";

const MyProgressSection = ({ width, myId, followingIds, toggleFollow }) => {
  const [ selectedGraphDateRange, setSelectedGraphDateRange ] = useState('week');
  const [ showChart, setShowChart ] = useState(true);
  const [ showCalendar, setShowCalendar ] = useState(true);
  const [ likedByModalOpen, setLikedByModalOpen ] = useState(false);
  const [ selectedPostId, setSelectedPostId ] = useState(null);
  const [isCalendarLoaded, setCalendarLoaded] = useState(false);
  const [ sortCol, selectSortCol ] = useState( { col: '', state: '' });
  const [ filterTerm, setFilterTerm ] = useState('');
  const [ selectedItem, selectItem ] = useState({ id: '', title: ''});
  const [ isDeleteModalOpen, setDeleteModalOpen ] = useState(false);
  const [ isCategoryModalOpen, setCategoryModalOpen ] = useState(false);
  const [ isProgressRecordModalOpen, setProgressRecordModalOpen ] = useState(false);
  const [ isListView, setToListView ] = useState(false);

  const [items, setItems] = useState([]);
  const { error, data, loading, refetch } = useQuery(MY_ITEMS, { suspend: false, fetchPolicy: "cache-and-network" });
  useEffect(() => {
    if (!loading && !sortCol.col) {
      const newData = data.myItems ? data.myItems.map(item => ({
        ...item,
        fill: item.categoryColor ? item.categoryColor : 'gainsboro',
      })) : [];
      setItems([...newData]);
    }
  }, [loading, JSON.stringify(data.myItems), sortCol.col]);

  const deleteItem = useMutation(
    DELETE_ITEM,
    {
      variables: { id: selectedItem && selectedItem.id },
      refetchQueries: [{ query: ITEMS }],
      suspense: false
    }
  );

  const handleDeleteConfirmation = async () => {
    await deleteItem();
    await refetch();
    openDeleteModal(false);
  };

  const openDeleteModal = (modalState, item={ id: '', title: ''}) => {
    selectItem(item);
    setDeleteModalOpen(modalState);
  };

  const openCategoryModal = (modalState) => {
    setCategoryModalOpen(modalState);
  };

  const openProgressRecordModal = (modalStatus, item) => {
    selectItem(item);
    setProgressRecordModalOpen(modalStatus);
  };

  const filterByTerm = (item) => {
    if (!filterTerm) return true;
    const filterTermLowerCase = filterTerm  && filterTerm.toLowerCase();
    const titleIncluded = item && item.title
      .toLowerCase()
      .includes(filterTermLowerCase);
    const categoryIncluded = item && item.categoryTitle && item.categoryTitle
      .toLowerCase()
      .includes(filterTermLowerCase);
    const totalTallyIncluded = item && item.totalProgressTally && item.totalProgressTally.toString().includes(filterTermLowerCase);
    const latestProgressTallyIncluded = item
      && item.latestProgressTally
      && item.latestProgressTally.toString().includes(filterTermLowerCase);

    let latestProgressDate = (item
      && item.latestProgressDate) || '';
    latestProgressDate = (latestProgressDate && moment(latestProgressDate).format('ddd MMM Do YYYY, h:mm a')) || '';
    const latestProgressDateIncluded = latestProgressDate && latestProgressDate.toLowerCase().includes(filterTermLowerCase);
    const tagsIncluded = item && item.tags && item.tags.toLowerCase().includes(filterTermLowerCase);
    const checkPublished = filterTermLowerCase === 'published' ? item.published : filterTermLowerCase === 'not published' ? !item.published : false;
    const descriptionIncluded = item && item.description && item.description
      .toLowerCase()
      .includes(filterTermLowerCase);
    return (
      titleIncluded
      || totalTallyIncluded
      || latestProgressTallyIncluded
      || latestProgressDateIncluded
      || categoryIncluded
      || tagsIncluded
      || checkPublished
      || descriptionIncluded
    );
  };

  const toggleSort = (col) => {
    if (col !== sortCol.col) {
      selectSortCol({ col, state: 'descending' });
    } else {
      switch (sortCol.state) {
        case "descending":
          selectSortCol({ col, state: 'ascending' });
          break;
        case "ascending":
          selectSortCol({ col: null, state: null });
          setItems([...data.myItems]);
          break;
        default:
      }
    }
  };

  const sortByCol = (a, b) => {
    let first = "";
    let second = "";
    let sortType = "";
    switch (sortCol.col) {
      case "category":
        first = a.categoryTitle ? a.categoryTitle.toLowerCase() : '';
        second = b.categoryTitle ? b.categoryTitle.toLowerCase() : '';
        sortType = "string";
        break;
      case "item":
        first = a.title.toLowerCase();
        second = b.title.toLowerCase();
        sortType = "string";
        break;
      case "published":
        first = a.published;
        second = b.published;
        sortType = "string";
        break;
      case "latestProgress":
        first = a.latestProgressDate ? a.latestProgressDate : '';
        second = b.latestProgressDate ? b.latestProgressDate : '';
        sortType = "string";
        break;
      case "tally":
        first = a.totalProgressTally;
        second = b.totalProgressTally;
        sortType = "number";
        break;
      default:
        return 0;
    }
    if (sortType === "number") {
      switch (sortCol.state) {
        case "ascending":
          return first - second;
        case "descending":
          return second - first;
        default:
          return 0;
      }
    }
    if (sortType === "string") {
      switch (sortCol.state) {
        case "ascending":
          if (first < second) return -1;
          if (first > second) return 1;
          return 0;
        case "descending":
          if (first > second) return -1;
          if (first < second) return 1;
          return 0;
        default:
          return 0;
      }
    }
  };

  const toggleChartAndCalendar = (selection) => {
    if (selection === 'chart') {
      if (showChart) {
        setShowChart(false);
      } else {
        setShowChart(true);
      }
    } else if (selection === 'calendar') {
      if (showCalendar) {
        setShowCalendar(false);
      } else {
        setShowCalendar(true);
      }
    } else {
      setShowChart(false);
      setShowCalendar(false);
    }
  };

  if (loading) return <LoadingPage />;
  if (error) return <LoadingPage error={error} />;

  return (
    <div>
      <DeleteConfirmationModal
        message="Are you sure you want to delete this item?"
        selectedItem={selectedItem}
        isModalOpen={isDeleteModalOpen}
        openModal={openDeleteModal}
        callbackOnConfirm={handleDeleteConfirmation}
      />
      <CategoryModal
        isModalOpen={isCategoryModalOpen}
        openModal={openCategoryModal}
      />
      <ProgressRecordModal
        refetchQueries={[{ query: MY_ITEMS }, { query: MY_PROGRESS_RECORDS }]}
        selectedItem={selectedItem}
        isModalOpen={isProgressRecordModalOpen}
        openModal={openProgressRecordModal}
      />
      <LikedByModal
        authenticated
        myId={myId}
        postId={selectedPostId}
        followingIds={followingIds}
        toggleFollow={toggleFollow}
        isModalOpen={likedByModalOpen}
        closeModal={() => {
          setLikedByModalOpen(false);
          setSelectedPostId(null);
        }}
      />
      <h2 className="text-center pt-2 pb-3">My Progress</h2>
      <div className="text-center py-2">
        <span className="display-4">Let's add your progress item.</span>
        &nbsp;
        <Link
          style={{ cursor: 'pointer' }}
          to="/item/new"
        >
          <FiPlusSquare
            size={20}
            className="text-success"
          />
        </Link>
      </div>
      <div className="d-flex pt-3 pb-1 flex-wrap">
        <label
          htmlFor="searchBar"
          className="col-lg-1 col-form-label"
        >
          Search:
        </label>
        <input
          id="searchBar"
          className="col-lg-11 form-control m-auto"
          value={filterTerm}
          onChange={e => setFilterTerm(e.target.value)}
        />
      </div>
      <Row className="justify-content-center">
        Sort By:
      </Row>
      <Row className="justify-content-center pb-2">
        <SortingButton
          title="Item"
          targetCol="item"
          sortCol={sortCol}
          onClick={() => toggleSort('item')}
        />
        <SortingButton
          title="Category"
          targetCol="category"
          sortCol={sortCol}
          onClick={() => toggleSort('category')}
        />
        <SortingButton
          title="Published"
          targetCol="published"
          sortCol={sortCol}
          onClick={() => toggleSort('published')}
        />
        <SortingButton
          title="Latest Progress"
          targetCol="latestProgress"
          sortCol={sortCol}
          onClick={() => toggleSort('latestProgress')}
        />
        <SortingButton
          title="Total Tally"
          targetCol="tally"
          sortCol={sortCol}
          onClick={() => toggleSort('tally')}
        />
      </Row>
      <CollapsibleInline
        initialStatus={true}
        title="Progress Charts"
        wrapperClassName="border shadow px-1 mb-3"
        className="py-3 justify-content-center"
        titleClassName=""
        description="Chart View of your progress"
      >
        {
          items && items.length > 0 && (
            <div className="TalliesChart">
              {/*<div className="input-group m-auto py-2" style={{ maxWidth: '15rem'}}>*/}
              {/*  <div className="input-group-prepend">*/}
              {/*    <label className="input-group-text">Date Range:</label>*/}
              {/*  </div>*/}
              {/*  <select*/}
              {/*    className="custom-select"*/}
              {/*    style={{ width: 'auto' }}*/}
              {/*    onChange={(e) => {*/}
              {/*      setSelectedGraphDateRange(e.target.value);*/}
              {/*    }}*/}
              {/*    value={selectedGraphDateRange}*/}
              {/*  >*/}
              {/*    <option value="day">day</option>*/}
              {/*    <option value="week">week</option>*/}
              {/*    <option value="month">month</option>*/}
              {/*  </select>*/}
              {/*</div>*/}
              <Col lg={12}>
                <Row className="d-flex justify-content-center p-1">
                  <Col lg={5} className="p-2 m-2">
                    <TalliesChart
                      items={
                        items
                          .sort(sortByCol)
                          .filter(filterByTerm)
                          .filter(item => item.totalProgressTally !== null)
                      }
                    />
                  </Col>
                  <Col lg={5} className="p-2 m-2">
                    <CategoriesChart
                      items={items}
                    />
                  </Col>
                </Row>
              </Col>
            </div>
          )
        }
      </CollapsibleInline>
      <CollapsibleInline
        initialStatus={true}
        title="Progress Calendar"
        wrapperClassName="border shadow px-1 mb-3"
        className="py-3 justify-content-center"
        titleClassName=""
        description="Calendar view of your progress"
      >
        <CalendarSection
          items={
            items
              .sort(sortByCol)
              .filter(filterByTerm)
          }
          setCalendarLoaded={setCalendarLoaded}
        />
      </CollapsibleInline>
      {
        isCalendarLoaded
        && (
          <CollapsibleInline
            initialStatus={true}
            title="Progress Items"
            wrapperClassName="border shadow px-1 mb-3"
            className="py-2 justify-content-center"
            titleClassName=""
            description="Manage all your progress items below"
          >
            <div className="text-center">
              {
                width > 992
                && (
                  <button
                    className="btn btn-link rounded-0"
                    onClick={() => setToListView(!isListView)}
                  >
                    Switch to {isListView ? 'Card' : 'List'} View
                  </button>
                )
              }
            </div>
            <div>
              {
                isListView && (width > 992) ? (
                  <MyProgressTable
                    authenticated
                    setSelectedPostId={setSelectedPostId}
                    setLikedByModalOpen={setLikedByModalOpen}
                    filterTerm={filterTerm}
                    items={
                      items
                        .sort(sortByCol)
                        .filter(filterByTerm)
                    }
                    showChart={showChart}
                    toggleSort={(col) => toggleSort(col)}
                    sortCol={sortCol}
                    openDeleteModal={openDeleteModal}
                    openCategoryModal={openCategoryModal}
                    openProgressRecordModal={openProgressRecordModal}
                  />
                ) : (
                  <MyProgressCards
                    authenticated
                    setSelectedPostId={setSelectedPostId}
                    setLikedByModalOpen={setLikedByModalOpen}
                    filterTerm={filterTerm}
                    items={
                      items
                        .sort(sortByCol)
                        .filter(filterByTerm)
                    }
                    showChart={showChart}
                    toggleSort={(col) => toggleSort(col)}
                    sortCol={sortCol}
                    openDeleteModal={openDeleteModal}
                    openCategoryModal={openCategoryModal}
                    openProgressRecordModal={openProgressRecordModal}
                  />
                )
              }
            </div>
          </CollapsibleInline>
        )
      }
    </div>
  );
};

export default MyProgressSection;
