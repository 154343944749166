import React, { useState, useEffect } from 'react';
import {useMutation, useQuery} from 'react-apollo-hooks';
import {FOLLOW, ME} from '../ProgressPosts/apollo';
import Row from 'reactstrap/lib/Row';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';

import './MyArea.scss';
import LoadingPage from "../LoadingPage";
import MyProgressSection from './MyProgressSection';
import WellbeingSection from './WellbeingSection';

const MyArea = () => {
  const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 992;
  const [width, setWidth] = useState(windowWidth);
  useEffect(() => {
    if (typeof window !=='undefined') {
      const handleResize = () => setWidth(windowWidth);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  });
  const [ activeTab, setActiveTab ] = useState('myProgress');
  const { data: meData, loading: meLoading } = useQuery(ME, { suspend: false });

  const toggleFollow = useMutation(
    FOLLOW,
    {
      refetchQueries: [{ query: ME }],
      suspense: false
    }
  );

  if (meLoading) return <LoadingPage />;

  const { id: myId, following } = (meData && meData.me) || {};
  const followingIds = (following && following.map(user => user && user.id)) || [];

  const tabItems = [{ id: 'myProgress', title: 'My Progress' }, { id: 'myWellbeing', title: 'My Wellbeing' }];

  return (
    <div className="container MyArea">
      <Row className="pb-2">
        <div className="col-sm-12 px-0">
          <div className={`titleSection-${activeTab}`} />
        </div>
      </Row>
      <Row className="pt-3 pb-4">
        <div className="col-sm-12 px-sm-3 px-1">
          <div className="text-center mt-1">
            <h1
              className="pb-2 text-center text-dark"
            >
              My Area
            </h1>
            <p className="display-3 mb-1">Track your progress journey on anything</p>
            <p className="display-4 mb-1">Also, keep a record of your wellbeing</p>
          </div>

          <Nav tabs>
            {
              tabItems && tabItems.map(({ id, title }) => {
                return (
                  <NavItem
                    key={id}
                    style={{ cursor: 'pointer' }}
                  >
                    <NavLink
                      className={activeTab === `${id}` ? "active text-primary": ""}
                      onClick={() => setActiveTab(id)}
                    >
                      { title }
                    </NavLink>
                  </NavItem>
                );
              })
            }
          </Nav>
          <TabContent activeTab={activeTab}>
            {
              tabItems && tabItems.map(({ id, title }) => {
                return (
                  <TabPane key={id} tabId={id} className="bg-beige shadow py-2 px-sm-2 px-1">
                    {
                      id === 'myProgress' && (
                        <MyProgressSection
                          width={width}
                          myId={myId}
                          followingIds={followingIds}
                          toggleFollow={toggleFollow}
                        />
                      )
                    }
                    {
                      id === 'myWellbeing' && (
                        <WellbeingSection />
                      )
                    }
                  </TabPane>
                );
              })
            }
          </TabContent>
        </div>
      </Row>
    </div>
  )
};

export default MyArea;
