import React, { useState } from 'react';
import moment from 'moment';
import {useQuery, useMutation} from 'react-apollo-hooks';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Button from 'reactstrap/lib/Button';

import { MY_PROFILE, CHANGE_AVATAR_URL } from './apollo';
import { ME } from "../../components/Menubar/Dropdown/apollo";
import LoadingPage from '../LoadingPage';
import { FiImage, FiEdit, FiXCircle } from 'react-icons/fi';
import Modal from '../../components/Modal';
import ImageSelector from '../../components/Editor/ImageSelector';
import CollapsibleInline from "../../components/CollapsibleInline";
import ChangeEmailForm from './ChangeEmailForm';
import DeactivateAccountForm from './DeactivateAccountForm';
import DeleteAccountForm from './DeleteAccountForm';
import './Settings.scss';
import AvatarImage from "../../components/AvatarImage";

const Settings = ({ history, ...props }) => {
  const [ avatarDeleted, setAvatarDeleted ] = useState(false);
  const [ modalSize, setModalSize ] = useState('');
  const [ settingsModalOpen, setSettingsModalOpen ] = useState(false);
  const [ modalTitle, setModalTitle ] = useState(null);
  const [ alertMessage, setAlertMessage ] = useState({ message: "", isError: false });
  const changeAvatarUrl = useMutation(CHANGE_AVATAR_URL, {
    refetchQueries: [{ query: ME, suspend: false }],
    suspend: false
  });
  const { data, loading } = useQuery(MY_PROFILE, { suspend: false });
  const { me } = data || {};
  const { username, avatarUrl, email, createdAt, updatedAt } = me || {};

  if (loading) return <LoadingPage small hideLoadingText />;

  const handleAvatarUrlChange = async (selectedUrl) => {
    if (!avatarDeleted || selectedUrl !== null) {
      try {
        const result = await changeAvatarUrl({ variables: { avatarUrl: selectedUrl }});
        if (result && result.data) {
          const { avatarUrl } = (result.data && result.data.changeAvatarUrl) || {};
          if (avatarUrl) {
            setAlertMessage({ message: "Successfully saved your Avatar Image Url.", isError: false });
            setAvatarDeleted(false);
          } else {
            setAlertMessage({ message: "Successfully deleted your Avatar Image Url.", isError: false });
            setAvatarDeleted(true);
          }
        }
      } catch (err) {
        setAlertMessage({ message: "Failed to save your Avatar Image Url.", isError: true });
      }
    }
    setSettingsModalOpen(false);
  };

  const { message, isError } = alertMessage || {};
  return (
    <Container className="Settings">
      <Modal
        title={modalTitle}
        size={modalSize}
        className="rounded-0"
        isOpen={settingsModalOpen}
        closeModal={() => {
          setSettingsModalOpen(false);
          setModalTitle(null);
        }}
        centered
      >
        {
          modalTitle === "Image Selection" && (
            <ImageSelector
              isOpen={settingsModalOpen}
              insertImage={handleAvatarUrlChange}
            />
          )
        }
        {
          modalTitle === "Change Email" && (
            <ChangeEmailForm />
          )
        }
        {
          modalTitle === "Deactivate Account" && (
            <DeactivateAccountForm
              history={history}
            />
          )
        }
        {
          modalTitle === "Delete Account" && (
            <DeleteAccountForm
              history={history}
            />
          )
        }
      </Modal>
      <Row className="p-3">
        <h1 className="m-auto">Settings</h1>
      </Row>
      <div className="p-3 text-center">
        <p className="mb-2">
          Username: {username}
        </p>
        <AvatarImage
          className="mb-3"
          avatarUrl={avatarUrl}
          style={{ width: '10rem', height: '10rem', borderRadius: "100%", objectFit: "cover" }}
        />
        <p className="mb-0">
          Select your profile image:
        </p>
        <p className="">
          <span className="pr-1">Open Image Library</span>
          <FiImage
            size={20}
            className="text-primary"
            style={{ cursor: 'pointer' }}
            role="button"
            onClick={() => {
              setModalSize('xl');
              setModalTitle("Image Selection");
              setSettingsModalOpen(true);
            }}
          />
        </p>
        <p className="">
          <span className="pr-1">Remove Profile Image Link</span>
          <FiXCircle
            size={20}
            className="text-danger"
            style={{ cursor: 'pointer' }}
            onClick={() => handleAvatarUrlChange(null)}
          />
        </p>
        {
          message && (
            <div className={`alert ${isError ? "alert-danger" : "alert-success"}`}>
              {message}
            </div>
          )
        }
        <p className="d-flex justify-content-center align-items-center">
          Email: {email} &nbsp;
          <FiEdit
            size={20}
            className="text-primary"
            style={{ cursor: 'pointer' }}
            role="button"
            onClick={() => {
              setModalSize('md');
              setModalTitle('Change Email');
              setSettingsModalOpen(true);
            }}
          />
        </p>
        <p>
          Updated At: { updatedAt ? moment(updatedAt).format('dddd D MMM YYYY, hh:mm a') : ''}
        </p>
        <p>
          Created At: { createdAt ? moment(createdAt).format('dddd D MMM YYYY, hh:mm a') : ''}
        </p>
      </div>
      <div className="row">
        <div className="col-lg-8 col-md-10 m-auto pb-4">
          <CollapsibleInline
            title="Account Deactivation/Deletion"
            className="justify-content-center"
            titleClassName="text-danger"
            bodyClassName="bg-white p-3"
          >
            <div className="alert alert-warning">
              <p>If you want to take a break from Progress MAKR for a while or someone compromised your account without your permission, you can deactivate your account.  All your posts and related comments will become invisible from the public posts.  No one will be able to log into your account to access your private data anymore.</p>
              <p>To access your account again, enter your username and password at login, then request a confirmation email from us and confirm your account with the email link.</p>
              <div className="text-center my-4">
                <Button
                  color="danger"
                  outline
                  onClick={() => {
                    setModalSize('lg');
                    setModalTitle('Deactivate Account');
                    setSettingsModalOpen(true);
                  }}
                >
                  Yes, I want to deactivate my account for now
                </Button>
              </div>
            </div>
            <div className="alert alert-danger">
              <p>If you want to delete your account, please proceed below.  <strong>Once you confirm, there is no way to retrieve your data again.  All your data including public and private progress as well as comments and all images in your image library will be deleted immediately.</strong></p>
              <div className="text-center my-4">
                <Button
                  color="danger"
                  outline
                  onClick={() => {
                    setModalSize('lg');
                    setModalTitle('Delete Account');
                    setSettingsModalOpen(true);
                  }}
                >
                  Yes, I want to delete my account
                </Button>
              </div>
            </div>
          </CollapsibleInline>
        </div>
      </div>
    </Container>
  );
};

export default Settings;
