import React from 'react';
import FormBox from '../../form';
import progressRecordFieldsArr from './progressRecordFieldsArr';
import { useMutation } from "react-apollo-hooks";
import {
  CREATE_PROGRESS_RECORD,
  UPDATE_PROGRESS_RECORD
} from './apollo';
import './ProgressRecordForm.scss';

const ProgressRecordForm = ({ selectedItemId, progressRecordId, record, openModal, refetchQueries }) => {
  const saveProgressRecord = useMutation(
    (progressRecordId ? UPDATE_PROGRESS_RECORD : CREATE_PROGRESS_RECORD),
    {
      refetchQueries,
      suspense: false
    }
  );
  const handleSaveProgressRecord = (variables) => {
    saveProgressRecord({ variables: progressRecordId ? { ...variables, id: progressRecordId } : variables });
  };

  // merge the data into inputFieldsArr.
  const inputFieldsArr = progressRecordFieldsArr
    .map(fieldObj => {
      // If progressRecordId exists, then it must be just updating, so set initial ErrorStatus for each field to fault.
      if (progressRecordId) fieldObj.errorStatus = false;
      if (fieldObj.name === 'latestProgressDate') {
        if (progressRecordId && record && record[fieldObj.name]) {
          fieldObj.value = new Date(record[fieldObj.name]);  // if the record exists, use the existing date to pre-populate input.
        } else {
          fieldObj.value = new Date();
        }
      } else {
        fieldObj.value = (record && record[fieldObj.name]) || fieldObj.initialValue;
      }
      return fieldObj;
    });

  return (
    <FormBox
      className="ProgressRecordForm"
      itemId={selectedItemId}
      idAsItemId
      buttonText="Submit"
      cancelFunc={() => openModal(false)}
      inputFieldsArr={inputFieldsArr}
      callbackOnSuccess={(variables) => {
        handleSaveProgressRecord(variables);
        openModal(false);
      }}
    />
  );
};

export default ProgressRecordForm;
