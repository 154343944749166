import React from 'react';
import FormBox from '../../components/form';
import { SIGN_UP } from './apollo';
import signUpFieldsArr from './signUpFieldsArr';
import { MY_ITEMS } from '../MyArea/apollo';

const SignUpForm = ({ setSignedUp }) => {
  return (
    <FormBox
      mutation={SIGN_UP}
      buttonText="Sign Up"
      alternativeLinks={[{ title: 'Log In', to: '/login' }]}
      inputFieldsArr={signUpFieldsArr}
      refetchQueries={[{ query: MY_ITEMS }]}
      comparePasswords
      customMessage={{ comparePasswords: 'Password does not match with Confirm Password.  Please enter the same password twice.'}}
      signUp
      callbackOnSuccess={()=>{
        setTimeout(() => {
          setSignedUp(true);
        }, 500);
      }}
    />
  );
};

export default SignUpForm;

