import React from 'react';
import Popover from "../../../components/Popover";
import data from './data';

const RiskLevelInfoPopover = () => {
  return (
    <Popover
      buttonText={data.buttonText}
      titleText={data.titleText}
      body={data.body}
    />
  );
};

export default RiskLevelInfoPopover;
