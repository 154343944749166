let fromDate = new Date();
fromDate.setDate(fromDate.getDate() - 30);

const dataRangeFieldsArr = [
  {
    type: "group",
    groupInnerClassName: 'd-flex justify-content-center',
    groupFields: [
      { type: 'datetime', label: 'From', name: 'fromDateTime', initialValue: fromDate,
        validationOptions: {
          required: { value: true, errorMessage: 'This field is required.' },
        }
      },
      { type: 'datetime', label: 'To', name: 'toDateTime', initialValue: new Date(),
        validationOptions: {
          required: { value: true, errorMessage: 'This field is required.' },
        }
      }
    ]
  }
];

export default dataRangeFieldsArr;
