import React, { Fragment } from 'react';
import PublicItemCards from '../../components/PublicItemCards';
import {Row, Col} from "reactstrap";
import {useQuery} from "react-apollo-hooks";
import { USER_ITEMS } from './apollo';
import LoadingPage from "../LoadingPage";

const ItemsTabSection = ({ profileName, authenticated, refetch }) => {
  const { error, data, loading } = useQuery(USER_ITEMS, { variables: { username: profileName }, suspend: false, fetchPolicy: "network-only" });
  if (loading) return <LoadingPage />;
  const { items } = data || {};
  return (
    <Fragment>
      <Row>
        <Col>
          {
            (items && items.length > 0) ? (
              <p>{profileName} has published below items so far.</p>
            ): (
              <p>There are no published item by {profileName} yet.</p>
            )
          }
        </Col>
      </Row>
      <Row className="mb-3">
        <PublicItemCards
          parentId="itemsTab"
          authenticated={authenticated}
          profileName={profileName}
          items={items}
          refetch={refetch}
        />
      </Row>
    </Fragment>
  );
};

export default ItemsTabSection;
