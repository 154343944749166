import React, { Fragment } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap/lib';
import { FiInfo } from 'react-icons/fi';

const Popover = ({ buttonText, titleText, body, placement }) => {
  return (
    <Fragment>
      <div
        id="PopoverLegacy"
        style={{ "cursor": "pointer"}}
        className="text-info d-inline-block"
      >
        <FiInfo /> <small>{ buttonText }</small>
      </div>
      <UncontrolledPopover trigger="legacy" placement={placement} target="PopoverLegacy">
        <PopoverHeader>
          <span>{ titleText }</span>
        </PopoverHeader>
        <PopoverBody>
          {
            body.map(({ textClassName, text }, i) => (
              <p
                key={i}
                className={textClassName}
              >
                { text }
              </p>
            ))
          }
        </PopoverBody>
      </UncontrolledPopover>
    </Fragment>
  );
};

export default Popover;
