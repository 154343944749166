const itemFieldsArr = [
  { type: 'text', label: 'Title (required)', name: 'title', initialValue: '',
    validationOptions: {
      required: { value: true, errorMessage: 'This field is required.' },
      minLength: { value: 3, errorMessage: 'It should not be less than 3 characters long.' },
      maxLength: { value: 60, errorMessage: 'It should not be more than 60 characters long.' }
    },
    helpMessage: "Keep the title short. e.g. Learning to play a guitar, regular exercises, learn French, quit drinking"
  },
  { type: 'textarea', label: 'Description', name: 'description', initialValue: '',
    validationOptions: {
      maxLength: { value: 400, errorMessage: 'It should not be more than 400 characters long.' }
    },
    helpMessage: "Please write a short description of this item."
  },
  { type: 'checkbox', label: 'Publish', name: 'published', initialValue: false,
    helpMessage: "Once you tick publish, others will be able to see this item in the Progress Posts page and in public. Don't tick if you want keep it private."
  },
  { type: 'checkbox', label: 'Allow Others Comments', name: 'allowOthersComment', initialValue: false,
    helpMessage: "If this item is published, do you want allow other members to comment on it?"
  },
  { type: 'tags', label: 'Tags (optional)', name: 'tags', initialValue: '',
    helpMessage: "Add any keywords related to this item. They will help you group and search items later.  e.g. health, chores, school, gardening..."
  },
  { type: 'select', label: 'Category (optional)', name: 'categoryId', initialValue: '', initialColor: '', helpMessage: "Select a category. Or deselect it by choosing 'No category selected'. By selecting categories for different items, you can track your overall life balance across different areas."　},
  { type: 'editor', label: 'Notes (optional but highly recommended to fill in)', name: 'notes', initialValue: '',
    helpMessage: "We recommend you to have rich notes about your item - you can create an introductory article about this item. - e.g. What it is and why you decided to track progress on this, and what you would like to achieve by doing so."
  }
];

export default itemFieldsArr;
