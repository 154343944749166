import React, { useState, useEffect } from 'react';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { useQuery, useMutation } from 'react-apollo-hooks';
import {MY_ITEMS, MY_PROGRESS_RECORDS, DELETE_PROGRESS_RECORD } from '../apollo';
import getDaysOfMonth from '../../../utils/getDaysOfMonth';
import './CalendarSection.scss';
import ProgressRecordsModal from '../../../components/ProgressRecordsModal';
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import { Link } from 'react-router-dom';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Table from 'reactstrap/lib/Table';

const CalendarSection = ({ items, setCalendarLoaded }) => {
  const {error, data, loading } = useQuery(MY_PROGRESS_RECORDS, { suspend: false, fetchPolicy: 'cache-and-network' });
  const dateNow = new Date();
  const currentYear = dateNow.getFullYear();
  const currentMonth = dateNow.getMonth()+1;
  const currentDate = dateNow.getDate();

  const initialDate = {
    targetYear: currentYear,
    targetMonth: currentMonth,
    targetDate: currentDate,
  };
  const [ dateObj, setDateObj ] = useState(initialDate);
  useEffect(() => {
    setCalendarLoaded(true);
  }, [dateObj, setCalendarLoaded]);
  const [ isDeleteModalOpen, setDeleteModalOpen ] = useState(false);
  const [ selectedProgressRecord, setSelectedProgressRecord ] = useState('');
  // const [ isPREditMode, setIsPREditMode ] = useState(false);
  const [ isTableItemTitleOpen, setTableItemTitleOpen ] = useState(true);
  const [ modalStatus, openModal ] = useState({ isOpen: false, records: [], itemId: null, targetDateStr: '' });
  const daysOfMonth = getDaysOfMonth(dateObj.targetYear, dateObj.targetMonth);

  const deleteProgressRecord = useMutation(DELETE_PROGRESS_RECORD, {
    refetchQueries: [{ query: MY_ITEMS }, { query: MY_PROGRESS_RECORDS }]
  });

  const handleDeleteRecordConfirmation = (variables) => {
    deleteProgressRecord({ variables: { id: variables.id }});
    setDeleteModalOpen(false);
    setSelectedProgressRecord('');
  };

  const handleMonthChange = (direction) => {
    let { targetYear, targetMonth } = dateObj;
    if ((dateObj.targetMonth + direction) > 12) {
      targetYear = targetYear + 1;
      targetMonth = 1;
    } else if ((dateObj.targetMonth + direction) < 1) {
      targetYear = targetYear -1;
      targetMonth = 12;
    } else {
      targetMonth = dateObj.targetMonth + direction;
    }
    const isThisMonth = currentYear === targetYear && currentMonth === targetMonth;
    setDateObj({
      targetYear,
      targetMonth,
      targetDate: isThisMonth ? currentDate : 1,
    });
  };
  const targetMonthStr = `${dateObj.targetYear}-${(("0"+dateObj.targetMonth.toString()).slice(-2))}`;
  const isThisMonth = currentYear === dateObj.targetYear && currentMonth === dateObj.targetMonth;

  if (error) return `Error! ${error.message}`;
  if (loading) return null;
  // const selectedDateStr = modalStatus.records[0] && modalStatus.records[0].latestProgressDate;
  // const selectedYear = moment(selectedDateStr).format('dddd D MMM YYYY, hh:mm a');
  // const daysTotal = modalStatus.records.reduce((acc, record) => acc + record.progressTally, 0);
  return (
    <Row className="CalendarSection d-flex justify-content-center">
      <DeleteConfirmationModal
        buttonColor="danger"
        buttonText="Confirm"
        message="Are you sure you want to delete this progress record?"
        selectedItem={{ title: 'Delete Progress Record', id: selectedProgressRecord }}
        isModalOpen={isDeleteModalOpen}
        openModal={(modalState) => {
          setDeleteModalOpen(modalState);
          setSelectedProgressRecord('');
        }}
        callbackOnConfirm={handleDeleteRecordConfirmation}
      />
      <ProgressRecordsModal
        data={data}
        openModal={openModal}
        modalStatus={modalStatus}
        selectedProgressRecord={selectedProgressRecord}
        setSelectedProgressRecord={setSelectedProgressRecord}
        setDeleteModalOpen={setDeleteModalOpen}
      />
      <Col lg={12} className="mb-2">
        <div className="text-center">
          <h2>
            <button
              className="btn btn-link mr-4"
              onClick={() => handleMonthChange(-1)}
            >
              {'<'}
            </button>
            &nbsp;
            {}
            {targetMonthStr}
            &nbsp;
            <button
              className="btn btn-link ml-4"
              onClick={() => handleMonthChange(1)}
            >
              {'>'}
            </button>
          </h2>
        </div>
        <div className="d-flex justify-content-center">
          <div className="p-0">
            <Table className="mt-3 p-3 border border-light shadow">
              <thead>
              <tr>
                <th className="itemHeader bg-white py-0">
                  <p className="mb-0">Item</p>
                  {
                    isTableItemTitleOpen ? (
                      <FiChevronLeft
                        size={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTableItemTitleOpen(false);
                        }}
                      />
                    ) : (
                      <FiChevronRight
                        size={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTableItemTitleOpen(true);
                        }}
                      />
                    )
                  }
                </th>
              </tr>
              </thead>
              <tbody>
              {
                items.map(item => {
                  return (
                    <tr key={item.id}>
                      <td className="p-1 bg-white">
                        <div
                          className="itemTitle pl-1"
                          style={{ width: isTableItemTitleOpen ? '15rem' : '3rem' }}
                        >
                          <Link style={{ cursor: 'pointer' }} to={`/progressposts/${item.id}`}>{item.title}</Link>
                        </div>
                      </td>
                    </tr>
                  );
                })
              }
              </tbody>
            </Table>
          </div>
          <div className="overflow-auto p-0">
            <Table className="mt-3 p-3 text-center border border-light shadow">
              <thead>
              <tr>
                {
                  daysOfMonth.map(dayObj => {
                    const isCurrentDate = isThisMonth && dayObj.date === (("0"+currentDate.toString()).slice(-2));
                    return (
                      <th
                        key={dayObj.date}
                        className={`px-1 py-0 text-center ${dayObj.day === 'Sa' || dayObj.day === 'Su' ? 'bg-light' : 'bg-white'}`}
                      >
                        <span className={`${ isCurrentDate ? 'text-warning font-weight-bold' : ''}`}>{dayObj.date}</span>
                        <br />
                        <span className={`${ isCurrentDate ? 'text-warning font-weight-bold' : ''}`}>{dayObj.day}</span>
                      </th>
                    );
                  })
                }
              </tr>
              </thead>
              <tbody className="bg-white">
              {
                items && items.map(item => {
                  return (
                    <tr key={item.id}>
                      {
                        daysOfMonth.map(dayObj => {
                          const targetDateStr = `${targetMonthStr}-${dayObj.date}`;
                          const records = (data && data.myProgressRecords && data.myProgressRecords
                            .filter(record => {
                              return record.latestProgressDate.includes(targetDateStr) && record.item.id === item.id;
                            }))|| [];
                          const catColorObj = records && records.reduce((acc, record) => {
                            if (record.item.category && record.item.category.color) {
                              if (!acc[record.item.category.color]) {
                                acc[record.item.category.color] = 1;
                              } else {
                                acc[record.item.category.color] +=1;
                              }
                            } else {
                              if (!acc['#c5c3c3']) {
                                acc['#c5c3c3'] = 1;
                              } else {
                                acc['#c5c3c3'] +=1;
                              }
                            }
                            return acc;
                          }, {});
                          const catColor = catColorObj ? Object.keys(catColorObj)[0] : '';
                          return (
                            <td
                              key={dayObj.date}
                              className="px-0 py-1 text-center align-middle"
                            >
                              <div className="itemData">
                                <div
                                  className={`position-relative ${catColor ? "border rounded" : ""}`}
                                  style={{
                                    cursor: catColor ? 'pointer' : 'auto',
                                    backgroundColor: catColor,
                                    height: '1rem',
                                    width: '100%'
                                  }}
                                  onClick={() => records && records.length > 0 ? openModal({ isOpen: true, records, itemId: item.id, targetDateStr }) : null }
                                />
                              </div>
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
              </tbody>
            </Table>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CalendarSection;
