import React, {Fragment, useState} from 'react';
import {useMutation, useQuery} from 'react-apollo-hooks';
import { MY_CATEGORIES, DELETE_CATEGORY } from './apollo';
import Category from './CategoryForm';
import { FiEdit, FiTrash, FiPlusSquare, FiXCircle } from 'react-icons/fi';
import Modal from 'reactstrap/lib/Modal';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

const CategoryModal = ({
  isModalOpen, openModal,
}) => {
  const [ selectedCategory, selectCategory ] = useState('');
  const [ editScreen, showEditScreen ] = useState(false);
  const [ deleteAlert, showDeleteAlert ] = useState(false);
  const {error, data, loading } = useQuery(MY_CATEGORIES, { suspend: false });
  const deleteCategory = useMutation(DELETE_CATEGORY, {
    variables: { id: selectedCategory },
    refetchQueries: [{
      query: MY_CATEGORIES
    }],
  });
  if (error) return `Error! ${error.message}`;
  if (loading) return null;
  const optionList = (data.myCategories && data.myCategories.map(({ id, title, color }) =>({ id, name: title, color }))) || [];
  optionList.unshift({ id: '', name: 'New'});
  const selectedCategoryName = selectedCategory
    ? optionList.filter(option => option.id === selectedCategory)[0]
    && optionList.filter(option => option.id === selectedCategory)[0].name : '';

  const confirmDeleteCategory = () => {
    deleteCategory();
    selectCategory('');
    showDeleteAlert(false);
  };
  const foundOption = optionList ? optionList.find(option => option.id === selectedCategory) : null;
  const selectedColor = foundOption ? foundOption.color : '';

  return (
    <Modal size="xl" isOpen={isModalOpen} toggle={() => openModal(false)}>
      <ModalHeader
        toggle={() => openModal(false)}>
        My Categories
      </ModalHeader>
      <ModalBody>
        <label
          style={ selectedColor ? { backgroundColor: selectedColor, padding: '0.2rem' } : {}}
        >
          Category
        </label>
        <p className="mb-0">You can edit pre-existing categories or create your own category here.</p>
        <select
          className="custom-select"
          value={selectedCategory}
          onChange={(e) => selectCategory(e.target.value)}
        >
          {
            optionList.map(option => {
              return (
                <option key={option.id} value={option.id}>{option.name}</option>
              )
            })
          }
        </select>
        <div className="p-2 d-flex align-items-center justify-content-end">
          {
            selectedCategory
            ? (
              <Fragment>
                {
                  editScreen ? (
                    <span
                      role="button"
                      className="d-flex align-items-center justify-content-center"
                      style={{ cursor: 'pointer' }}
                      onClick={() => showEditScreen(!editScreen)}
                    >
                      <span className="pr-1">Close</span>
                      <FiXCircle
                        className="text-danger"
                      />
                    </span>
                  ) : (
                    <Fragment>
                      <span
                        role="button"
                        className="d-flex align-items-center justify-content-center"
                        style={{ cursor: 'pointer' }}
                        onClick={() => showEditScreen(!editScreen)}
                      >
                        <span className="pr-1">Edit</span>
                        <FiEdit
                          size={20}
                          className="text-primary"
                        />
                      </span>
                      &nbsp;
                      <span
                        role="button"
                        className="d-flex align-items-center"
                        style={{ cursor: 'pointer' }}
                        onClick={() => showDeleteAlert(true)}
                      >
                        <span className="pr-1">Delete</span>
                        <FiTrash
                          size={20}
                          className="text-danger"
                        />
                      </span>
                    </Fragment>
                  )
                }
              </Fragment>
            ) : (
              <Fragment>
                {
                  editScreen ? (
                    <span
                      role="button"
                      className="d-flex align-items-center"
                      style={{ cursor: 'pointer' }}
                      onClick={() => showEditScreen(!editScreen)}
                    >
                      <FiXCircle
                        className="text-danger"
                      />
                      Close
                    </span>
                  ) : (
                    <span
                      role="button"
                      className="d-flex align-items-center justify-content-center"
                      style={{ cursor: 'pointer' }}
                      onClick={() => showEditScreen(!editScreen)}
                    >
                      <span className="pr-1">Create</span>
                      <FiPlusSquare
                        size={20}
                        className="text-success"
                      />
                    </span>
                  )
                }
              </Fragment>
              )
          }
        </div>
        {
          deleteAlert
          && (
            <div className="alert alert-danger d-flex justify-content-between">
              <div className="d-flex align-items-center">
                Are you sure you want to delete this category?
              </div>
              <div>
                <Button
                  className="my-2 mr-2 rounded-0"
                  color="danger"
                  outline
                  onClick={confirmDeleteCategory}
                >
                  Confirm
                </Button>
                <Button
                  className="my-2 mr-2 rounded-0"
                  color="secondary"
                  outline
                  onClick={() => showDeleteAlert(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )
        }
        {
          editScreen
          && (
            <Col>
              <Category
                selectedCategoryName={selectedCategoryName}
                categoryId={selectedCategory}
                showEditScreen={showEditScreen}
              />
            </Col>
          )
        }
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          className="rounded-0"
          outline
          onClick={() => openModal(false)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
};

export default CategoryModal;
