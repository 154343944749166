import gql from 'graphql-tag';

export const SIGN_UP = gql`
    mutation signUp($username: String!, $email: String!, $newPassword: String!, $confirmNewPassword: String!, $termsAndConditions: Boolean!){
        createUser (data: {
            username: $username,
            email: $email,
            newPassword: $newPassword
            confirmNewPassword: $confirmNewPassword
            termsAndConditions: $termsAndConditions
        }) {
            user {
                id
                username
                email
                password
            }
            accountConfirmed
#            token
        }
    }
`;
