import React from 'react';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { FiEdit, FiPlusSquare, FiTrash } from 'react-icons/fi';
import PublishedCheckBox from '../PublishedCheckBox';
import moment from 'moment';
import invert from 'invert-color';
import './MyProgressCards.scss';
import { useMutation } from 'react-apollo-hooks';
import { LIKE, MY_ITEMS } from '../apollo';
import LikesButtons from "../../ProgressPost/LikesButtons";
import AllowOthersCommentCheckBox from "../AllowOthersCommentCheckBox";
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

const MyProgressCards = ({ authenticated, setSelectedPostId, setLikedByModalOpen, items, showChart, toggleSort, sortCol, openDeleteModal, openCategoryModal, openProgressRecordModal, filterTerm }) => {
  const toggleLike = useMutation(
    LIKE,
    {
      refetchQueries: [{ query: MY_ITEMS }],
      suspense: false
    }
  );
  return (
    <Col className="MyProgressCards">
      <Row>
        <Col>
          <Row className="d-flex justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-5 py-2">
              <Link
                to="/item/new"
                style={{ cursor: 'pointer' }}
                className="d-flex align-items-center justify-content-center text-decoration-none text-dark"
              >
                <span>Create Item</span>
                &nbsp;
                <FiPlusSquare
                  size={20}
                  className="text-success"
                />
              </Link>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-5 py-2">
              <span
                className="d-flex align-items-center justify-content-center"
                role="button"
                style={{ cursor: 'pointer' }}
                onClick={() => openCategoryModal(true)}
              >
                <span>Add/Edit Categories</span>
                &nbsp;
                <FiEdit
                  size={20}
                  className="text-primary"
                />
              </span>
            </div>
          </Row>
        </Col>
      </Row>
      <Row className="py-4">
        {
          items && items
            .map(({
                    categoryId, categoryColor, categoryTitle, id, title, latestProgressDate, latestProgressTally, totalProgressTally=0, published, allowOthersComment, tags, description, likedByCount
                  }) => {
              const maxDescLength = 100;
              const truncatedDesc = description.length > maxDescLength ? `${description.substring(0, maxDescLength)}...` : description;
              return (
                <div
                  key={id}
                  className="col-xl-3 col-lmd-4 col-smd-6 mb-3"
                >
                  <div
                    className="card h-100 shadow border-1 rounded"
                  >
                    <div className="card-header d-flex justify-content-between shadow-sm">
                      <Link style={{ cursor: 'pointer' }} to={`/progressposts/${id}`}>
                        <Highlighter
                          highlightClassName="bg-warning"
                          searchWords={[filterTerm]}
                          autoEscape={true}
                          textToHighlight={title}
                        />
                      </Link>
                      <div
                        className="edit-delete text-right"
                      >
                        <Link style={{ cursor: 'pointer' }} to={`/item/edit/${id}`}>
                          <FiEdit
                            size={20}
                            className="text-primary"
                            style={{ cursor: 'pointer' }}
                          />
                          &nbsp;
                        </Link>
                        <FiTrash
                          size={20}
                          className="text-danger"
                          style={{ cursor: 'pointer' }}
                          onClick={() => openDeleteModal(true, { id, title })}
                        />
                      </div>
                    </div>
                    <div
                      className="card-body overflow-hidden rounded-bottom"
                    >
                      <p className="mb-1 d-flex">
                        Total Tally: &nbsp;
                        <span className={`d-flex align-items-center badge badge-pill ${totalProgressTally > 0 ? 'badge-primary' : 'badge-light' }`}>
                          <Highlighter
                            highlightClassName="bg-warning"
                            searchWords={[filterTerm]}
                            autoEscape={true}
                            textToHighlight={totalProgressTally ? totalProgressTally.toString() : ""}
                          />
                        </span>
                      </p>
                      <p className="mb-1">
                        Latest Progress: &nbsp;
                        <FiPlusSquare
                          size={20}
                          className="text-success"
                          style={{ cursor: 'pointer' }}
                          onClick={() => openProgressRecordModal(true, { id, title })}
                        />
                        <br />
                        {
                          latestProgressDate
                          && (
                            <Highlighter
                              highlightClassName="bg-warning"
                              searchWords={[filterTerm]}
                              autoEscape={true}
                              textToHighlight={moment(latestProgressDate).format('ddd D MMM YYYY, hh:mm a')}
                            />
                          )
                        }
                        &nbsp;&nbsp;
                        {
                          latestProgressTally
                          && (
                            latestProgressTally > 0
                              ? <Highlighter
                                highlightClassName="bg-warning"
                                searchWords={[filterTerm]}
                                autoEscape={true}
                                textToHighlight={`(+${latestProgressTally})`}
                              />
                              : <Highlighter
                                highlightClassName="bg-warning"
                                searchWords={[filterTerm]}
                                autoEscape={true}
                                textToHighlight={latestProgressTally}
                              />
                          )
                        }
                      </p>
                      <p className="mb-1">
                        Category:
                        {
                          categoryTitle && (
                            <span
                              className="px-2 m-1 rounded d-inline-block"
                              style={{
                                color: categoryColor ? invert(categoryColor, true) : '',
                                backgroundColor: (categoryColor) || ''
                              }}
                            >
                              <Highlighter
                                highlightClassName="bg-warning"
                                searchWords={[filterTerm]}
                                autoEscape={true}
                                textToHighlight={categoryTitle}
                              />
                            </span>
                          )
                        }
                      </p>
                      <p>
                        Tags:
                        &nbsp;
                        {
                          tags && (
                            <Highlighter
                              highlightClassName="bg-warning"
                              searchWords={[filterTerm]}
                              autoEscape={true}
                              textToHighlight={tags}
                            />
                          )
                        }
                      </p>
                      <p className="description">
                        Description:
                        <br/>
                        { description
                          && (
                              <Highlighter
                                highlightClassName="bg-warning"
                                searchWords={[filterTerm]}
                                autoEscape={true}
                                textToHighlight={truncatedDesc}
                              />
                            )
                        }
                        {/*{ truncatedDesc }*/}
                      </p>
                      <p className="d-flex align-items-baseline mb-1">
                        <Highlighter
                          highlightClassName="bg-warning"
                          searchWords={[filterTerm]}
                          autoEscape={true}
                          textToHighlight="Published"
                        />: &nbsp;
                        <PublishedCheckBox
                          id={id}
                          value={published}
                        />
                      </p>
                      <p className="d-flex align-items-baseline mb-1">
                        Allow Others Comments:
                        &nbsp;
                        <AllowOthersCommentCheckBox
                          id={id}
                          value={allowOthersComment}
                        />
                      </p>
                      <LikesButtons
                        id={id}
                        likedByCount={likedByCount}
                        toggleLike={toggleLike}
                        authenticated={authenticated}
                        setLikedByModalOpen={() => {
                          setSelectedPostId(id);
                          setLikedByModalOpen(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })
        }
      </Row>
    </Col>
  );
};

export default MyProgressCards;
