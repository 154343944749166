import React from 'react';
import {useMutation } from "react-apollo-hooks";
import {　MY_ITEMS, UPDATE_ALLOW_OTHERS_COMMENT_STATUS　} from "./apollo";

const AllowOthersCommentCheckBox = ({ id, value }) => {
  const updateAllowStatus = useMutation(
    UPDATE_ALLOW_OTHERS_COMMENT_STATUS,
    {
      variables: { id, allowOthersComment: !value },
      refetchQueries: [{ query: MY_ITEMS }],
      suspense: false
    }
  );

  const handleUpdateAllowStatus = () => {
    if (id) updateAllowStatus();
  };

  return (
    <input
      type="checkbox"
      style={{ opacity: 1, margin: 0, position: 'inherit', cursor: 'pointer' }}
      id={id}
      checked={value}
      onChange={handleUpdateAllowStatus}
    />
  );
};

export default AllowOthersCommentCheckBox;
