import React, { useState } from 'react';
import './StyleGuide.scss';
import Menubar from '../../components/Menubar';
import { RingSpinner } from "react-spinners-kit";
import ExampleForm from './ExampleForm';
import CollapsibleSection from '../../components/CollapsibleSection';
import CollapsibleInline from '../../components/CollapsibleInline';
import ExamplePopover from './ExamplePopover';
import Alert from '../../components/Alert';
import ExampleModal from './ExampleModal';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

const StyleGuide = () => {
  const exampleChartValues = [820, 450, 210];
  const exampleChartValuesTotal = exampleChartValues
    .reduce((acc, value) => {
      return acc + value;
    }, 0);
  const [ collapsibleSectionOpen, setCollapsibleSectionOpen ] = useState(false);
  return (
    <Container className="StyleGuide mb-5 pt-5">
      <Row className="py-5">
        <Col className="text-center">
          <h1>Progress MAKR Theme</h1>
          <h2>STYLE GUIDE</h2>
        </Col>
      </Row>
      <Row>
        <Col className="pb-5">
          <h2>TYPOGRAPHY</h2>
          <hr />
          <h3>Fonts: Open Sans </h3>
          <h1>HEADER H1</h1>
          <h2>HEADER H2</h2>
          <h3>HEADER H3</h3>
          <h4>HEADER H4</h4>
          <p>Base Font Size</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Condimentum lacinia quis vel eros donec ac odio tempor. Imperdiet nulla malesuada pellentesque elit. Ac turpis egestas integer eget aliquet nibh praesent tristique magna. Aliquam sem fringilla ut morbi tincidunt augue interdum velit euismod. Vehicula ipsum a arcu cursus vitae congue mauris.</p>
          <br />
          <label>Basic Label</label>
          <br />
          <small>Small: For assistive texts for input,etc.</small>
          <br />
          <a href="/">This is a link.</a>
        </Col>
      </Row>
      <Row className="pb-5">
        <Col>
          <h2>COLOURS</h2>
          <hr />
          <div className="d-flex">
            <p className="text-primary p-2">Primary</p>
            <p className="text-secondary p-2">Secondary</p>
            <p className="text-info p-2">Info</p>
            <p className="text-warning p-2">Warning</p>
            <p className="text-danger p-2">Danger</p>
          </div>
          <h2>Borders</h2>
          <hr />
          <div className="border h-25 p-3">
            Empty Box with borders around
          </div>
        </Col>
      </Row>
      <Row className="py-5">
        <Col>
          <h2>COMPONENTS</h2>
          <hr />
          <div className="p-2 m-2">
            <h3>Spinner</h3>
            <div className="d-flex justify-content-center">
              <RingSpinner
                size={30}
                color="gray"
                loading={true}
              />
            </div>
          </div>
          <div className="p-2 m-2">
            <h3>Buttons</h3>
            <div className="d-flex">
              {
                ['primary', 'secondary', 'info', 'warning', 'danger']
                  .map(color => (
                    <Button
                      key={color}
                      color={color}
                      outline
                      className="m-2"
                    >
                      Button
                    </Button>
                  ))
              }
            </div>
          </div>
          <div className="p-2 m-2">
            <h3>Alerts</h3>
            {
              ['primary', 'secondary', 'info', 'warning', 'danger']
                .map(color => (
                  <Alert
                    key={color}
                    color={color}
                    className="rounded-0"
                  >
                    <p className="m-0">{ color } - Example text: More than one nameserver should be used for your domain. Using multiple nameservers provides redundancy and reduces the chance of business interruption.</p>
                  </Alert>
                ))
            }
          </div>
          <div className="p-2 m-2">
            <h3>Menubar</h3>
            <div>
              <Menubar />
            </div>
          </div>
          <div className="p-2 m-2">
            <h3>Card</h3>
            <div className="w-50">
              add card here...
            </div>
          </div>
          <div className="p-2 m-2">
            <h2>Popover</h2>
            <ExamplePopover />
          </div>
          <div className="p-2 m-2">
            <h2>Form</h2>
            <hr />
            <ExampleForm />
          </div>
          <div className="p-2 m-2">
            <h2>Collapsibles</h2>
            <hr />
            <h3>Collapsible Section</h3>
            <CollapsibleSection
              title={'Summary Notes'}
              className="bg-light border"
              open={collapsibleSectionOpen}
              onClick={() => setCollapsibleSectionOpen(!collapsibleSectionOpen)}
            >
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </CollapsibleSection>
            <br />
            <h3>Collapsible Inline</h3>
            <CollapsibleInline
              title={'Information'}
              className=""
              titleClassName="text-info"
            >
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </CollapsibleInline>
          </div>
          <div className="p-2 m-2">
            <h2>Modal</h2>
            <ExampleModal />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StyleGuide;
