import React, { Fragment } from 'react';
import PublicItemCards from '../../components/PublicItemCards';
import {Col, Row} from "reactstrap/lib";
import {useQuery} from "react-apollo-hooks";
import { ITEMS_USER_LIKES } from './apollo';
import LoadingPage from "../LoadingPage";

const LikesTabSection = ({ profileName, authenticated, refetch }) => {
  const { error, data, loading } = useQuery(ITEMS_USER_LIKES, { variables: { username: profileName }, suspend: false, fetchPolicy: "network-only" });
  if (loading) return <LoadingPage />;
  const { itemsUserLikes } = data || {};
  return (
    <Fragment>
      <Row>
        <Col>
          {
            (itemsUserLikes && itemsUserLikes.length > 0) ? (
              <p>{profileName} likes below published items.</p>
            ): (
              <p>{profileName} has not liked any published items yet.</p>
            )
          }
        </Col>
      </Row>
      <Row className="mb-3">
        <PublicItemCards
          parentId="likesTab"
          authenticated={authenticated}
          profileName={profileName}
          items={itemsUserLikes}
          refetch={refetch}
        />
      </Row>
    </Fragment>
  );
};

export default LikesTabSection;
