import React, { useState, useEffect } from 'react';
import { CirclePicker } from 'react-color';
import {selectErrorMessage} from './errorHandlingFuncs';

const ColorPicker = ({ className, label, name, initialValue, validate, validationOptions, helpMessage, placeholder }) => {
  const [ color, setColor ] = useState(initialValue);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (touched) {
      setError(selectErrorMessage(validationOptions, color));
    } else {
      setError("");
    }
  }, [touched, color, validationOptions]);

  useEffect(() => {
    if (validate) {
      setError(selectErrorMessage(validationOptions, color));
    }
  }, [validate, color, validationOptions]);

  return (
    <div>
      <input
        id={name}
        name={name}
        value={color}
        readOnly
        style={{ display: 'none' }}
      />
      <p className="mb-0">{helpMessage}</p>
      <CirclePicker
        className="py-2"
        color={color}
        onChangeComplete={ (color) => setColor(color.hex) }
      />
    </div>
  );
};

export default ColorPicker;

// [name]: value,
//       categoryColor: name === 'categoryId' ? optionList.find(option => option.id === value).color : null
